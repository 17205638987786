import { Box, Typography } from '@mui/material'
import React  from 'react'
import { useTranslation } from "react-i18next";

const Information = () => {
  const {t} = useTranslation('common')
  return (
    <Box sx={{ display:'flex', justifyContent:"center"}}>
        <Typography variant='h4' fontFamily= 'MyTamilFontHeading' sx={{textAlign:"center", width:'50%',lineHeight: 1.3 ,marginBottom:"100px"}}>{t("description.desc1")} </Typography>
    </Box>
  )
}

export default Information;
