import React, { useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Container, Grid, Typography, Box, Tab, ThemeProvider, Button } from '@mui/material';
import logonew from '../../assets/logonew.png';
import publicationLogo from '../../assets/Tamilezhuthapadi Publications.png';
import profile from '../../assets/sponsor.png'
import booklogo from '../../assets/booklogo.png'
import namelogo from '../../assets/namelogo.png'
import theme from '../ui/Theme';
import copy from '../../assets/copy.png';
import Scroll from '../layout/Scroll'
import { useTranslation } from 'react-i18next';
// import { useLocation } from 'react-router-dom';
// import { useEffect } from 'react';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import XIcon from '@mui/icons-material/X';
 
const Footer = () => {
  const { t } = useTranslation('home')
  const topRef = useRef(null);
 
 
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleScrollToTop1 = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleScrollToTop2 = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
 
  return (
 
    <React.Fragment>
      <Scroll />
      <ThemeProvider theme={theme}>
 
        <Box ref={topRef} sx={{ textAlign: 'center'}} pb="60px" pr="40px" pl="40px" pt="60px">
          <Box sx={{ textAlign: 'center', }}>
            <Grid container
              direction="row" 
              alignItems="center"                 
              sx={{                      
                borderTop: '1px solid #ccc',                
                backgroundColor: '#a2d7eeb0',
                borderRadius: '20px',                              
                height:"270px",                               
              }}
            >
                          
              <Grid item xs={3}>
                <Box sx={{width:{xs:60 ,md:120, lg:220}, height:{xs:65, md:150, lg:250}}}  component={'img'} src={publicationLogo}/>
              </Grid>                  

              <Grid item container xs={6} direction="column" justifyContent="space-between">                      
                <Grid item container justifyContent="space-evenly" sx={{mb:"80px"}}>
                  <Link to="" style={{ textDecoration: 'none' }} onClick={handleScrollToTop} >
                    <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '20px',color:'#191970' }} >{t("footer.home")}</Typography>
                  </Link>                
                
                  <Link to="/contact" style={{ textDecoration: 'none' }} onClick={handleScrollToTop1}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '20px',color:'#191970' }}>{t("footer.contact")}</Typography>
                  </Link>              
                
                  <Link to="/terms" style={{ textDecoration: 'none' }} onClick={handleScrollToTop2}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '20px',color:'#191970' }}>{t("footer.terms")}</Typography>
                  </Link>
                </Grid>

                <Grid item container justifyContent="space-between" sx={{mb:"20px"}}>
                  <Box  component={Link} to="https://www.facebook.com/profile.php?id=61564309873738" target='_label'>
                    <FacebookIcon  sx={{fontSize:'40px',color:'#191970'}}/>
                  </Box>
                  <Box  component={Link} to="https://www.instagram.com/storybuzz_2024/?hl=en" target='_label'>
                    <InstagramIcon sx={{fontSize:'40px',color:'#191970'}}/>
                  </Box>
                  <Box  component={Link} to="https://x.com/storybuzz_2024" target='_label'>
                    <XIcon sx={{fontSize:'40px',color:'#191970'}}/>
                  </Box>
                  <Box  component={Link} to="mailto:support@storybuzz.org" target='_label'>
                    <EmailIcon sx={{fontSize:'40px',color:'#191970'}}/>
                  </Box>
                  <Box  component={Link} to="https://www.youtube.com/channel/UCY2GhLCHalhLgKJGu6sP3vA" target='_label'>
                    <YouTubeIcon sx={{fontSize:'40px',color:'#191970'}}/>
                  </Box>
                </Grid>

                <Grid item >
                  <Box>
                    <Typography align='center'>Powered by
                    <Box component={Link} to="https://www.keelis.com/index.html" target='_label'
                      sx={{
                        textDecoration: "none", color: 'blue',
                        display: 'inline'
    
                      }}
                    > keelis</Box>
                  </Typography>
                  <Typography >© {new Date().getFullYear()}, StoryBuzz All Rights Reserved.</Typography>              
                  </Box>   
                </Grid>
              </Grid>

              <Grid item xs={3}>
                <Box component={'img'} sx={{width: { xs:'100px', md: '200px', lg: '300px' }, height: { xs:'75px', md: '150px', lg: '250px' }}} src={booklogo}/>
              </Grid>
              </Grid>    
      
          </Box>
          {/* <Typography >version 0.1.5</Typography> */}
          </Box>
      </ThemeProvider>  
    </React.Fragment>
 
  );
};
 
export default Footer;
 