import React, { useState, useRef, useEffect } from 'react';
import { Box, Modal, TextField, Button, Grid, IconButton, Typography } from '@mui/material';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import CancelIcon from '@mui/icons-material/Cancel';
import axiosInstance from "../utils/AxiosInstance";

const VerifyOtpPopup = ({ onClose, userId }) => {
  const [email, setEmail] = useState('');
  const [showOtpInputs, setShowOtpInputs] = useState(false);
  const [otp, setOtp] = useState(Array(6).fill(''));
  const [emailError, setEmailError] = useState('');
  const [otpError, setOtpError] = useState('');
  const [message, setMessage] = useState('');
  const [isVerificationSuccess, setIsVerificationSuccess] = useState(false);
  const [timer, setTimer] = useState(60); // 1 minute in seconds
  const [showGetOtpLink, setShowGetOtpLink] = useState(false);
  const [isSendingOTP, setIsSendingOTP] = useState(false); // Added state to manage OTP sending
  const { t } = useTranslation('user');

  const otpRefs = useRef([]);

  // Timer countdown effect
  useEffect(() => {
    let countdown;
    if (showOtpInputs && timer > 0) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setShowOtpInputs(false); // Hide OTP inputs when the timer reaches 0
      setShowGetOtpLink(true); // Show "Get OTP Link" when the timer reaches 0
    }
    return () => clearInterval(countdown);
  }, [showOtpInputs, timer]);

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);

    if (validateEmail(newEmail)) {
      setEmailError('');
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleOtpChange = (e, index) => {
    const value = e.target.value;

    if (/^\d?$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < otp.length - 1) {
        otpRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && otp[index] === '') {
      if (index > 0) {
        otpRefs.current[index - 1]?.focus();
      }
    } else if (e.key === 'Enter') {
      if (index < otp.length - 1) {
        otpRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleVerifyClick = async () => {
    // Check if the email field is empty or invalid
    if (!email) {
      setEmailError('Please enter your email');
      return; // Exit the function if email is empty
    }
  
    if (!validateEmail(email)) {
      setEmailError('Please enter a valid email address');
      return; // Exit the function if email is invalid
    }
  
    // Clear any existing email errors if the email is valid
    setEmailError('');
  
    if (showOtpInputs) {
      // Validate OTP input
      if (otp.some((digit) => digit === '')) {
        setOtpError('Please enter all OTP digits');
      } else {
        setOtpError('');
        await verifyOTP();
      }
    } else {
      if (isSendingOTP) return; // Prevent sending OTP if already in process
  
      setIsSendingOTP(true); // Set the flag to indicate OTP is being sent
      setShowOtpInputs(true); // Show OTP inputs
      await sendOTP(); // Send OTP when the user clicks Verify after entering email
      setIsSendingOTP(false); // Reset the flag after operation
    }
  };
  


  const sendOTP = async () => {
    try {
      const response = await axiosInstance.post('/otp/send-otp', { email, userId });
      setMessage(response.data.message);
      setTimer(60); // Reset the timer to 1 minute
      setShowGetOtpLink(false); // Hide "Get OTP Link" when OTP is sent
      setTimeout(() => {
        setMessage('');
      }, 10000);
    } catch (error) {
      setMessage('Error sending OTP.');
      setTimeout(() => {
        setMessage('');
      }, 10000);
    }
  };

  const verifyOTP = async () => {
    try {
      const otpString = otp.join('');
      const response = await axiosInstance.post('/otp/verify-otp', { email, otp: otpString, userId });
      setIsVerificationSuccess(true);
      setMessage('New Mail Verification Successfully!');
      
      // Redirect to the user-dashboard page after 3 seconds
      setTimeout(() => {
        window.location.href = '/user-dashboard';
      }, 500);
    } catch (error) {
      setMessage('Error verifying OTP.');
    }
  };

  const handleResendOTP = async () => {
    if (isSendingOTP) return; // Prevent sending OTP if already in process

    setIsSendingOTP(true); // Set the flag to indicate OTP is being sent
    setTimer(60); // Reset the timer to 1 minute
    setShowOtpInputs(true); // Show OTP inputs immediately when "Get OTP" is clicked
    setShowGetOtpLink(false); // Hide "Get OTP Link" button after requesting a new OTP
    await sendOTP(); // Request a new OTP
    setIsSendingOTP(false); // Reset the flag after operation
  };

  const handleCancelClick = () => {
    onClose();
  };

  const handleEmailBlur = async () => {
    if (validateEmail(email)) {
      if (isSendingOTP) return; // Prevent sending OTP if already in process

      setIsSendingOTP(true); // Set the flag to indicate OTP is being sent
      try {
        const response = await axiosInstance.post('/otp/checkemail', { email });

        if (response.data.exists) {
          setEmailError('Email already exists. Please use another email.');
          setShowOtpInputs(false);
        } else {
          setEmailError('');
          setShowOtpInputs(true);
          await sendOTP(); // Send OTP only if the email doesn't exist
        }
      } catch (error) {
        console.error('Error checking email:', error);
        setEmailError('Error checking email. Please try again.');
        setShowOtpInputs(false);
      } finally {
        setIsSendingOTP(false); // Reset the flag after operation
      }
    } else {
      setEmailError('Please enter a valid email address');
      setShowOtpInputs(false);
    }
  };

  return (
    <Modal open={true} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 300,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          borderRadius: '10px',
          border: 'none',
          backgroundColor: '#e6f5fc',
          p: 4,
        }}
      >
        {isVerificationSuccess ? (
          <Box sx={{ textAlign: 'center' }}>
            <IconButton sx={{ position: 'absolute', top: 8, right: 8 }} onClick={handleCancelClick}>
              <CancelIcon />
            </IconButton>
            <Typography variant="h6">{t('edit.verificationSuccess')}</Typography>
          </Box>
        ) : (
          <>
            <h2>{t('edit.Newmail')}</h2>
            <TextField
              label={t('edit.email')}
              variant="outlined"
              fullWidth
              margin="normal"
              value={email}
              onChange={handleEmailChange}
              onBlur={handleEmailBlur}
              error={!!emailError}
              helperText={emailError}
            />
            {showOtpInputs && (
              <>
                <h3>{t('edit.otp')}</h3>
                <Grid container spacing={1}>
                  {otp.map((value, index) => (
                    <Grid item xs={2} key={index}>
                      <TextField
                        inputProps={{ maxLength: 1 }}
                        value={value}
                        onChange={(e) => handleOtpChange(e, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        variant="outlined"
                        error={!!otpError}
                        inputRef={(el) => (otpRefs.current[index] = el)}
                      />
                    </Grid>
                  ))}
                </Grid>
                {otpError && <Box sx={{ color: 'red', mt: 1 }}>{otpError}</Box>}
                <Box sx={{ mt: 2, color: 'red' }}>
                  <Typography variant="body2">
                    Resend OTP in: {Math.floor(timer / 60)}:{('0' + (timer % 60)).slice(-2)}s
                  </Typography>
                </Box>
              </>
            )}

            {showGetOtpLink && (
              <Box sx={{ mt: 2 }}>
                <Typography
                  variant="body2"
                  color="secondary"
                  onClick={handleResendOTP}
                  sx={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue'}}
                >
                  {t('edit.getOtp')}
                </Typography>
              </Box>
            )}

            {message && (
              <Box
                sx={{
                  mt: 2,
                  color: message.includes('Error') ? 'red' : 'green',
                }}
              >
                {message}
              </Box>
            )}

            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
              <Button variant="contained" color="secondary" onClick={onClose}>
                {t('edit.cancel')}
              </Button>
              <Button variant="contained" color="primary" onClick={handleVerifyClick}>
                {t('edit.verify')}
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default VerifyOtpPopup;
