import React, { useContext, useEffect, useState } from "react";
import axiosInstance from "../utils/AxiosInstance";
import { LanguageContext } from "../../hooks/context/LanguageContext";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Typography,
  Box,
  TextField,
  Pagination,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import BookCard from "../common/BookCard";
import { useTranslation } from "react-i18next";
import { LensTwoTone } from "@mui/icons-material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import FloatingDonateButton from '../donate/FloatingDonateButton';
import Loader from '../bookCreator/Loader';


const AllBooks = () => {

  const [searchQuery, setSearchQuery] = useState("");
  const { language } = useContext(LanguageContext);
  const [categories, setCategories] = useState([]);
  const [levels, setLevels] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubcategory, setSelectedSubcategory] = useState("");
  const [selectedLevel, setSelectedLevel] = useState("");
  const [locationLevel, setLocationLevel] = useState("");
  const [books, setBooks] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  // console.log("subcate", levels);
  const { t } = useTranslation("common");
  const [page, setPage] = useState(1); // Current page
  const [totalPages, setTotalPages] = useState(1); 
  const [loader,setLoader]=useState(false)
  // const [sticky, setSticky] = useState(false);

  // const userId = 1;
  // useEffect(() => {
  //   const handleScroll = () => {
  //     setSticky(window.scrollY > 0); // Set sticky based on scroll position
  //   };

  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  useEffect(() => {
    if (location.state) {
      if (location.state.level) {
        setSelectedLevel(location.state.level);
      }
      if (location.state.categoryId) {
        setSelectedCategory(location.state.categoryId);
      }
      if (location.state) {
        setSelectedLevel(location.state.level || "");
        setSelectedCategory(location.state.categoryId || "");
        setSelectedSubcategory(location.state.subcategoryId || "");
        setSortBy(location.state.sortBy || "");
      }
    }
  }, [location.state]);



  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axiosInstance.get("/books/categories", {
          headers: {
            "Accept-Language": language,
          },
        });
        setCategories(response.data.categories);
        setLevels(response.data.levels);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, [language]);

  useEffect(() => {
    const fetchSubcategories = async () => {
      if (selectedCategory) {
        try {
          const response = await axiosInstance.get("/books/subcategories", {
            params: { category_id: selectedCategory },
            headers: {
              "Accept-Language": language,
            },
          });
          setSubcategories(response.data.subcategories);
        } catch (error) {
          console.error("Error fetching subcategories:", error);
        }
      } else {
        setSubcategories([]);
      }
    };

    fetchSubcategories();
  }, [selectedCategory, language]);

  useEffect(() => {
    const fetchBooks = async () => {
      // console.log("--");
      setLoader(true)
      try {
        // setBooks([]);
        // console.log("--");
        const response = await axiosInstance.get("/books/list", {
          params: {
            level_id: selectedLevel,
            category_id: selectedCategory,
            subcategory_id: selectedSubcategory,
            sort_by: sortBy,
            page,
            limit: booksPerPage,
          }
        });
        // console.log("-----response-----", response.data);
        setBooks(response.data);
        setPage(1);
      } catch (error) {
        console.error("Error fetching books:", error);
      }finally{
        setLoader(false)
      }
      
    };
    // if (selectedLevel || selectedCategory || selectedSubcategory || sortBy) {
    fetchBooks(); // Fetch books only if a level is selected
    // }
    // else{
    //     fetchBooks();
    // }
  }, [selectedLevel, selectedCategory, selectedSubcategory, sortBy, language]);


  const booksPerPage = 52;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [page]);

  useEffect(() => {
    setTotalPages(Math.ceil(books.length / booksPerPage));
  }, [books]);

  // const handleBookClick = (book) => {
  //   navigate(`/book/${book}`, { state: { book } });
  // };

  const handleBookClick = (book) => {
    navigate(`/book/${book.book_id}`);
  };

  const handleCategoryChange = (event) => {
    const category = event.target.value;
    setSelectedCategory(category);
    setSelectedSubcategory(""); // Reset subcategory when category changes
    setPage(1);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };


  let startIndex = (page - 1) * booksPerPage;
  let pagination = books.slice(startIndex, startIndex + booksPerPage);


  useEffect(() => {
    setTotalPages(Math.ceil(books.length / booksPerPage));
  }, [books]);

  const handlePageChange = (event, value) => {
    setPage(value);
    // console.log("---------", value);
  };

  // console.log("lkfdjlksdfjkdsjfsd", page);

  //   if (selectedCategory) {
  //     pagination = books.filter(
  //       (book) => book.category_id === selectedCategory
  //     );
  //   }

  startIndex = (page - 1) * booksPerPage;
  pagination = books.slice(startIndex, startIndex + booksPerPage);

  const isTamil = t.language === 'ta';

  const theme = createTheme({
    palette: {
      background: {
        default: "#cee6f0", // Your desired background color
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ backgroundColor: "background.default" }}>
        
        <Grid container direction="column" alignItems="center">
          <Grid
            item
            textAlign="center"
            sx={{
              marginTop: "100px",
              fontWeight: "bold",
              maxWidth: isTamil ? "80%" : "100%",
            }}
          >
            <Typography
              variant="h4"
              fontFamily="Roboto"
              fontSize={isTamil ? "30px" : "35px"}
              fontWeight={"bold"}
            >
              {t("titles.page2")}
            </Typography>
          </Grid>
          <Grid
            item
            textAlign="center"
            fontFamily="Roboto"
            sx={{
              marginTop: "30px",
              fontWeight: "bold",
              maxWidth: isTamil ? "80%" : "100%",
            }}
          >
            <Typography
              fontFamily="Roboto"
              fontSize={isTamil ? "24px" : "28px"}
            >
              {t("titles.child")}
            </Typography>
          </Grid>
        </Grid>

        <Box
          container
          width={"100%"}
          mt='auto'
          // pt={'60px'}
          // spacing={3}
          // height={'170px'}
          sx={{ display: 'flex', justifyContent:'space-between', alignItems: 'center' }}
          position={ 'sticky' } // Make the header sticky
          top={ 0 }  // Stick to the top
          zIndex={1100}
          bgcolor={ "#cee6f0" }

        >
          <Box width={'25%'} padding={"0px 5px"}>
            <FormControl variant="outlined" fullWidth margin="normal">
              <InputLabel>{t("counter.level")}</InputLabel>
              <Select
                value={selectedLevel}
                onChange={(e) => setSelectedLevel(e.target.value)}
                label={t("counter.level")}
                sx={{
                  height: "50px",
                  borderRadius: "10px",
                  "&.MuiSelect-outlined": { color: "#000000" },
                  "& .MuiInputLabel-root": {
                    color: "#000000", // Change the label color
                  },
                }}
              >
                <MenuItem value="">{t("counter.none")}</MenuItem>
                {levels.map((level) => (
                  <MenuItem key={level.level_id} value={level.level_id}>
                    {level.level_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box width={'25%'} padding={"0px 5px"}>
            <FormControl variant="outlined" fullWidth margin="normal">
              <InputLabel>{t("counter.category")}</InputLabel>
              <Select
                value={selectedCategory}
                onChange={handleCategoryChange}
                label={t("counter.category")}
                sx={{ height: "50px", borderRadius: "10px" }}
              >
                <MenuItem value="">{t("counter.none")}</MenuItem>
                {categories.map((category) => (
                  <MenuItem
                    key={category.category_id}
                    value={category.category_id}
                  >
                    {category.category_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box width={'25%'} padding={"0px 5px"}>
            <FormControl variant="outlined" fullWidth margin="normal">
              <InputLabel>{t("counter.subcategory")}</InputLabel>
              <Select
                value={selectedSubcategory}
                onChange={(e) => setSelectedSubcategory(e.target.value)}
                label={t("counter.subcategory")}
                disabled={!selectedCategory}
                sx={{ height: "50px", borderRadius: "10px" }}
              >
                <MenuItem value="">{t("counter.none")}</MenuItem>
                {subcategories.map((subcategory) => (
                  <MenuItem
                    key={subcategory.subcategory_id}
                    value={subcategory.subcategory_id}
                  >
                    {subcategory.subcategory_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box width={'25%'} padding={"0px 5px"}>
            <FormControl variant="outlined" fullWidth margin="normal">
              <InputLabel>{t("counter.sortBy")}</InputLabel>
              <Select
                value={sortBy}
                onChange={(e) => setSortBy(e.target.value)}
                label={t("counter.sortBy")}
                sx={{ height: "50px", borderRadius: "10px" }}
              >
                <MenuItem value="">{t("counter.none")}</MenuItem>
                <MenuItem value="views">{t("counter.MostViewed")}</MenuItem>
                <MenuItem value="latest">{t("counter.Latest")}</MenuItem>
                <MenuItem value="oldest">{t("counter.Oldest")}</MenuItem>
                <MenuItem value="title">{t("counter.az")}</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>

        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          mt={10}
        >
          {/* {pagination.map((book) => (
          <Grid
            item
            container
            justifyContent="center"
            key={book.book_id}
            sm={6}
            md={3}
            lg={3}
            xl={2}
          >
            <BookCard
              book={book}
              onBookClick={handleBookClick}
              userId={userId}
              setBooks={setBooks}
            />
          </Grid>
        ))} */}
  {loader  ?(<Loader/>):(
          <Grid item xs={12} container spacing={2}>
            {pagination.map((book) => (
              <Grid
                item
                container
                justifyContent="center"
                key={book.book_id}
                sm={6}
                md={3}
                lg={3}
                xl={2}
              >
                <BookCard
                  book={book}
                  onBookClick={handleBookClick}
                  setBooks={setBooks}
                />
              </Grid>
            ))}
          </Grid>
          )}

        </Grid>

        <Grid container justifyContent="center" mt={4}>
          <Pagination
            count={totalPages} // Total number of pages
            page={page} // Current page
            onChange={handlePageChange} // Handle page change
            color="primary"
          />
        </Grid>
        <FloatingDonateButton />
      </Box>
    </ThemeProvider>
  );
};

export default AllBooks;
