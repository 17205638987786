import React, { useEffect, useState } from "react";
import { ThemeProvider } from "@emotion/react";
import theme from "../ui/Theme";
import Box from "@mui/material/Box";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Avatar, Button, Grid, Link } from "@mui/material";
import ArrowForward from "@mui/icons-material/ArrowForwardIos";
import ArrowBack from "@mui/icons-material/ArrowBackIos";
import { useTranslation } from "react-i18next";
import banner_1 from "../../assets/banner-1.png";
import banner_2 from "../../assets/banner2_new.jpg";
import banner_3 from "../../assets/Banner-3.png";
import Typography from "@mui/material/Typography";
import girl from "../../assets/girl.jpg";
import pencilbanner from "../../assets/pencilbanner.png";
import axiosInstance from "../utils/AxiosInstance";
import babyimg from "../../assets/babyimg.jpg";
import { useMediaQuery } from "@mui/material";
import Loading from "../common/Loading";
import hkg from '../../assets/users/user1.webp'
import { useLocation, useNavigate } from "react-router-dom";



function NextArrow(props) {
  const { onClick } = props;
  return (

    <Button
      onClick={onClick}
      disableRipple
      sx={{
        display: "block",
        background: "none",
        color: "#000000a5",
        position: "absolute",
        top: "50%",
        height: "100%",
        bottom: "130px",
        right: "0px",
        padding: "0px",
        transform: "translateY(-50%)",
        zIndex: 1,
        "&:hover": {
          background: "none",
          color: "#000000e9",
        },
        "&:active": {
          background: "none",
        },
        "&:focus": {
          outline: "none",
        },
      }}
    >
      <ArrowForward fontSize="large" />
    </Button>
  );
}

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <Button
      onClick={onClick}
      disableRipple
      sx={{
        display: "block",
        background: "rgba(0, 0, 0, 0)",
        color: "#000000a5",
        position: "absolute",
        top: "50%",
        height: "100%",
        padding: "0px",
        width: "5%",
        transform: "translateY(-50%)",
        zIndex: 1,
        "&:hover": {
          background: "none",
          color: "#000000e9",
        },
        "&:active": {
          background: "#261d1d00",
        },
        "&:focus": {
          outline: "none",
        },
      }}
    >
      <ArrowBack fontSize="large" />
    </Button>
  );
}

function Carousel() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const { t, i18n } = useTranslation("carousel");
  const [book, setBook] = useState(null);
  const [author, setAuthor] = useState(null);
  // const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // console.log("book", book);

  // console.log(author);
  const navigate = useNavigate();

  const handleBookClick = () => {
    navigate(`/book/${book.book_id}`);
  };

  const handleAuthorClick = () => {
    navigate(`/book/${author.latest_book_id}`);
  };
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const [bookResponse, authorResponse] = await Promise.all([
  //         axiosInstance.get("/books/book-of-the-month"),
  //         axiosInstance.get("/books/author-of-the-month")
  //       ]);
  //       setBook(bookResponse.data);
  //       setAuthor(authorResponse.data);
  //     } catch (err) {
  //       setError(err);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchData();
  // }, []);

  useEffect(() => {
    const fetchBookOfTheMonth = async () => {

      try {
        // setLoading(true);
        const response = await axiosInstance.get("/books/book-of-the-month");
        setBook(response.data);
        console.log("resp", response.data)

      } catch (err) {
        setError(err);


      }
      // finally{
      //   setLoading(false)
      // }
    };

    const fetchAuthorOfTheMonth = async () => {

      try {
        // setLoading(true);
        const response = await axiosInstance.get("/books/author-of-the-month");
        setAuthor(response.data);

      } catch (err) {
        setError(err);

      }
      // finally{
      //   setLoading(false)
      // }

    };

    fetchAuthorOfTheMonth();
    fetchBookOfTheMonth();
  }, []);

  const settings = {
    dots: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    appendDots: dots => (
      <Box sx={{ position: "absolute", bottom: 20, width: '100%', display: 'flex', justifyContent: 'center', padding: 0 }}>
        <ul style={{ margin: 0 }}>{dots}</ul>
      </Box>
    ),
    customPaging: i => (
      <Box sx={{ width: 20, height: 4, borderRadius: "10px", background: '#000000ce', opacity: currentSlide === i ? 1 : 0.5, '&:hover': { opacity: 1 } }} />
    ),
    beforeChange: (current, next) => setCurrentSlide(next),
  };

  // const imageStyle = {
  //   width: '100%',
  //   height: '100%',
  //   objectFit: 'cover',
  // };

  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(max-width:960px) and (min-width:601px)");

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));

  // if (loading) {
  //   return (

  //       <Loading />
  //     )
  // }
  const [slides, setSlides] = useState([]);
  useEffect(() => {
    axiosInstance.get('/slides/list')
      .then(response => {
        setSlides(response.data);
        console.log(response.data);

        // setLoading(false);
      })
      .catch(err => {
        setError(err);
        // setLoading(false);
      });
  }, []);


  const formatCount = (count) => {
    if (count >= 1000) {
      return `${Math.floor(count / 1000)}k`;
    }
    return count.toString();
  };

  return (
    <Grid>
      <ThemeProvider theme={theme}>
        <Slider {...settings}>
          <Box
            sx={{
              position: "relative",
              height: { xs: "auto", md: "550px" },
              width: "100%",
            }}
          >
            {author && (
              <>
                <Box
                  component="img"
                  src={banner_1}
                  sx={{ height: { xs: "auto", md: "550px" }, width: "100%" }}
                />
                <Box sx={{
                  width: '62%',
                  height: '70%',
                  position: 'absolute',
                  top: '25%',
                  right: 0,

                }}>
                  <Typography
                    variant={isMobile ? "h6" : isTablet ? "h5" : "h4"}
                    sx={{
                      color: "#191970",
                      fontFamily: "Pokemon",
                      fontSize: isMobile ? "24px" : isTablet ? "36px" : "48px",
                      fontWeight: 400,

                    }}
                  >
                    Welcome to
                  </Typography>
                  <Box sx={{
                    textAlign: 'center'
                  }}>
                    <Typography
                      variant={isMobile ? "h5" : isTablet ? "h4" : "h2"}
                      sx={{
                        color: "#191970",
                        fontFamily: "Pokemon",
                        fontSize: { lg: "80px", md: '70px', sm: '50px' },
                        fontWeight: 600,
                        textAlign: 'center'

                      }}
                    >
                      Story Buzz
                    </Typography>
                  </Box>
                  <Typography sx={{
                    color: "#191970",
                    fontSize: '25px',
                    fontWeight: 600,
                    textAlign: 'center',
                    pt: '5%',
                    fontFamily: "Fredoka"
                  }}>Where Stories Come Alive and Buzzz..!
                  </Typography>
                  <Box
                    component="img"
                    src={pencilbanner}
                    sx={{
                      // width: isMobile ? "50px" : isTablet ? "100px" : "390px",
                      width: { lg: '390px', md: '250px', sm: '200px' },
                      transform: "rotate(-3.85deg) skewX(-7deg) scaleY(1.05)",
                      top: { lg: '-53px', md: '30px', sm: '20px' },
                      right: 0,
                      position: 'absolute',
                      // bgcolor:"red"
                    }}
                  />
                </Box>



              </>
            )}
          </Box>
          {/* slide2 */}
          <Box
            sx={{
              position: "relative",
              height: { xs: "auto", md: "550px" },
              width: "100%",
            }}
          >
            {author && (
              <>
                <Box
                  component="img"
                  src={banner_2}
                  sx={{ height: { xs: "auto", md: "550px" }, width: "100%" }}
                />

                <Box sx={{
                  width: '65%',
                  height: '70%',
                  position: 'absolute',
                  top: '20%',
                  right: 0,

                }}>
                  <Typography
                    sx={{
                      color: "#0e0d0d",
                      fontFamily: "MyTamilFontHeading",
                      fontSize: { xs: "20px", md: "60px" },
                      textAlign: 'center',
                      lineHeight: { xs: "normal", md: "99.65px" },


                    }}
                  >
                    {t("slide2.title")}
                  </Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Box sx={{ pr: '20px' }}>
                      <Avatar
                        src={`${process.env.REACT_APP_AUTHOR_PROFILE_URL}${author.profile_picture}`}
                        sx={{

                          height: { xs: "80px", md: "150px" },
                          width: { xs: "80px", md: "150px" },


                        }}
                      />
                    </Box>
                    <Box>
                      <Box display={'flex'}>
                        <Typography variant="h6"
                          sx={{ fontWeight: "bold", width: '180px', }}
                        >{t("slide2.name")}
                        </Typography>
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>: {author.author_name}</Typography>
                      </Box>
                      <Box display={'flex'}>
                        <Typography variant="h6"
                          sx={{ fontWeight: "bold", width: '180px' }}
                        >{t("slide2.like")}
                        </Typography>
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>: {formatCount(author.total_likes_count)}</Typography>
                      </Box>
                      <Box display={'flex'}>
                        <Typography variant="h6"
                          sx={{ fontWeight: "bold", width: '180px', }}
                        >{t("slide2.cmt")}
                        </Typography>
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>: {formatCount(author.total_comments_count)}</Typography>
                      </Box>
                      <Box display={'flex'}>
                        <Typography variant="h6"
                          sx={{ fontWeight: "bold", width: '180px' }}
                        >{t("slide2.totalReads")}
                        </Typography>
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>: {formatCount(author.total_views_count)}</Typography>
                      </Box>
                      <Box display="flex" justifyContent="flex-start" mt={2}>
                        <Button variant="body1" onClick={handleAuthorClick} color="textSecondary" sx={{ border: 'solid 1px', borderRadius: '5px', fontWeight: 'bold', display: 'block' }}>
                          {t("slide2.read")}
                        </Button>
                      </Box>
                    </Box>

                  </Box>

                </Box>


              </>
            )}
          </Box>
          {/* slide3 */}
          <Box
            sx={{
              position: "relative",
              height: { xs: "auto", md: "550px" },
              width: "100%",
            }}
          >
            {book && (
              <>
                <Box
                  component="img"
                  src={banner_3}
                  sx={{ height: { xs: "auto", md: "550px" }, width: "100%" }}
                />

                <Box sx={{
                  width: '65%',
                  height: '70%',
                  position: 'absolute',
                  top: '20%',
                  right: 0
                }}>
                  <Typography
                    sx={{
                      color: "#0e0d0d",
                      fontFamily: "MyTamilFontHeading",
                      fontSize: { xs: "20px", md: "60px" },
                      textAlign: 'center',
                      lineHeight: { xs: "normal", md: "99.65px" },


                    }}
                  >
                    {t("slide3.title")}
                  </Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Box sx={{ pr: '20px' }}>
                      <Box
                        component="img"
                        src={`${process.env.REACT_APP_BOOK_COVER_BASE_URL}${book.cover_image_url}`}
                        sx={{
                          width: "150px",
                          height: "180px",
                          borderRadius: '10px',
                        }}


                      ></Box>
                    </Box>

                    <Box>
                      <Box display={'flex'}>
                        <Typography variant="h6"
                          sx={{ fontWeight: "bold", width: '200px' }}
                        >{t("slide3.bookName")}
                        </Typography>
                        <Typography sx={{ fontWeight: 'bold', fontSize: '20px', display: 'inline' }}> : </Typography>
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }} width={'300px'} pl={'5px'}>

                          {book.title}</Typography>
                      </Box>
                      <Box display={'flex'}>
                        <Typography variant="h6"
                          sx={{ fontWeight: "bold", width: '200px' }}
                        >{t("slide3.author")}
                        </Typography>
                        <Typography sx={{ fontWeight: 'bold', fontSize: '20px', display: 'inline' }}> : </Typography>
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }} width={'300px'} pl={'5px'}> {book.author_name}</Typography>
                      </Box>
                      <Box display={'flex'}>
                        <Typography variant="h6"
                          sx={{ fontWeight: "bold", width: '200px' }}
                        >{t("slide3.totalReads")}
                        </Typography>
                        <Typography sx={{ fontWeight: 'bold', fontSize: '20px', display: 'inline' }}> : </Typography>
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }} width={'300px'} pl={'5px'}> {formatCount(book.views_count)}</Typography>
                      </Box>
                      <Box display={'flex'}>
                        <Typography variant="h6"
                          sx={{ fontWeight: "bold", width: '200px' }}
                        >{t("slide3.like")}
                        </Typography>
                        <Typography sx={{ fontWeight: 'bold', fontSize: '20px', display: 'inline' }}> : </Typography>
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }} width={'300px'} pl={'5px'}> {formatCount(book.likes_count)}</Typography>
                      </Box>
                      <Box display={'flex'}>
                        <Typography variant="h6"
                          sx={{ fontWeight: "bold", width: '200px' }}
                        >{t("slide3.cmt")}
                        </Typography>
                        <Typography sx={{ fontWeight: 'bold', fontSize: '20px', display: 'inline' }}> : </Typography>
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }} width={'300px'} pl={'5px'}> {formatCount(book.comments_count)}</Typography>
                      </Box>
                      <Box sx={{ paddingTop: '10px' }}>
                        <Button variant="body1" onClick={handleBookClick} color="textSecondary" sx={{ border: 'solid 1px', borderRadius: '5px', fontWeight: 'bold', display: 'block' }}>   {t("slide2.read")}</Button>
                      </Box>
                    </Box>

                  </Box>

                </Box>


              </>
            )}
          </Box>

          {/* slide 4 */}
          {slides.map(slide => (

            <Box key={slide.id} sx={{ position: "relative", height: "550px", width: "100%" }}>
              <Box
                component="img"
                src={`${process.env.REACT_APP_EVENT_BANNER_URL}${slide.image_url}`}
                sx={{ height: "550px", width: "100%", backgroundSize: "cover" }}
              />
              <Grid item xs={12} md={2} lg={2}></Grid>

              <>


                <Box sx={{

                  position: "absolute",

                  top: "20%",
                  left: "50%",
                  transform: "translateX(-50%)",
                  textAlign: "left",

                  px: 2,
                  fontWeight: "bold",
                  width: '100%',
                  height: '300px',


                }}
                >
                  {slide.slide_title &&
                  <Box sx={{                    
                    display: 'flex',
                    justifyContent: 'center',
                  }}>
                    <Typography variant="h2"
                      sx={{
                        p:"10px",
                        bgcolor:"#000000b0",
                        borderRadius:"10px",
                        fontFamily: "MyTamilFontHeading",
                        fontSize: { xs: "20px", md: "60px" },
                        color:"#ffffff"
                      }}
                    >{slide.slide_title}
                    </Typography>
                  </Box>
                  }
                  {slide.description &&
                  <Box sx={{
                    display: 'flex',
                    textAlign: 'justify',
                    justifyContent: 'center',
                    textAlignLast: 'center'
                  }}>
                    <Typography variant="h5"
                      width={'700px'}
                      height={'130px'}
                      pt={'15px'}
                      color="#ffffff"
                    > {slide.description}
                    </Typography>

                  </Box>
                  }
                  {slide.video_url &&
                  <Box component={Link} href={slide.video_url} target='_label'
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      pt: '80px',
                      textDecoration: "none",
                      color: '#ffffff'                      
                    }}>
                    <Button variant="body1" color="textSecondary" sx={{ borderRadius: '50px', fontWeight: 'bold', display: 'block', width: '250px', height: '70px', fontSize: '30px', border: '1px solid', ":hover": 'carosal' }}>Watch Now</Button>
                  </Box>
                  }

                </Box>

              </>

            </Box>
          ))}
        </Slider>
      </ThemeProvider>
    </Grid>
  );
}

export default Carousel;
