// LikeButton.jsx
import React, { useState, useEffect, useContext } from 'react';
import { IconButton } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import axiosInstance from '../utils/AxiosInstance';
import { AuthContext } from '../../hooks/context/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';


const LikeButton = ({ bookId, userId, initialLikesCount, setBooks }) => {
    const [liked, setLiked] = useState(false);
    const [likesCount, setLikesCount] = useState(initialLikesCount);
    const { isAuthenticated, user, loading} = useContext(AuthContext);

    const navigate = useNavigate();
    const location = useLocation()
    useEffect(() => {

        const fetchLikeStatus = async () => {
            try {            
                const response = await axiosInstance.get(`/books/${bookId}/like-status`, {params:{userId }});

                const { isLiked, updatedLikesCount } = response.data;
            
                setLiked(isLiked);
                setLikesCount(updatedLikesCount);
            } catch (error) {                  
                console.error('Error fetching like status:', error);
            }
        };

        fetchLikeStatus();
    }, [userId, bookId]);

    const handleLike = async () => {

        if (!isAuthenticated || user?.role !== 'user') {      
            const bookPath = location.pathname + location.search;

            navigate('/auth/login', {state:{bookPath}});
            return;
        }

        try {
            const response = await axiosInstance.post(`/books/${bookId}/like`, { user_id: userId });
            const { liked } = response.data;
            
            setLiked(liked);
            setLikesCount(prev => liked ? prev + 1 : prev - 1);

            setBooks(prevBooks =>
                prevBooks.map(book =>
                    book.book_id === bookId
                        ? { ...book, isLiked: liked, likes_count: liked ? book.likes_count + 1 : book.likes_count - 1 }
                        : book
                )
            );
        } catch (error) {
            if (error.response.status === 401) {
                window.location.href = '/auth/login';
              }      
            console.error('Error liking/disliking book:', error);
        }
    };

    return (
        <IconButton
            disableRipple
            
            onClick={handleLike}
            
        >
            <FavoriteIcon sx={{ color: liked ? 'red' : '#54666E' }} fontSize="large"/>
            {likesCount}
        </IconButton>
    );
};

export default LikeButton;
