import React, { useState, useEffect, useRef, useContext } from 'react';
import {
  TextField,
  Box,
  Typography,
  Container,
  IconButton,
  Avatar,
  Snackbar,
  Alert,
} from '@mui/material';
import { CameraAlt, Stop, Mic, Send, Delete } from '@mui/icons-material';
import axiosInstance from '../utils/AxiosInstance';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../hooks/context/AuthContext';
 
const CommentComponent = ({ bookId, userId, setBooks }) => {
  // console.log("---bookId--", bookId)
  // console.log("---userId--", userId)
  // console.log("---setBooks--", setBooks)
  const [commentText, setCommentText] = useState('');
  const [comments, setComments] = useState([]);
  const [mediaStream, setMediaStream] = useState(null);
  const [recordingVideo, setRecordingVideo] = useState(false);
  const [recordingAudio, setRecordingAudio] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [audioUrl, setAudioUrl] = useState('');
  const [userName, setUserName] = useState('John Doe');
  const [userProfilePic, setUserProfilePic] = useState('/path/to/profile-pic.jpg');
  const mediaRecorderRef = useRef(null);
  const recordedChunksRef = useRef([]);
  const { t } = useTranslation("nav")
  const [errors, setErrors] = useState();
  const [audio, setAudio] = useState(null);
  const [video, setVideo] = useState(null);
  const [isBookCreated, setIsBookCreated] = useState(false);
  const AudiofileInputRef = useRef(null);
  const VideofileInputRef = useRef(null);
  // const [vdUrl, setvdUrl] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const { isAuthenticated, user, loading} = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
 
 
 
  const startRecording = async (type) => {

    if (!isAuthenticated || user?.role !== 'user') {         
      const bookPath = location.pathname + location.search;
      navigate('/auth/login', {state:{bookPath}});
      return;
  }

    const constraints = {
      video: type === 'video' ? true : false,
      audio: type === 'audio' ? true : false,
    };
 
    const stream = await navigator.mediaDevices.getUserMedia(constraints);
    setMediaStream(stream);
 
    if (type === 'video') {
      setRecordingVideo(true);
    } else if (type === 'audio') {
      setRecordingAudio(true);
    }
 
    mediaRecorderRef.current = new MediaRecorder(stream);
    mediaRecorderRef.current.ondataavailable = (event) => {
      if (event.data.size > 0) {
        recordedChunksRef.current.push(event.data);
      }
    };
    mediaRecorderRef.current.start();
  };
  // console.log("----audio-----", audio)
  // console.log("-----video----", video)
 
 
  const stopRecording = () => {

    if (!isAuthenticated || user?.role !== 'user') {         
      const bookPath = location.pathname + location.search;
      navigate('/auth/login', {state:{bookPath}});
      return;
  }

    mediaRecorderRef.current.stop();
    mediaRecorderRef.current.onstop = () => {
      const blob = new Blob(recordedChunksRef.current, {
        type: recordingVideo ? 'video/webm' : 'audio/webm',
      });
      const url = URL.createObjectURL(blob);
      // console.log("-------url",url)
 
      if (recordingVideo) {
        setVideoUrl(url);
        setVideo(blob);
        // Store the Blob for uploading
      } else if (recordingAudio) {
        setAudioUrl(url);
        setAudio(blob);
        // Store the Blob for uploading
      }
 
      recordedChunksRef.current = [];
      setMediaStream(null);
      setRecordingVideo(false);
      setRecordingAudio(false);
    };
    mediaStream.getTracks().forEach((track) => track.stop());
  };
 
  useEffect(() => {
    const fetchComments = async () => {
 
      try {
        const response = await axiosInstance.get(`/books/${bookId}/comments`);
        setComments(response.data);
      } catch (error) {
        console.error('Error fetching comments:', error);
      }
    };
 
    fetchComments();
  }, []);
 
  // const submitComment = async (event) => {
  //   event.preventDefault();
  //   // const bookId = 1;
  //   // const userId = 6; // You can replace this with the actual user ID
 
  //   // Prepare the new comment object with user details
  //   const newComment = {
  //     userName,
  //     userProfilePic,
  //     comment_text: commentText,
  //     videoUrl,
  //     audioUrl,
  //   };
 
  //   // Update the comments state with the new comment immediately
  //   setComments([...comments, newComment]);
 
  //   // Clear the comment input and media URLs
  //   setCommentText('');
  //   setAudioUrl('');
  //   setVideoUrl('');
 
  //   try {
  //     // Post the comment to the server
  //     await axiosInstance.post(`/books/${bookId}/post-comment`, {
  //       userId,
  //       commentText,
  //       audioUrl,
  //       videoUrl,
  //     });
  //   } catch (error) {
  //     console.error('Error adding comment:', error);
  //   }
  // };
 
  const deleteComment = async (index) => {
    // const bookId = 1;
    // console.log("------index")
    const commentId = comments[index].id; // Assuming comments have an ID
    // console.log("------commentId--------",commentId)
    // Remove the comment from the comments array
    const updatedComments = comments.filter((_, i) => i !== index);
    setComments(updatedComments);
 
    try {
      // Delete the comment from the server
      await axiosInstance.delete(`/books/${bookId}/comments/${commentId}`);
    } catch (error) {
      console.error('Error deleting comment:', error);
    }
  };
 
 
 
 
  //   const handleAudioUpload = (event) => {
  //     const file = event.target.files[0];
  //     console.log("-----file",file)
  //     if (file) {
  //         const audioUrl = URL.createObjectURL(file);
  //         setAudio(file);
  //         setErrors((prevErrors) => ({
  //             ...prevErrors,
  //             audio: ''
  //         }));
  //     }
  // };
 
 
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isAuthenticated || user?.role !== 'user') {         
      const bookPath = location.pathname + location.search;
      navigate('/auth/login', {state:{bookPath}});
      return;
  }
 
    const newErrors = {
      // authorName: validateAuthorName(form.authorName),
      // bookTitle: validateBookTitle(form.bookTitle),
      // description: validateDescription(form.description),
      audio: audio ? '' : 'audio is required',
    };
 
    // setErrors(newErrors);
    // const hasErrors = Object.values(newErrors).some(error => error);
    // console.log("hasErrors",hasErrors)
    // if (!hasErrors) {
    // console.log("0")
    const formData = new FormData();
    if (audio) {
      formData.append('audio', audio, 'recording.webm'); // Append Blob with filename
    }
    if (video) {
      formData.append('video', video, 'recording.webm'); // Append Blob with filename
    }
    try {
 
 
      if (audio) {
        const AudioResponse = await axiosInstance.post('/upload/audio', formData);
        const AudiofileName = AudioResponse.data.fileName;
        // console.log('Uploaded file name------:', AudiofileName);
        const newComment = {
          userId,
          userName,
          userProfilePic,
          comment_text: commentText,
          audioUrl: AudiofileName,
          videoUrl: videoUrl,
        };
 
        // console.log("newComment", newComment)/
        // Update the comments state with the new comment immediately
        // setComments([...comments, newComment]);
 
        // Clear the comment input and media URLs
        setCommentText('');
        setAudioUrl('');
        setVideoUrl('');
 
        const bookResponse = await axiosInstance.post(`/books/${bookId}/post-comment`, newComment);
        // console.log('Book created successfully', bookResponse.data);
        setSnackbarMessage("Comment successfully submitted");
          setSnackbarSeverity("success");
          setOpenSnackbar(true);
        if (bookResponse.data) {
          setIsBookCreated(true);
        }
      }
 
      if (video) {
        const VideoResponse = await axiosInstance.post('/upload/video', formData);
        // console.log("---VideoResponse--", VideoResponse)
        const VideofileName = VideoResponse.data.fileName;
        // console.log('Uploaded file name------:', VideofileName);
        const newComment = {
          userId,
          userName,
          userProfilePic,
          comment_text: commentText,
          audioUrl: audioUrl,
          videoUrl: VideofileName,
        };
 
        // console.log("newComment", newComment)
        // Update the comments state with the new comment immediately
        // setComments([...comments, newComment]);
 
        // Clear the comment input and media URLs
        setCommentText('');
        setAudioUrl('');
        setVideoUrl('');
 
        const bookResponse = await axiosInstance.post(`/books/${bookId}/post-comment`, newComment);
        // console.log('Book created successfully', bookResponse.data);
        setSnackbarMessage("Comment successfully submitted");
          setSnackbarSeverity("success");
          setOpenSnackbar(true);

        if (bookResponse.data) {
          setIsBookCreated(true);
        }
 
      }
 
 
      if (commentText) {
        const newComment = {
          userName,
          userProfilePic,
          comment_text: commentText,
          videoUrl,
          audioUrl,
        };
 
        // Update the comments state with the new comment immediately
        // setComments([...comments, newComment]);
 
        // Clear the comment input and media URLs
        setCommentText('');
        setAudioUrl('');
        setVideoUrl('');
 
        try {
          // Post the comment to the server
          await axiosInstance.post(`/books/${bookId}/post-comment`, {
            userId,
            commentText,
            audioUrl,
            videoUrl,
          });
          setSnackbarMessage("Comment successfully submitted");
          setSnackbarSeverity("success");
          setOpenSnackbar(true);
        } catch (error) {
          if (error.response.status === 401) {
            window.location.href = '/auth/login';
          }      
          console.error('Error adding comment:', error);
        }
      }
 
 
    } catch (error) {
      console.error('Error creating the book:', error);
    }
 
    // }
  };
 
  const handleDeleteVideo = () => {
    setVideoUrl('');
  };
 
  const handleDeleteAudio = () => {
    setAudioUrl('');
  };
 
 
 
  return (
    <Container>
      <Box
        my={2}
        sx={{
          padding: '5px',
          width: '800px',
          height: 'auto',
          bgcolor: 'background.paper',
          borderRadius: 2,
        }}
      >
        <form onSubmit={handleSubmit}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              label={t("bookdis.entercomment")}
              variant="outlined"
              fullWidth
              multiline
              rows={1} // Adjusted to display a single row initially
              value={commentText}
              onChange={(e) => setCommentText(e.target.value)}
              sx={{
                mb: 2,
                width: '600px', // Adjusted width
                '& .MuiOutlinedInput-root': { borderRadius: 2 },
                '&:hover .MuiOutlinedInput-root': { borderColor: 'primary.main' },
              }}
            />
            <IconButton
              color={recordingVideo ? 'success' : 'default'}
              onClick={recordingVideo ? stopRecording : () => startRecording('video')}
              sx={{
                transition: 'color 0.3s',
                '&:hover': { color: 'primary.main' },
                ml: 1,
                border: '1px solid', // Added border
              }}
              // onChange={handleVideoUpload}
              ref={VideofileInputRef}
            >
              {recordingVideo ? <Stop /> : <CameraAlt />}
            </IconButton>
            <IconButton
              color={recordingAudio ? 'success' : 'default'}
              onClick={recordingAudio ? stopRecording : () => startRecording('audio')}
              sx={{
                transition: 'color 0.3s',
                '&:hover': { color: 'primary.main' },
                ml: 1,
                border: '1px solid', // Added border
              }}
              // onChange={handleAudioUpload}
              ref={AudiofileInputRef}
            >
              {recordingAudio ? <Stop /> : <Mic />}
            </IconButton>
            <IconButton
              variant="contained"
              color="primary"
              type="submit"
              sx={{
                transition: 'color 0.3s',
                '&:hover': { color: 'secondary.main' },
                ml: 1,
                border: '1px solid', // Added border
              }}
            >
              <Send />
            </IconButton>
          </Box>
          <Box mt={2}>
            {videoUrl && (
              <Box position="relative" display="inline-block">
                <video
                  src={videoUrl}
                  controls
                  style={{ width: '400px', height: '400px', marginBottom: 10 }}
                />
                <IconButton
                  onClick={handleDeleteVideo}
                  sx={{
                    position: 'absolute',
                    top: 25,
                    right: 8,
                    color: 'white',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.7)' },
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            )}
            {audioUrl && (
              <Box position="relative" display="inline-block">
                <audio src={audioUrl} controls style={{ width: '300px' }} />
                <IconButton
                  onClick={handleDeleteAudio}
                  sx={{
                    position: 'absolute',
                    top: 8,
                    right: -45,
                    color: 'white',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.7)' },
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            )}
          </Box>
        </form>
      </Box>
      <Box mt={4}>
        {comments.map((comment, index) => (
          <Box
            key={index}
            sx={{
              marginBottom: 2,
              bgcolor: 'background.paper',
              borderRadius: 2,
              boxShadow: 3,
              padding: 2,
              transition: 'transform 0.3s',
              '&:hover': { transform: 'scale(1.02)' },
              position: 'relative',
            }}
          >
            <Box display="flex" alignItems="center">
              <Avatar
                src={comment.profile_picture}
                alt={comment.username}
                sx={{ mr: 2 }}
              />
              <Typography variant="h6">{comment.username}</Typography>
            </Box>
            <Typography variant="body1" sx={{ mt: 1 }}>
              {comment.comment_text}
            </Typography>
            {comment.video_url && (
              <video
                src={`https://story-buzz.blr1.digitaloceanspaces.com/uploads/books/comments/video/${comment.video_url}`}
                controls
                controlsList="nodownload"
                style={{ width: '100%', marginTop: 10 }}
              />
            )}
            {comment.audio_url && (
              <audio
                src={`https://story-buzz.blr1.digitaloceanspaces.com/uploads/books/comments/audio/${comment.audio_url}`}
                controls
                controlsList="nodownload"
                style={{ width: '100%', marginTop: 10 }}
              />
            )}
            {/* <IconButton
              onClick={() => deleteComment(index)}
              sx={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                color: 'red',
                '&:hover': { color: 'darkred' },
              }}
            >
              <Delete />
            </IconButton> */}
          </Box>
        ))}
         <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      </Box>
    </Container>
  );
};
 
export default CommentComponent;