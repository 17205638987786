import React, { useContext, useEffect, useState } from 'react';
import { ThemeProvider } from '@emotion/react';
import theme from '../ui/Theme';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Box, Card, CardContent, CardMedia, Container, Grid, IconButton, Typography } from '@mui/material';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useTranslation } from 'react-i18next';
import girl from "../../assets/girl.jpg";
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookCard from '../common/BookCard';
import axiosInstance from '../utils/AxiosInstance';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faComment } from '@fortawesome/free-solid-svg-icons';
import LikeButton from '../common/LikeButton';
import "../CSS/slider.css"
import { LanguageContext } from '../../hooks/context/LanguageContext';



function NextArrow(props) {
    const { onClick } = props;
    return (
        <IconButton
            onClick={onClick}
            sx={{
                display: 'flex',
                justifyContent: "center",
                alignItems: "center",
                background: '#76767691',
                color: '#000000a5',
                position: 'absolute',
                top: '50%',
                height: "30px",
                width: "30px",
                right: '10px',
                padding: "0px",
                transform: 'translateY(-50%)',
                zIndex: 1,
                '&:hover': {
                    background: '#646464db',
                },
                '&:focus': {
                    outline: 'none',
                },
            }}
        >
            <ArrowForwardIos fontSize='medium' sx={{ ml: "2px" }} />
        </IconButton>
    );
}

function PrevArrow(props) {
    const { onClick } = props;
    return (
        <IconButton
            onClick={onClick}
            sx={{
                display: 'flex',
                justifyContent: "center",
                alignItems: "center",
                background: '#76767691',
                color: '#000000a5',
                position: 'absolute',
                left: '10px',
                top: '50%',
                padding: "0px",
                height: "30px",
                width: "30px",
                transform: 'translateY(-50%)',
                zIndex: 1,
                '&:hover': {
                    background: '#646464db',
                },
                '&:focus': {
                    outline: 'none',
                },
            }}
        >
            <ArrowBackIos fontSize='medium' sx={{ ml: "6px" }} />
        </IconButton>
    );
}

function Popular() {
    const { t } = useTranslation("home");
    const { language } = useContext(LanguageContext);
    const [books, setBooks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    // const userId = 1;

    const handleBookClick = (book) => {
        navigate(`/book/${book.book_id}`);
      };
  
    useEffect(() => {
      const fetchRecentBooks = async () => {
        try {
          const response = await axiosInstance.get('/books/popular'); 
          setBooks(response.data);
        //   console.log(response.data)
        } catch (err) {
          setError(err);
        } finally {
          setLoading(false);
        }
      };
  
      fetchRecentBooks();
    }, [language]);
  
    // if (loading) return <div>Loading...</div>;



    const settings = {
        className: "center",
        infinite: true,
        autoplay: true,
        speed: 5000, 
        pauseOnHover: true,
        slidesToShow: 5,
        autoplaySpeed: 0,
        cssEase: "linear",
        swipeToSlide: true,
        nextArrow: <></>,
        prevArrow: <></>,
        afterChange: function (index) {
            // console.log("slide");
        },
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,                    
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <ThemeProvider theme={theme}>
            <Box className="multi-slider" sx={{display:"flex", justifyContent:"center" , mt:"-30px"}}>
                <Box sx={{ width: "100%", justifyContent:"center" }}>
                <Typography textAlign="center" mt="30px" mb="30px" fontFamily= 'MyTamilFontHeading'  fontWeight="bold" variant='h4' className="admin" >{t("headtitle.PopularOnStoryBuzz")}</Typography>
                <Slider {...settings}>
               
                    {books.map((book) => (         
                        <Container  justifyContent="center">
                        <BookCard
                            key={book.book_id}
                            book={book}
                            onBookClick={handleBookClick}                            
                            setBooks={setBooks} 
                        />       
                        </Container>
                                             
                ))}
           
                </Slider>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default Popular;
