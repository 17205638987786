import React, { useEffect,useState } from "react";
import googleIcon from "../../assets/final/Google.png"
import { Box, Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

const GoogleAuth = ({ setLoggedInMessage, setInvalidUserMessage, label,setSnackbarOpen }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { cbPath, form, image, bookPath } = location.state || {};
  

  // const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    const handleGoogleAuth = (event) => {
      console.log(event.origin,process.env.REACT_APP_SERVER_ORIGIN);
      
      if (event.origin !== process.env.REACT_APP_SERVER_ORIGIN){             
        return;
      } 

      const { data } = event;
      
      if (data.type === "success") {
        setLoggedInMessage("Login successful");
        setSnackbarOpen(true);
      
        setTimeout(() => {
          const redirectUrl = data.redirectUrl || '/';
             
          if(cbPath){            
            navigate(cbPath, {state:{form, image}})    
            window.location.href = cbPath;
        }else if(bookPath){
          window.location.href = bookPath;
        }else{
          window.location.href = redirectUrl;
        }
          
        }, 2000); // 3000 milliseconds delay before redirecting

      } else if (data.type === "failure") {
        setInvalidUserMessage("Google authentication failed");
        window.location.href = '/auth/login';
      }

      // Cleanup listener
      window.removeEventListener("message", handleGoogleAuth);
    };

    window.addEventListener("message", handleGoogleAuth);

    return () => {
      window.removeEventListener("message", handleGoogleAuth);
    };
  }, [setLoggedInMessage, setInvalidUserMessage]);


  const loginGoogle = () => {
    const width = 600;
    const height = 600;
    const parentWindow = window;

    const left = (parentWindow.screen.width / 2) - (width / 2);
    const top = (parentWindow.screen.height / 2) - (height / 2);

    const popup = parentWindow.open(
      `${process.env.REACT_APP_API_BASE_URL}/auth/oauth2/google`,
      'Google Login',
      `width=${width},height=${height},left=${left},top=${top},menubar=no,toolbar=no,status=no,scrollbars=no,resizable=no`
    );

    const interval = setInterval(() => {
      if (popup.closed) {
        clearInterval(interval);
      }
    }, 1000);
  };

  return (
    <Button variant="outlined" sx={{mt:"10px", borderRadius:"40px", textTransform:"none", borderColor: '#000000', color: '#000000','&:hover': {backgroundColor: '#00000000', borderColor: '#000000', },}} onClick={loginGoogle}><Box component="img" src={googleIcon} width={40} mr={0.5}/>{label}</Button>
  );
};

export default GoogleAuth;
