import React, { useState, useEffect } from 'react';
import { Box, Grid, ThemeProvider, Typography } from '@mui/material';
import theme from '../ui/Theme';
import { useTranslation } from 'react-i18next';
 
 
function Counter() {
  const { t } = useTranslation("common");
  const [booksCount, setBooksCount] = useState(0);
  const [readsCount, setReadsCount] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [loading, setLoading] = useState(true);




  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const booksResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/counters/published-books`);
        const booksData = await booksResponse.json();
        const readsResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/counters/views-count`);
        const readsData = await readsResponse.json();
        const usersResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/counters/users-count`);
        const usersData = await usersResponse.json();
        // console.log("count", usersData);
        

        incrementCounter(setBooksCount, booksData, 50);
        incrementCounter(setReadsCount, readsData, 50);
        incrementCounter(setUserCount, usersData, 50);
      } catch (error) {
        console.error('Error fetching counts:', error);
      }
    };
    fetchCounts();
  }, []);
  // if (loading){
  //   return(
  
  //     <Box display="flex" justifyContent="center" alignItems="center" height="100vh">

  //     <Box display="flex" justifyContent="center" alignItems="center" height="100vh"> 

  //     <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
  //     <Box display="flex" justifyContent="center" alignItems="center" height="100vh">

  //     <Loading/>
  //     </Box>
  //   )
  // }

  const incrementCounter = (setter, maxCount, interval) => {
    let currentCount = 0;
    const counter = setInterval(() => {
      currentCount += 10;
      if (currentCount <= maxCount) {
        setter(currentCount);
      } else {
        setter(maxCount);
        clearInterval(counter);
      }
    }, interval);
  };

  const formatCount = (count) => {
    if (count >= 1000) {
      return `${Math.floor(count / 1000)}k`;
    }
    return count.toString();
  };

  const fixedWidthStyle = {
    width: '120px',
    textAlign: 'center',
  };

  return (
    <ThemeProvider theme={theme}>
      <Box display="flex" justifyContent="center" mt="20px">
        <Grid container width="80%" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
              <Typography variant='h2' sx={{ color: "#191970", ...fixedWidthStyle  }} fontFamily="Fredoka" fontWeight="bold">
                {formatCount(booksCount)}+
              </Typography>
              <Typography variant='caption' sx={{ color: "text.primary" }} textAlign="center" fontFamily="Coiny">
                {t("counter.books")}
              </Typography>
            </Box>
          </Grid>

          <Grid item>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
              <Typography variant='h2' sx={{ color: "#191970", ...fixedWidthStyle  }} fontFamily="Fredoka" fontWeight="bold">
                {formatCount(readsCount)}+
              </Typography>
              <Typography variant='caption' sx={{ color: "text.primary" }} textAlign="center" fontFamily="Coiny">
                {t("counter.reads")}
              </Typography>
            </Box>
          </Grid>

          <Grid item>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
              <Typography variant='h2' sx={{ color: "#191970", ...fixedWidthStyle  }} fontFamily="Fredoka" fontWeight="bold">
                {formatCount(userCount)}+
              </Typography>
              <Typography variant='caption' sx={{ color: "text.primary" }} textAlign="center" fontFamily="Coiny">
                {t("counter.users")}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  )
}
 
export default Counter;