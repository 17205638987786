import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

const Captcha = ({ onCaptchaChange }) => {
  const handleCaptchaChange = (value) => {
    onCaptchaChange(value);
  };

  return (    
      <ReCAPTCHA
        sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
        onChange={handleCaptchaChange}
      />    
  );
};

export default Captcha;