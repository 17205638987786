import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    Radio,
    RadioGroup,
    FormControlLabel,
    Typography,
    Box,
} from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const CustomToolbar = () => (
    <div id="toolbar">
        <span className="ql-formats">
            <button className="ql-bold" title="Bold"></button>
            <button className="ql-italic" title="Italic"></button>
            <button className="ql-underline" title="Underline"></button>
            <button className="ql-strike" title="Strikethrough"></button>
        </span>
        <span className="ql-formats">
            <button className="ql-list" value="ordered" title="Ordered List"></button>
            <button className="ql-list" value="bullet" title="Bullet List"></button>
        </span>
        <span className="ql-formats">
            <select className="ql-color" title="Text Color"></select>
            <select className="ql-background" title="Background Color"></select>
        </span>
        <span className="ql-formats">
            <button className="ql-clean" title="Remove Formatting"></button>
        </span>
    </div>
);

const TextEditorDialog = ({ open, onClose, onAddText }) => {
    const [text, setText] = useState('');  // Starts empty
    const [textSize, setTextSize] = useState(30);
    const [textColor, setTextColor] = useState('#000000');
    const [isBold, setIsBold] = useState(false);
    const [isItalic, setIsItalic] = useState(false);
    const [charCount, setCharCount] = useState(0);
    const maxChars = 500;

   

    const handleTextChange = (value) => {
        // Strip HTML tags to count only text characters
        const plainText = value.replace(/<\/?[^>]+(>|$)/g, "");

        // If the character count exceeds the limit, stop updates
        if (plainText.length <= maxChars) {
            setText(value); // Update the editor content
            setCharCount(plainText.length); // Update the character count
        }
    };

    const handleTextSizeChange = (event) => {
        setTextSize(Number(event.target.value));
    };

    const handleAddText = () => {
        onAddText({ text, textSize, textColor, isBold, isItalic });
        setText('');
        setTextColor('#000000');
        setTextSize(18);
        setIsBold(false);
        setIsItalic(false);
        onClose();
    };

    const handleCloseDialog = () => {
        onClose();
        setText('');
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            sx={{
                '& .MuiDialog-paper': {
                    minHeight: '400px',
                    maxHeight: '90vh',
                },
            }}
        >
            <DialogTitle
                sx={{
                    backgroundColor: 'white',
                }}>Text</DialogTitle>
            <DialogContent
                sx={{
                    backgroundColor: 'white',
                }}>
                <Typography variant="subtitle1" sx={{ marginTop: 2 }}>
                    Text size
                </Typography>
                <RadioGroup row value={textSize} onChange={handleTextSizeChange}>
                    <FormControlLabel
                        value={30}
                        control={<Radio />}
                        label="Heading 1 (30px)"
                    />
                    <FormControlLabel
                        value={28}
                        control={<Radio />}
                        label="Heading 2 (28px)"
                    />
                    <FormControlLabel
                        value={18}
                        control={<Radio />}
                        label="Paragraph (18px)"
                    />
                </RadioGroup>

                <Typography variant="subtitle1">Page text</Typography>
                <Box>
                    <CustomToolbar />
                    <ReactQuill
                        value={text}
                        onChange={handleTextChange}
                        theme="snow"
                        modules={{
                            toolbar: {
                                container: "#toolbar",
                            },
                        }}
                        formats={['bold', 'italic', 'underline', 'strike', 'header', 'list', 'color', 'background', 'align']}
                        style={{
                            height: '200px',
                            marginBottom: '20px',
                            fontSize: `${textSize}px`,
                            color: textColor,
                            fontWeight: isBold ? 'bold' : 'normal',
                            fontStyle: isItalic ? 'italic' : 'normal',
                        }}
                    />
                    <div style={{ marginTop: '10px', fontSize: '14px', color: '#555' }}>
                        Character count: {charCount}/{maxChars}
                    </div>
                    {charCount >= maxChars && (
                        <div style={{ color: 'red', fontSize: '12px' }}>
                            You have reached the maximum character limit of {maxChars} characters.
                        </div>
                    )}
                </Box>

                <Box textAlign="center" sx={{ marginTop: 8 }}>
                    <Button variant="contained" color="primary" onClick={handleAddText} disabled={!text.trim()}>
                        ADD TEXT TO PAGE
                    </Button>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: '#FF0004',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: '#D00000',
                            },
                        }}
                        onClick={handleCloseDialog}
                    >
                        Close
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default TextEditorDialog;
