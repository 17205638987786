import React, { useState } from "react";
import { TextField, Button, Grid, Typography, Box, ThemeProvider,IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, InputAdornment, Divider, Chip, Modal, MenuItem,CircularProgress } from "@mui/material";
import axiosInstance from "../utils/AxiosInstance";
import { Link, useNavigate } from "react-router-dom";
import theme from "../ui/Theme";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Checkbox from '@mui/material/Checkbox';
import bgImg from "../../assets/final/Login.jpg"
import GoogleAuth from "./GoogleAuth";
import NotMatch from '@mui/icons-material/HighlightOff';
import ReCAPTCHA from 'react-google-recaptcha';
import Navbar from "../layout/Navbar";
import RegTerms from "./RegTerms";
import PasswordValidator from "./PasswordValidator";
import TermsModal from "./TermsModal";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';


function Register({onCloseParentDialog, onSuccess}) {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [role, setRole] = useState("user");
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [alreadyRegMessage, setAlreadyRegMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [validateErrorMessage, setValidateErrorMessage] = useState('')
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [loggedInmessage, setLoggedInMessage] = useState("");
  const [invalidUserMessage ,setInvalidUserMessage] = useState("");
  const [termsOpen, setTermsOpen] = useState(false); 
  const [isTermsChecked, setIsTermsChecked] = useState(false); 
  const [passwordFocused, setPasswordFocused] = useState(false);
  const [focused, setFocused] =useState(false)
  const [showPassword, setShowPassword] = useState(false);
  const [addUser, setAddUser] = useState(false)
  const [validations, setValidations] = useState({
    hasUppercase: false,
    hasLowercase: false,
    hasNumber: false,
    hasSpecialChar: false,
    isLongEnough: false,
  });
  

  const navigate = useNavigate();


//Validations
  const validatePassword = (password) => {
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const isLongEnough = password.length >= 8;


    setValidations({
      hasUppercase,
      hasLowercase,
      hasNumber,
      hasSpecialChar,
      isLongEnough,
    });
  };
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordFocus = () => {
    setPasswordFocused(true);
  };

  const handlePasswordBlur = () => {
    if (!password) {
      setPasswordFocused(false);
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    if (onCloseParentDialog) {
      onCloseParentDialog();
      onSuccess();
    }
  };

//Password
const handlePasswordChange = (e) => {
  const newPassword = e.target.value;
  setPassword(newPassword);
  validatePassword(newPassword);
  setPasswordMismatch("")
  setValidateErrorMessage("");
};

const handleConfirmPassword = (e) => {
  const value=(e.target.value)
  setConfirmPassword(value) 
  setPasswordMismatch("")
  //setFocused(true)
  if (value){
    setFocused(true)
  }
  else{
    setFocused(false)
  }
}
const handleBlur =()=>{
  setFocused(false)
}

// Submit
const handleSubmit = async (event) => {
  event.preventDefault();
 setAddUser(true)
  //validator
  if (!validations.hasUppercase || !validations.hasLowercase || !validations.hasNumber || !validations.hasSpecialChar || !validations.isLongEnough) {
    setValidateErrorMessage("Password does not meet criteria.");
    return;
  }
  setValidateErrorMessage("");

  
  if (password !== confirmPassword) {
    setPasswordMismatch(true);
    return;
  }
  setPasswordMismatch(false);


  try {
    const response = await axiosInstance.post("/auth/register-admin", { firstName, lastName, email, password, role}, { withCredentials: true });
    if (response.status === 200 && response.data.message === "Registration successful") {
      setSuccessMessage("Registration Success");
      // console.log(response.data);
      setDialogOpen(true);
      // navigate("/login");
    }
  } catch (error) {
    if (error.response && error.response.status === 400 && error.response.data.error === "Email already registered. Please login.") {
      setAlreadyRegMessage("User already registered ");
    } else {
      setErrorMessage("Internal server error. Please try again later.");
      // console.log(`Error: ${error.response ? error.response.data.error : error.message}`);
    }
  }finally{
    setAddUser(false)
  }
};

  return (
    <ThemeProvider theme={theme}>    
    
      <Grid container direction="column" justifyContent="center" alignItems="center"  width="380px" p={5} sx={{bgcolor:{xs:"#ffffffff", md:"#ffffff39", }}}>
        <Grid item xs={8} sm={8} md={6} lg={3}>
        <Typography variant="h4" align="center" fontWeight="bold" color="#000000">Add User</Typography>
          <form onSubmit={handleSubmit}>
            <TextField required autoFocus label="First Name" fullWidth margin="normal" value={firstName} onChange={(e) => setFirstName(e.target.value)} sx={{ '& .MuiOutlinedInput-root': {borderRadius: '10px', height:"50px",}, '& .MuiInputLabel-root': { color: 'black',},}}/>
            <TextField required label="Last Name" fullWidth margin="normal" value={lastName} onChange={(e) => setLastName(e.target.value)} sx={{ '& .MuiOutlinedInput-root': {borderRadius: '10px', height:"50px",}, '& .MuiInputLabel-root': { color: 'black',},}}/>
            <TextField required label="Email" type="email" fullWidth margin="normal" value={email} onChange={(e) => setEmail(e.target.value)} sx={{ '& .MuiOutlinedInput-root': {borderRadius: '10px', height:"50px",}, '& .MuiInputLabel-root': { color: 'black',},}}/>
            <TextField required label="Password" type={showPassword ? 'text' : 'password'} fullWidth margin="normal" value={password} onChange={handlePasswordChange} onFocus={handlePasswordFocus} onBlur={handlePasswordBlur} sx={{ '& .MuiOutlinedInput-root': {borderRadius: '10px', height:"50px",}, '& .MuiInputLabel-root': { color: 'black',},}}
             InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            />

            {passwordFocused && (
            <PasswordValidator validations={validations} validateErr={validateErrorMessage}/>            
            )}
            
            <TextField required label="Confirm Password" type="password" fullWidth margin="normal" value={confirmPassword}
              onChange={handleConfirmPassword}
             // onBlur={handleBlur}
              sx={{ '& .MuiOutlinedInput-root': {borderRadius: '10px', height:"50px",}, '& .MuiInputLabel-root': { color: 'black',},}}
              InputProps={{ endAdornment: (
                focused && (                                
                <InputAdornment position="end">
                   { password && confirmPassword && password === confirmPassword ? (
                    <CheckCircleIcon color="success" />
                    ) : <NotMatch color='error'/>
                    }
                </InputAdornment> 
                )  
              ),}}/>
            <TextField required select label="Role" fullWidth margin="normal" value={role} onChange={(e) => setRole(e.target.value)} sx={{ '& .MuiOutlinedInput-root': {borderRadius: '10px', height:"50px",}, '& .MuiInputLabel-root': { color: 'black',},}}>
            <MenuItem value="user">User</MenuItem>
            <MenuItem value="admin">Admin</MenuItem>
            <MenuItem value="editor">Editor</MenuItem>
          </TextField>

            {passwordMismatch && (
                <Typography color="error" mt="10px" mb="10px">Passwords does not match</Typography>
              )}

            {alreadyRegMessage && (
              <Typography color="error" mt="10px" mb="10px">{alreadyRegMessage}<Typography color="error" sx={{textDecoration:"underline"}}>User already exist.</Typography></Typography>
            )}

{/* <ReCAPTCHA
          sitekey="YOUR_SITE_KEY" // Replace with your site key
              
            /> */}

            <Button type="submit" variant="contained" fullWidth color="primary" sx={{mt:"20px", color: '#ffffff', backgroundColor:"#000000", '&:hover': {backgroundColor: '#000000ff', borderColor: '#000000', },}}>
              {/* Add User */}
              {addUser?<Box><CircularProgress size={15} sx={{ color: "#ffffff" }} /></Box> :'Add User'}
            </Button>
          </form>
        </Grid>

      </Grid>

      <Dialog open={dialogOpen} keepMounted onClose={handleDialogClose} aria-describedby="alert-dialog-slide-description">
        <DialogTitle>{successMessage}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">User Added Successfully</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Ok</Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}
 
export default Register;
