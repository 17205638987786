import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    Paper,
    TextField,
    IconButton,
    Box,
    Button,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import UploadIcon from '@mui/icons-material/Upload';


const ItemsDialog = ({ open, onClose, onImageSelect, setEmpty }) => {
    const [images, setImages] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [uploadedImages, setUploadedImages] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);

    const fetchImages = async (query = '') => {
        const API_KEY = '45689426-43544d700520b0a765aa13375';
        const response = await fetch(
            `https://pixabay.com/api/?key=${API_KEY}&q=${encodeURIComponent(query)}&image_type=photo&per_page=20&safesearch=true`
        );
        const data = await response.json();
        setImages(data.hits);
    };

    useEffect(() => {
        fetchImages();
    }, []);

    const handleSearch = () => {
        fetchImages(searchTerm);

    };

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        if (files.length > 0) {
            const file = files[0];
            const fileUrl = URL.createObjectURL(file); // Create object URL for the uploaded image
            onImageSelect(fileUrl); // Immediately set the image in the Paper component
            onClose(); // Close the dialog after the upload
        }
    };



    const handleImageSelect = (url) => {
        setSelectedImage(url);
        onImageSelect(url);
        onClose();
        // setEmpty(true)
    };


    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Items</DialogTitle>
            <DialogContent>
                <Box sx={{ marginBottom: '20px' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                        <input
                            type="file"
                            accept="image/*"
                            multiple
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                            id="image-upload"
                        />
                        <label htmlFor="image-upload">
                            <IconButton component="span" color="primary">
                                <UploadIcon style={{ marginRight: '8px' }} />
                                <span>Upload Images</span>
                            </IconButton>
                        </label>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            placeholder="Search"
                        />
                        <IconButton onClick={handleSearch}>
                            <SearchIcon />
                        </IconButton>
                    </Box>
                </Box>

                <Grid container spacing={2}>
                    {[...uploadedImages, ...images].map((image) => (
                        <Grid item xs={3} key={image.id}>
                            <Paper
                                sx={{
                                    padding: '10px',
                                    cursor: 'pointer',
                                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                    height: '150px', // Fixed height
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                                onClick={() => { handleImageSelect(image.url || image.webformatURL) }}
                            >
                                <img
                                    src={image.url || image.webformatURL}
                                    alt={image.name || image.tags}
                                    style={{
                                        height: '100%',
                                        width: '100%',
                                        objectFit: 'cover',
                                    }}
                                />
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: '#FF0004',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: '#D00000',
                            },
                        }}
                        onClick={onClose}
                    >
                        Close
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default ItemsDialog;
