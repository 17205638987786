import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, Card, CardContent } from '@mui/material';
import { Bar, BarChart, Legend, ResponsiveContainer, XAxis, YAxis, Tooltip } from 'recharts';
import ReportCard from '../adminPanel/ReportCard/ReportCard';
import { useTheme } from '@mui/material/styles';
import BooksIcon from '@mui/icons-material/Book';
import ReadsIcon from '@mui/icons-material/LibraryBooks';
import ApprovalIcon from '@mui/icons-material/ThumbUp';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import UsersIcon from '@mui/icons-material/Group';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';

const Dashboard = () => {
  const theme = useTheme();
  const [totalBooksCount, setTotalBooksCount] = useState(0);
  const [publishedBooksCount, setPublishedBooksCount] = useState(0);
  const [approvedBooksCount, setApprovedBooksCount] = useState(0);
  const [rejectedBooksCount, setRejectedBooksCount] = useState(0);
  const [submittedBooksCount, setSubmittedBooksCount] = useState(0);
  const [fABooksCount, setFABooksCount] = useState(0);
  const [totalUserCount, setTotalUserCount] = useState(0);

  const [monthlyData, setMonthlyData] = useState([]);

  // console.log("monthlyData", monthlyData);
  

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const allBooksResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/counters/all-books`);
        const allBooksCount = await allBooksResponse.json();
        const publishedBooksResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/counters/published-books`);
        const publishedBooksCount = await publishedBooksResponse.json();
        const approvedBooksResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/counters/approved-books`);
        const approvedBooksCounts = await approvedBooksResponse.json();
        const rejectedBooksResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/counters/rejected-books`);
        const rejectedBooksCount = await rejectedBooksResponse.json();
        const submittedBooksResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/counters/submitted-books`);
        const submittedBooksCount = await submittedBooksResponse.json();
        const wFABooksResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/counters/wfa-books`);
        const wFABooksCount = await wFABooksResponse.json();
        const usersResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/counters/users-count`);
        const usersCount = await usersResponse.json();

        setTotalBooksCount(allBooksCount);
        setPublishedBooksCount(publishedBooksCount);
        setApprovedBooksCount(approvedBooksCounts);
        setRejectedBooksCount(rejectedBooksCount);
        setSubmittedBooksCount(submittedBooksCount);
        setFABooksCount(wFABooksCount);
        setTotalUserCount(usersCount);
      } catch (error) {
        console.error('Error fetching counts:', error);
      }
    };

    const fetchMonthlyData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/counters/monthly-stats`);
        const data = await response.json();
        setMonthlyData(data);
      } catch (error) {
        console.error('Error fetching monthly stats:', error);
      }
    };

    fetchCounts();
    fetchMonthlyData();
  }, []);

  return (
    <Box>
      <Grid container direction="row">
        <Grid item xs={12} justifyContent="center" alignContent="center">
          <Box sx={{ display: "flex", justifyContent: "center"}}>
            <Typography variant='h3'>Dashboard</Typography>
          </Box>
        </Grid>

        <Grid container direction="row" spacing={2} justifyContent="space-around" sx={{ marginTop: 2 }}>
          <Grid item xs={12} sm={6} md={4} lg={3} >
            <ReportCard
              primary="Total Books"
              secondary={totalBooksCount}
              iconPrimary={BooksIcon}
              color={theme.palette.primary.main}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <ReportCard
              primary="Published Books"
              secondary={publishedBooksCount}
              iconPrimary={ReadsIcon}
              color={theme.palette.secondary.main}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <ReportCard
              primary="Approved Books"
              secondary={approvedBooksCount}
              iconPrimary={ApprovalIcon}
              color={theme.palette.success.main}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <ReportCard
              primary="Rejected Books"
              secondary={rejectedBooksCount}
              iconPrimary={ThumbDownAltIcon}
              color={theme.palette.warning.main}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <ReportCard
              primary="Submitted Books"
              secondary={submittedBooksCount}
              iconPrimary={ImportContactsIcon}
              color={theme.palette.warning.main}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <ReportCard
              primary="Waiting for Approval"
              secondary={fABooksCount}
              iconPrimary={LibraryAddCheckIcon}
              color={theme.palette.warning.main}
            />
          </Grid>
          
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <ReportCard
                primary="Total Users"
                secondary={totalUserCount}
                iconPrimary={UsersIcon}
                color={theme.palette.info.main}
              />
            </Grid>          
        </Grid>
        <Grid container direction="row" sx={{ marginTop: 10, marginLeft: 25 }}>
          <Grid item  xs={12} sm={10} md={8}>
            <Card sx={{ width: '800px', borderRadius: '15px' }}>
              <CardContent>
                <Typography variant="h5" align="center" gutterBottom>
                  Users Registered/Books Published in a Month
                </Typography>
                <ResponsiveContainer width="100%" height={400}>
            <BarChart data={monthlyData}>
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="users" fill="#8884d8" name="Users" />
              <Bar dataKey="booksPublished" fill="#82ca9d" name="Books Published" />
            </BarChart>
          </ResponsiveContainer>

              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} marginTop={5}>
          {/* <iframe
      src="http://192.168.0.144:3000/public/dashboard/a0b5f78f-45ee-4761-9afc-6bfc68f6f18c"
      frameBorder="0"
      width="800"
      height="600"
      allowTransparency="true"
      title="Dashboard"
      style={{ border: 0 }}
    ></iframe> */}
    {/* <iframe     src="
http://192.168.0.144:3000/public/dashboard/dca03d82-41fb-4cc3-bcd0-65b1111e58fe"
    frameborder="0"     width="800"     height="600"     allowtransparency ></iframe> */}
            {/* Additional content can go here */}
            {/* <iframe     src="http://192.168.0.114:3000/public/dashboard/dca03d82-41fb-4cc3-bcd0-65b1111e58fe"     frameborder="0"     width="800"     height="600"     allowtransparency ></iframe> */}
 
            
          </Grid>

        </Grid>
      </Grid>
      
    </Box>
  );
};

export default Dashboard;
