import { Button, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, Paper, Dialog, DialogContent, DialogActions, DialogTitle, CircularProgress, Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import axiosInstance from '../utils/AxiosInstance';

const EventsAdd = () => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [events, setEvents] = useState([]);
    const [error, setError] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [handleDialogOpen, setHandleDialogOpen] = useState(false);
    const [handleDelete, setHandleDelete] = useState(null)
    const [dataLoad, setDataLoad] = useState(false)



    // console.log("img", selectedImage);

    // Fetch events on component mount
    useEffect(() => {
        axiosInstance.get('/events/list')
            .then(response => {
                setEvents(response.data);
            })
            .catch(err => {
                setError(err.message);
            });
    }, []);

    // Handle form submit
    const handleSubmit = async (e) => {
        e.preventDefault();
       
        if (!title || !description || !selectedImage) {
            alert("All fields are required");
            return;
        }
        setDataLoad(true)
        try {
            // Upload banner image
            const formDataImage = new FormData();
            formDataImage.append('banner', selectedImage);

            const imageResponse = await axiosInstance.post('/upload/event-banner', formDataImage);
            const imageUrl = imageResponse.data.fileName;
            // console.log("url", imageUrl);

            const eventData = {
                event_name: title,
                description,
                image_url: imageUrl,
            };

            const eventResponse = await axiosInstance.post('/events/create', eventData);
            // console.log('Event created successfully', eventResponse.data.event);

            if (eventResponse.data) {
                setEvents([...events, eventResponse.data.event]);
                setDialogOpen(false);
                setSelectedImage(null);
                setTitle('');
                setDescription('');
            }

        } catch (error) {
            if (error.response.status === 401) {
                window.location.href = '/auth/login';
              }      
            else if (error.response && error.response.data) {
                alert(error.response.data.message);
            } else {
                console.error('Error creating the event:', error);
            }
        } finally {
            setDataLoad(false)
        }
    };
    const handleOpenDialog = (id) => {
        setHandleDelete(id)
        setHandleDialogOpen(true)
    }

    const handleCloseDeleteDialog = () => {
        setHandleDialogOpen(false)
    }

    const handleDeleteClick = async (eventId) => {
        setDataLoad(true)
        try {
            await axiosInstance.delete(`/events/delete/${eventId}`);

            setEvents(events.filter(event => event.id !== eventId));

            // console.log('Event deleted successfully');
        } catch (error) {
            if (error.response.status === 401) {
                window.location.href = '/auth/login';
              }      
            console.error('Error deleting event:', error);
            alert('Failed to delete event');
        } finally {
            setHandleDialogOpen(false)
            setDataLoad(false)
        }

    };


    // Handle file change
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedImage(file);
        }
    };

    // Handle dialog open
    const handleEventOpen = () => {
        setDialogOpen(true);
    };

    // Handle dialog close
    const handleDialogClose = () => {
        setDialogOpen(false);
        setTitle("");
        setDescription("");
        setSelectedImage(null);
    };

    const handleDeleteImage = () => {
        setSelectedImage(null); // Remove the selected image from the state
        // console.log('Image has been deleted.');
    };

    return (
        <Grid container direction="column" spacing={2} sx={{ padding: 2 }}>
            <Grid item container justifyContent="space-between">
                <Typography variant='h3' fontWeight="bold">Events</Typography>
                <Button onClick={handleEventOpen} variant='contained' size='medium'>Add Event</Button>
            </Grid>

            {events.length > 0 && (
                <Grid item xs={12} mb="30px">
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow sx={{ backgroundColor: "#1976d2" }}>
                                    <TableCell  sx={{ color: "#fff" }}>S.No</TableCell>
                                    <TableCell  sx={{ color: "#fff" }}>Title</TableCell>
                                    <TableCell  sx={{ color: "#fff" }}>Description</TableCell>
                                    <TableCell  sx={{ color: "#fff" }}>Image</TableCell>
                                    <TableCell  sx={{ color: "#fff" }}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {events.reverse().map((event, index) => (
                                    <TableRow key={event.id}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{event.event_name}</TableCell>
                                        <TableCell>{event.description}</TableCell>
                                        <TableCell>
                                            <img
                                                src={`${process.env.REACT_APP_EVENT_BANNER_URL}${event.image_url}`}
                                                alt="Event Banner"
                                                style={{ width: '100px', height: '100px' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                color="error"
                                                onClick={() => handleOpenDialog(event.id)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            )}

            {/* Add Event Dialog */}
            <Dialog open={dialogOpen} onClose={handleDialogClose} maxWidth="md" fullWidth>
                <DialogContent>
                    <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
                        <Typography variant='h5' fontWeight="bold" textAlign="center" p="20px">Add Event</Typography>

                        <Grid item container spacing={2} justifyContent="center" alignItems="center">
                            <TextField
                                label="Title"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                sx={{ width: '80%', mb: "20px" }}
                            />
                            <TextField
                                label="Description"
                                multiline
                                rows={4}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                sx={{ width: '80%' }}
                            />
                        </Grid>

                        {selectedImage && (
                            <Grid item style={{ position: 'relative' }}>
                                <img
                                    src={URL.createObjectURL(selectedImage)}
                                    alt="Selected Preview"
                                    style={{ width: '150px', height: '150px', marginLeft: '10px' }}
                                />
                                <IconButton
                                    onClick={handleDeleteImage}
                                    aria-label="delete"
                                    color="error"
                                    style={{ position: 'absolute', top: 0, right: 0 }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Grid>
                        )}

                        <Grid item>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                                id="upload-image"
                            />
                            <label htmlFor="upload-image">
                                <IconButton disableRipple color="primary" component="span">
                                    <CloudUploadIcon sx={{ fontSize: 40 }} />
                                    <Typography pl="2px">Upload Image</Typography>
                                </IconButton>
                            </label>
                        </Grid>


                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid item>
                        <Button color="primary" onClick={handleSubmit}>
                            {dataLoad ? <Box width={80}><CircularProgress size={15} sx={{ color: "secondary" }} /></Box> : 'Submit'}
                        </Button>
                    </Grid>
                    <Button onClick={handleDialogClose} color="secondary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={handleDialogOpen}
                onClose={handleCloseDeleteDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure you want to delete this Event?"}
                </DialogTitle>
                <DialogActions>
                    <Button color="primary"
                        onClick={handleCloseDeleteDialog}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => handleDeleteClick(handleDelete)}
                        color="secondary"
                        autoFocus
                    >
                        {dataLoad ? <Box><CircularProgress size={15} sx={{ color: "secondary" }} /></Box> : "Delete"}
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};

export default EventsAdd;
