import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@emotion/react';
import theme from "../ui/Theme";
import Box from '@mui/material/Box';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Button, Typography } from '@mui/material';
import ArrowForward from '@mui/icons-material/ArrowForwardIos';
import ArrowBack from '@mui/icons-material/ArrowBackIos';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../utils/AxiosInstance';

// Custom Next Arrow
function NextArrow(props) {
  const { onClick } = props;
  return (
    <Button
      onClick={onClick}
      disableRipple
      sx={{
        display: 'block',
        background: 'none',
        color: '#000000a5',
        position: 'absolute',
        top: '40%',
        right: '150px',
        padding: "0px",
        transform: 'translateY(-50%)',
        zIndex: 1,
        '&:hover': {
          background: 'none',
          color: "#000000e9"
        },
        '&:active': {
          background: 'none',
        },
        '&:focus': {
          outline: 'none',
        },
      }}
    >
      <ArrowForward fontSize='large' />
    </Button>
  );
}

// Custom Previous Arrow
function PrevArrow(props) {
  const { onClick } = props;
  return (
    <Button
      onClick={onClick}
      disableRipple
      sx={{
        display: 'block',
        background: 'none',
        color: '#000000a5',
        position: 'absolute',
        top: '40%',
        left: '150px',
        padding: "0px",
        transform: 'translateY(-50%)',
        zIndex: 1,
        '&:hover': {
          background: 'none',
          color: "#000000e9"
        },
        '&:active': {
          background: 'none',
        },
        '&:focus': {
          outline: 'none',
        },
      }}
    >
      <ArrowBack fontSize='large' />
    </Button>
  );
}

function Carousel() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const { t } = useTranslation("home");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [events, setEvents] = useState([]);

  useEffect(() => {    
    axiosInstance.get('/events/list')
      .then(response => {
        setEvents(response.data);
        setLoading(false);
      })
      .catch(err => {
        setError(err);
        setLoading(false);
      });
  }, []);

  const settings = {
    fade: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
    autoplaySpeed: 2000,
    nextArrow: <NextArrow />, // Add next arrow
    prevArrow: <PrevArrow />  // Add previous arrow
  };

  return (
    <ThemeProvider theme={theme}>
      {events.length > 0 && (
        <>
          <Typography fontWeight="bold" variant='h4' mt="30px" mb="30px" fontFamily= 'MyTamilFontHeading' sx={{ textAlign: 'center', paddingTop: "30px" }}>
            {t("headtitle.Events")}
          </Typography>

          <Slider {...settings}>
            {events.map(event => (
              <Box key={event.id} sx={{ display: 'flex', justifyContent: "center" }}>
                <Box
                  component="img"
                  src={`${process.env.REACT_APP_EVENT_BANNER_URL}${event.image_url}`}
                  sx={{
                    height: { xs: "100px", sm: "300px", md: "500px" },
                    width: { xs: "80%", sm: "80%", md: "70%" },
                    borderRadius: '20px',
                    display: 'block',
                    mx: 'auto'
                  }}
                />
                <Box sx={{ textAlign: "center", paddingTop: { xs: '20px', md: "50px" } }}>
                  <Typography variant='h5' fontSize='30px'
                    sx={{
                      fontWeight: "bold",
                      fontFamily: 'TAMUni-Tamil046',
                      marginBottom: '100px',
                      fontSize: { xs: '20px', sm: '25px', md: '30px' },
                    }}>
                    “ {event.description} ”
                  </Typography>
                </Box>
              </Box>
            ))}
          </Slider>
        </>
      )}
    </ThemeProvider>
  );
}

export default Carousel;
