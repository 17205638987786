import React, { useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import bird from '../../assets/bird.png';
import tiger from '../../assets/tiger.png'
import lion from '../../assets/lion.png'
import lionking from '../../assets/lionking.png'
import elephant from '../../assets/elephant.png'
import {  useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CategoriesList = () => {
  const navigate  = useNavigate();
  const [showLevel1, setShowLevel1] = useState(false);
  const[level1,setLevel1] = useState(null);

  const handleClick = (path, level) => {
    navigate(path, { state: { level } });
  };
const {t} = useTranslation("nav")

  return (
    <Box id="category">
      <Grid container justifyContent="center">
        <Grid item container xs={12} sm={6} md={2.4} justifyContent="center">
          <Box
          onClick={() => handleClick('/books', '1')}
          // onClick={() => handleClickLevel1(level1)}
            sx={{
              borderRadius: '20px',
              height: '270px',
              width:"220px",
              background: 'linear-gradient(180deg, #B95927 0%, #466170 100%);',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'absolute',
              cursor:"pointer",
              '&:hover img': {
                transform: 'scale(1.2)',
                paddingBottom:"50px",
                marginBottom:"100px",
                position:"relative",
                width:"330px",
                height: '250px',
                // boxShadow:"8px 8px 15px rgba(0, 0, 0, 0.5)"
              },
              '&:hover .hover-text': {
                fontSize:"2rem",
                color:"#ff371c",
                transition: 'font-size 0.3s ease-in-out, color 0.3s ease-in-out'
              },
            }}
          >
            <Box
              component="img"
              src={bird}
              sx={{
                height: '230px',
                width: '310px',
                position:"relative",
                marginRight:"10px"
              }}
            />
          </Box>
          <Typography
            variant="body1"
            className="hover-text"
            fontFamily='MyTamilFontLevel'
            sx={{
              transition: 'font-size 0.3s ease-in-out ,color 0.3s ease-in-out',
              marginTop:"270px",
              fontSize:"2rem",
              
            }}
          >
            {t("subtitle.arumbu")}
          </Typography>
        </Grid>

        <Grid item container xs={12} sm={6} md={2.4} justifyContent="center">
          <Box onClick={() => handleClick('/books', '2')}
            sx={{
              borderRadius: '20px',
              height: '270px',
              width:"220px",
              background: 'linear-gradient(180deg, #F99C52 0%, rgba(189, 118, 63, 1) 50%, #090506 100%)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor:"pointer",
              position: 'absolute',
              '&:hover img': {
                transform: 'scale(1.2)',
                paddingBottom:"50px",
                marginBottom:"100px",
                position:"relative",
                height: '260px',
                width: '210px',
                
              },
            }}
          >
            <Box
              component="img"
              src={tiger}
              sx={{
                height: '245px',
                width: '190px',
                position:"relative",              
              }}
            />
          </Box>
          <Typography
            variant="body1"
            className="hover-text"
            fontFamily='MyTamilFontLevel'
            sx={{
              transition: 'font-size 0.3s ease-in-out',
              marginTop:"270px",
              fontSize:"2rem"
            }}
          >
             {t("subtitle.mottu")}
          </Typography>
        </Grid>

        <Grid item container xs={12} sm={6} md={2.4} justifyContent="center">
          <Box onClick={() => handleClick('/books', '3')}
            sx={{
              borderRadius: '20px',
              height: '270px',
              width:"220px",
              background: 'linear-gradient(180deg, #921710 0%, rgba(252, 252, 225, 1) 50%, #9D623B 100%)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor:"pointer",
              position: 'absolute',
              '&:hover img': {
                transform: 'scale(1.2)',
                paddingBottom:"50px",
                marginBottom:"100px",
                position:"relative",
                height: '260px',
                width: '320px',
                
              },
            }}
          >
            <Box
              component="img"
              src={lionking}
              sx={{
                height: '240px',
                width: '300px',
                position:"relative",            
              }}
            />
          </Box>
          <Typography
            variant="body1"
            className="hover-text"
            fontFamily='MyTamilFontLevel'
            sx={{
              transition: 'font-size 0.3s ease-in-out',
              marginTop:"270px",
              fontSize:"2rem"
            }}
          >
              {t("subtitle.mugai")}
          </Typography>
        </Grid>

        <Grid item container xs={12} sm={6} md={2.4} justifyContent="center">
          <Box onClick={() => handleClick('/books', '4')}
            sx={{
              borderRadius: '20px',
              height: '270px',
              width:"220px",
              cursor:"pointer",
              // background: 'linear-gradient(to bottom right, #ff7e5f, #feb47b)',
              background: 'linear-gradient(180deg, #921710 0%, #9D623B 25%, #D0770F 50%, #A45224 75%, #D1A870 100%)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'absolute',
              '&:hover img': {
                transform: 'scale(1.2)',
                paddingBottom:"50px",
                marginBottom:"100px",
                position:"relative",
                width:"360px,",
                height:"270"
                
              },
            }}
          >
            <Box
              component="img"
              src={lion}
              sx={{
                height: '250px',
                width: '340px',
                position:"relative",
              }}
            />
          </Box>
          <Typography
            variant="body1"
            className="hover-text"
            fontFamily='MyTamilFontLevel'
            sx={{
              transition: 'font-size 0.3s ease-in-out',
              marginTop:"270px",
              fontSize:"2rem"
            }}
          >
              {t("subtitle.malar")}
          </Typography>
        </Grid>

        <Grid item container xs={12} sm={6} md={2.4} justifyContent="center">
          <Box onClick={() => handleClick('/books', '5')}
            sx={{
              borderRadius: '20px',
              height: '270px',
              width:"220px",
              background: 'linear-gradient(180deg, #192A34 0%, #614569 50%, #533429 100%)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor:"pointer",
              position: 'absolute',
              '&:hover img': {
                transform: 'scale(1.2)',
                paddingBottom:"50px",
                marginBottom:"100px",
                position:"relative",
                width:"280px"
                
              },
            }}
          >
            <Box
              component="img"
              src={elephant}
              sx={{
                height: '330px',
                width: '280px',
                position:"relative",
                pb:"20px"
              }}
            />
          </Box>
          <Typography
            variant="body1"
            className="hover-text"
            fontFamily='MyTamilFontLevel'
            sx={{
              transition: 'font-size 0.3s ease-in-out',
              marginTop:"270px",         
              fontSize:"2rem"
            }}
          >
            {t("subtitle.alar")}
          </Typography>
        </Grid>

      </Grid>      
    </Box>
  );
};

export default CategoriesList;
