import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    RadioGroup,
    FormControlLabel,
    Radio,
    Typography,
    Box,
} from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const CustomToolbar = () => (
    <div id="toolbar">
        <span className="ql-formats">
            <button className="ql-bold" title="Bold"></button>
            <button className="ql-italic" title="Italic"></button>
            <button className="ql-underline" title="Underline"></button>
            <button className="ql-strike" title="Strikethrough"></button>
        </span>
        <span className="ql-formats">
            <button className="ql-list" value="ordered" title="Ordered List"></button>
            <button className="ql-list" value="bullet" title="Bullet List"></button>
        </span>
        <span className="ql-formats">
            <select className="ql-color" title="Text Color"></select>
            <select className="ql-background" title="Background Color"></select>
        </span>
        <span className="ql-formats">
            <button className="ql-clean" title="Remove Formatting"></button>
        </span>
    </div>
);

const EditTextDialog = ({ open, handleCloseDialog, text, handleSaveText }) => {
    const [updatedText, setUpdatedText] = useState("");
    const [textSize, setTextSize] = useState(20);

    // Use effect to update the dialog fields when the text prop changes
    useEffect(() => {
        if (text) {
            setUpdatedText(text.text);
            setTextSize(text.size);
        }
    }, [text]);

    const handleTextSizeChange = (event) => {
        const size = Number(event.target.value);
        setTextSize(size);
    };

    const handleTextChange = (value) => {
        setUpdatedText(value); // No character limit applied here
    };

    const handleSave = () => {
        const updatedTextObject = {
            id: text.id, // Keep track of the text id
            text: updatedText,
            size: textSize,
        };

        handleSaveText(updatedTextObject); // Pass the updated text object to parent
        handleCloseDialog(); // Close the dialog after saving
    };


    return (
        <Dialog open={open} onClose={handleCloseDialog} maxWidth="md" fullWidth
            sx={{
                '& .MuiDialog-paper': {
                    minHeight: '530px',
                    maxHeight: '100vh',
                },
            }}>
            <DialogTitle sx={{ backgroundColor: 'white' }}>Edit Text</DialogTitle>
            <DialogContent sx={{ backgroundColor: 'white' }}>
                <Typography variant="subtitle1" sx={{ marginTop: 2 }}>
                    Text Size
                </Typography>
                <RadioGroup row value={textSize} onChange={handleTextSizeChange}>
                    <FormControlLabel value={30} control={<Radio />} label="Heading 1 (30px)" />
                    <FormControlLabel value={28} control={<Radio />} label="Heading 2 (28px)" />
                    <FormControlLabel value={20} control={<Radio />} label="Paragraph (20px)" />
                </RadioGroup>

                <Typography variant="subtitle1" sx={{ marginTop: 2 }}>
                    Edit Text
                </Typography>
                <CustomToolbar />
                <ReactQuill
                    value={updatedText}
                    onChange={handleTextChange}
                    theme="snow"
                    modules={{
                        toolbar: {
                            container: "#toolbar", 
                        },
                    }}
                    formats={['bold', 'italic', 'underline', 'strike', 'list', 'color', 'background', 'align']}
                    style={{
                        height: '200px',
                        marginBottom: '20px',
                        fontSize: `${textSize}px`,
                    }}
                />
            </DialogContent>
            <DialogActions sx={{ display: 'flex', justifyContent: 'space-around', backgroundColor: "white" }}>
                <Button onClick={handleCloseDialog} sx={{ backgroundColor: 'red', color: 'white', '&:hover': { backgroundColor: 'darkred' }, }}>Cancel</Button>
                <Button onClick={handleSave} sx={{ backgroundColor: 'green', color: 'white', '&:hover': { backgroundColor: 'darkgreen' }, }} color="primary">Update</Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditTextDialog;
