import React, { useState, useEffect, useContext } from "react";
import {
  Container as MuiContainer,
  Box,
  Avatar,
  Typography,
  Button,
  Tabs,
  Tab,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Modal,
  TextField,
  TablePagination,
  Grid,
  InputAdornment,
  Pagination,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import { Add, Remove, Delete } from "@mui/icons-material";
import { styled } from "@mui/system";
import { useLocation } from "react-router-dom";
import BookIcon from "@mui/icons-material/Book";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import EditIcon from "@mui/icons-material/Edit";
import axiosInstance from "../utils/AxiosInstance";
import VerifyOtpPopup from "../user/VerifyOtpPopup";
import { useNavigate } from "react-router-dom";
import BookCard from "../common/BookCard";
import BookCardTwo from "../common/BookCardTwo";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../hooks/context/AuthContext";
import FloatingDonateButton from "../donate/FloatingDonateButton";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { Link } from "react-router-dom";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const ProfileContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: "#fff",
  textAlign: "center",
}));

const StyledContainer = styled(Paper)(({ theme }) => ({
  backgroundColor: "#000",
}));

const Header = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: theme.spacing(2),
}));

const AvatarWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  position: "relative", // Ensure this is relative for absolute positioning of icon
  gap: theme.spacing(2),
  "&:hover .edit-icon": {
    opacity: 1,
    visibility: "visible",
  },
}));

const EditIconWrapper = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "25%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "rgba(255, 255, 255, 0.7)",
  opacity: 0,
  visibility: "hidden",
  marginRight: "10px",
  transition: "opacity 0.3s, visibility 0.3s",
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.9)",
  },
}));

const TabsContainer = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(2),
  backgroundColor: "#e6f5fc",
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const EditProfileModal = ({
  open,
  handleClose,
  profileImage,
  setProfileImage,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
}) => {
  // const [email, setEmail] = useState('');

  const [showOtpPopup, setShowOtpPopup] = useState(false);
  const [errors, setErrors] = useState({ image: "" });
  const [initialFirstName, setInitialFirstName] = useState(firstName);
  const [initialLastName, setInitialLastName] = useState(lastName);
  const [tempFirstName, setTempFirstName] = useState(firstName);
  const [tempLastName, setTempLastName] = useState(lastName);

  useEffect(() => {
    // Set initial values when the modal opens
    setTempFirstName(firstName);
    setTempLastName(lastName);
  }, [open]);

  const { isAuthenticated, user, loading } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleProfilePicChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      // Save the file name to state
      setProfileImage(file);
    }
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleEditEmail = () => {
    setShowOtpPopup(true); // Show the OTP popup when the edit icon is clicked
  };

  const handleCloseOtpPopup = () => {
    setShowOtpPopup(false); // Close the OTP popup
  };
  const userId = user?.id;
  const handleSaveProfile = async () => {
    setFirstName(tempFirstName); // Update the main state with temp values
    setLastName(tempLastName);
    try {
      let isProfileUpdated = false;
      let isProfileImageUpdated = false;

      if (firstName !== tempFirstName || lastName !== tempLastName) {
        const response1 = await axiosInstance.put(
          `/users/${userId}/UpdateUser`,
          {
            first_name: tempFirstName,
            last_name: tempLastName,
            email: email,
          }
        );

        if (response1.status === 200) {
          isProfileUpdated = true;
          handleClose();
        } else {
          console.error("Failed to update profile:", response1.data.message);
        }
      }
      if (profileImage) {
        const formDataImage = new FormData();
        formDataImage.append("profile", profileImage);

        // Upload the image
        const imageResponse = await axiosInstance.post(
          `/upload/profile`,
          formDataImage
        );

        const imageUrl = imageResponse.data.fileName; // Adjust based on your server response
        const response2 = await axiosInstance.put(
          `/users/${userId}/userprofile`,
          {
            profile_picture: imageUrl,
          }
        );

        if (response2.status === 200) {
          isProfileImageUpdated = true;
          handleClose();
        } else {
          console.error(
            "Failed to update profile picture:",
            response2.data.message
          );
        }
      }
      if (isProfileUpdated || isProfileImageUpdated) {
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const { t } = useTranslation("home");

  return (
    <>
      <Modal open={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            border: "none",
            borderRadius: "10px",
            p: 4,
            backgroundColor: "#e6f5fc",
          }}
        >
          <h2>{t("editpop.editPro")}</h2>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box sx={{ position: "relative", display: "inline-block" }}>
              <Avatar
                src={`https://story-buzz.blr1.digitaloceanspaces.com/uploads/user/profile_pic/${profileImage}`}
                // alt="Profile Picture"
                sx={{ width: 100, height: 100, mb: 2 }}
              />
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="profile-pic-upload"
                name="profile_picture"
                type="file"
                onChange={handleProfilePicChange}
              />
              <label htmlFor="profile-pic-upload">
                <IconButton
                  component="span"
                  sx={{
                    position: "absolute",
                    bottom: 10,
                    right: 0,
                    bgcolor: "rgba(0, 0, 0, 0.6)",
                    color: "white",
                    "&:hover": {
                      bgcolor: "rgba(0, 0, 0, 0.5)",
                    },
                  }}
                >
                  <EditIcon />
                </IconButton>
              </label>
            </Box>
            <TextField
              label={t("editpop.firstname")}
              variant="outlined"
              fullWidth
              margin="normal"
              value={tempFirstName}
              onChange={(e) => setTempFirstName(e.target.value)}
            />
            <TextField
              label={t("editpop.lastname")}
              variant="outlined"
              fullWidth
              margin="normal"
              value={tempLastName}
              onChange={(e) => setTempLastName(e.target.value)}
            />
            <TextField
              label={t("editpop.email")}
              variant="outlined"
              fullWidth
              margin="normal"
              value={email}
              onChange={handleEmailChange}
              disabled
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleEditEmail}>
                      <EditIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                mt: 2,
              }}
            >
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClose}
              >
                {t("editpop.cancel")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSaveProfile}
                disabled={!tempFirstName || !tempLastName}
              >
                {t("editpop.save")}
              </Button>
            </Box>
          </Box>
          {showOtpPopup && (
            <VerifyOtpPopup userId={userId} onClose={handleCloseOtpPopup} />
          )}
        </Box>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Profile sent to admin successfully!
        </Alert>
      </Snackbar>
    </>
  );
};

const User = () => {
  const [value, setValue] = useState(0);
  const [sortOrder, setSortOrder] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [read, setRead] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [books, setBooks] = useState([]);
  const [paginatedBooks, setPaginatedBooks] = useState([]);
  const bookOrder=paginatedBooks
  const [favoriteBooks, setFavoriteBooks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const [activeBooks, setActiveBooks] = useState([]);
  const [bookCounts, setBookCounts] = useState({});
  const [totalPrice, setTotalPrice] = useState(0);
  const [shippingCharge, setShippingCharge] = useState(0);
  const [taxAmount, setTaxAmount] = useState(0);
  const [totalPriceWithTax, setTotalPriceWithTax] = useState(0);
  const [discount, setDiscount] = useState(0);

  const [paymentMethod, setPaymentMethod] = useState('paypal');
  const [payoutRecipient, setPayoutRecipient] = useState('');

  const [openPopup, setOpenPopup] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [address, setAddress] = useState({
    fullname: "",
    mobile: "",
    emailid: "",
    pincode: "",
    locality: "",
    city: "",
    state: "",
    address: "",
    landmark: "",
    alternatePhone: "",
    addressType: "home",
  });

  const steps = ["Enter Your Address", "Order Summary", "Payment Option"];

  const handleNext = () => setActiveStep((prevStep) => prevStep + 1);
  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);
  const handleReset = () => setActiveStep(0);

  const { isAuthenticated, user } = useContext(AuthContext);
  const navigate = useNavigate();

  const { t } = useTranslation("home");

  const [favoritePage, setFavoritePage] = useState(1); // Pagination state for favorite books
  const [booksPerPage] = useState(8); // Number of books per page

  const location = useLocation();
  // Placeholder for states and cities. Ideally, fetch these from an API or a static JSON.
  const usStates = [
    { name: "California", abbreviation: "CA" },
    { name: "New York", abbreviation: "NY" },
    // Add more states...
  ];

  const citiesByState = {
    CA: ["Los Angeles", "San Francisco", "San Diego"],
    NY: ["New York City", "Buffalo", "Rochester"],
    // Add more cities...
  };
  const [cities, setCities] = useState([]);

  // Handle changes in the form inputs
  const handleChangesadrs = (e) => {
    const { name, value } = e.target;
    setAddress({
      ...address,
      [name]: value,
    });

    if (name === "state") {
      // When the state is selected, update cities dropdown
      const selectedStateAbbr = usStates.find((state) => state.name === value)?.abbreviation;
      setCities(citiesByState[selectedStateAbbr] || []);
      setAddress({ ...address, city: "" }); // Clear city when state changes
    }
  };

  // Submit the form
  const handleSubmit = async () => {
    // Send the formData to your backend for processing
    try {
      const response = await fetch("/api/save-address", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(address),
      });

      if (response.ok) {
        alert("Address saved successfully!");
      } else {
        alert("Failed to save the address.");
      }
    } catch (error) {
      console.error("Error saving address:", error);
    }
  };



  // const [bookCounts, setBookCounts] = useState(
  //   activeBooks.reduce((acc, book) => {
  //     acc[book.book_id] = book.count !== undefined ? book.count : 1; // If book.count exists, use it, otherwise default to 1
  //     return acc;
  //   }, {})
  // );

  // const [value, setValue] = useState(0);

  useEffect(() => {
    if (location.state && location.state.selectedTab !== undefined) {
      setValue(location.state.selectedTab);
    }
  }, [location.state]);

  const handleBookClick = (book) => {
    navigate(`/book/${book.book_id}`);
  };

  const userId = user?.id;
  // Fetch user profile data from the server
  const [bookCount, setBookCount] = useState(0);
  useEffect(() => {
    const fetchBookCount = async () => {
      try {
        const response = await axiosInstance.get(
          `/users/${userId}/published-count`
        );
        setBookCount(response.data.count);
      } catch (error) {
        console.error("Error fetching book count:", error);
      }
    };

    fetchBookCount();
  }, [userId]);

  const handleCancel = () => {
    navigate("/");
    setValue("0");
    localStorage.setItem("TabValue", 0);
  };

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/users/${userId}/profile`
        ); // Replace with dynamic userId if needed
        const data = await response.json();

        // alert("-------",respon

        if (response.ok) {
          setProfileImage(data.profile_picture || "");
          setFirstName(data.first_name || "");
          setLastName(data.last_name || "");
          setEmail(data.email || "");
          setRead(data.views_count || "");
        } else {
          console.error("Failed to fetch user profile:", data.message);
        }
      } catch (err) {
        console.error("Error fetching user profile:", err);
      }
    };

    fetchUserProfile();
  }, []);
  const fetchUserBooks = async () => {
    try {
      const response = await axiosInstance.get(`/users/${userId}/user-books`);
      const sortedBooks = response.data.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      ); // Sort by created date
      setBooks(sortedBooks);
      setPaginatedBooks(sortedBooks.slice(0, rowsPerPage));
    } catch (error) {
      console.error("Error fetching books:", error);
    }
  };

  useEffect(() => {
    fetchUserBooks();
  }, []);
  useEffect(() => {
    const myFavoriteBooks = async () => {
      try {
        const response = await axiosInstance.get(`/users/${userId}/favourites`);
        setFavoriteBooks(response.data.favouriteBooks);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };

    myFavoriteBooks();
  }, []);

  useEffect(() => {
    const fetchActiveBooks = async () => {
      try {
        const response = await axiosInstance.get(
          `/purchase/getActiveCartBooks/${userId}`
        );
        // Set initial book counts and calculate the total price
        const cartData = response.data.books;
        const counts = {};
        let total = 0;
        cartData.forEach((item) => {
          counts[item.id] = item.quantity;
          total += item.price * item.quantity;
        });

        setBookCounts(counts);
        setTotalPrice(response.data.totalPrice);
        setActiveBooks(cartData); // Set the fetched books in state
        setShippingCharge(response.data.shippingCharge);
        setTaxAmount(response.data.taxAmount);    // Set the tax amount
        setTotalPriceWithTax(response.data.finalTotal);  // Total price after tax
        setDiscount(response.data.discount);

      } catch (error) {
        console.error("Error fetching active books:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchActiveBooks();
  }, [userId]);
  console.log("----------------activeBooks---", activeBooks);
  // if (activeBooks.length === 0) {
  //   return <p>No active books in your cart.</p>;
  // }

  if (loading) return <div>Loading...</div>;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const handleSortChange = (event) => {
  //   setSortOrder(event.target.value);
  // };

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
    const sortedBooks = [...books].sort((a, b) => {
      if (event.target.value === "Draft") {
        return a.status === "Draft" ? -1 : 1;
      } else if (event.target.value === "Published") {
        return a.status === "Published" ? -1 : 1;
      } else if (event.target.value === "Waiting for Approval") {
        return a.status === "Waiting for Approval" ? -1 : 1;
      } else {
        return 0;
      }
    });
    setBooks(sortedBooks);
    setPaginatedBooks(sortedBooks.slice(0, rowsPerPage));
  };

  const handleEdit = (book) => {
    // console.log("ggg",book)
    navigate("/create/book", { state: { book: book } });
  };

  const getStatusStyle = (status) => {
    switch (status) {
      case "Draft":
        return { color: "gray" };
      case "Published":
        return { color: "green" };
      case "Waiting for Approval":
        return { color: "orange" };
      default:
        return {};
    }
  };

  const filteredBooks = sortOrder
    ? books.filter((book) => book.status === sortOrder)
    : books;

  // Pagination handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    const startIndex = newPage * rowsPerPage;
    setPaginatedBooks(books.slice(startIndex, startIndex + rowsPerPage));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setPaginatedBooks(books.slice(0, event.target.value));
  };

  const handleFavoritePageChange = (event, value) => {
    setFavoritePage(value); // Update the page number when pagination is changed
  };

  const indexOfLastBook = favoritePage * booksPerPage;
  const indexOfFirstBook = indexOfLastBook - booksPerPage;
  const currentFavoriteBooks = favoriteBooks.slice(
    indexOfFirstBook,
    indexOfLastBook
  );

  // Function to increase book count
  // const handleIncrement = (bookId) => {
  //   setBookCounts((prevCounts) => ({
  //     ...prevCounts,
  //     [bookId]: (prevCounts[bookId] || 1) + 1, // Ensure to always increment based on the current count or 1
  //   }));
  // };
  // Function to handle incrementing the book count
  // const handleIncrement = (bookId, price) => {
  //   setBookCounts((prevCounts) => {
  //     const updatedCounts = {
  //       ...prevCounts,
  //       [bookId]: (prevCounts[bookId] || 1) + 1,
  //     };
  //     calculateTotal(updatedCounts);
  //     return updatedCounts;
  //   });
  // };


  // const handleIncrement = async (bookId) => {
  //   setBookCounts((prevCounts) => {
  //     const updatedCounts = {
  //       ...prevCounts,
  //       [bookId]: (prevCounts[bookId] || 1) + 1,
  //     };
  //     // Send request to backend to update the cart and get new total price
  //     updateCartInBackend(bookId, updatedCounts[bookId]);
  //     return updatedCounts;
  //   });
  // };
  // Function to increment quantity
  const handleIncrement = async (bookId) => {
    setBookCounts((prevCounts) => {
      const newCount = (prevCounts[bookId] || 1) + 1;  // Increment count
      updateCartInBackend(bookId, newCount);  // Send updated count to backend
      return {
        ...prevCounts,
        [bookId]: newCount,  // Update state immediately with new count
      };
    });
  };
  // Function to handle decrementing the book count
  // const handleDecrement = (bookId, price) => {
  //   setBookCounts((prevCounts) => {
  //     const updatedCounts = {
  //       ...prevCounts,
  //       [bookId]: Math.max((prevCounts[bookId] || 1) - 1, 1), // Prevent count going below 1
  //     };
  //     calculateTotal(updatedCounts);
  //     return updatedCounts;
  //   });
  // };

  // const handleDecrement = async (bookId) => {
  //   setBookCounts((prevCounts) => {
  //     const updatedCounts = {
  //       ...prevCounts,
  //       [bookId]: Math.max((prevCounts[bookId] || 1) - 1, 1),
  //     };
  //     // Send request to backend to update the cart and get new total price
  //     updateCartInBackend(bookId, updatedCounts[bookId]);
  //     return updatedCounts;
  //   });
  // };


  // Function to decrement quantity
  const handleDecrement = async (bookId) => {
    setBookCounts((prevCounts) => {
      const newCount = Math.max((prevCounts[bookId] || 1) - 1, 0); // Prevent going below 0
      if (newCount >= 1) {
        updateCartInBackend(bookId, newCount);  // Update backend only if count >= 1
      }
      return {
        ...prevCounts,
        [bookId]: newCount,  // Update state immediately with new count
      };
    });
  };


  // Function to decrease book count
  // const handleDecrement = (bookId) => {
  //   setBookCounts((prevCounts) => ({
  //     ...prevCounts,
  //     [bookId]: prevCounts[bookId] > 1 ? prevCounts[bookId] - 1 : 1, // Ensure count doesn't go below 1
  //   }));
  // };
  // Function to send the updated book count to the backend
  const updateCartInBackend = async (bookId, newQuantity) => {
    try {
      const response = await axiosInstance.post('/purchase/updateCartQuantity', {
        userId: userId,  // Replace with actual userId
        bookId,
        newQuantity,
      });

      if (response.status === 200) {
        setTotalPrice(response.data.newTotalPrice); // Update total price from backend
      }
    } catch (error) {
      console.error('Error updating cart:', error);
    }
  };
  // Function to calculate total price
  // const calculateTotal = (updatedCounts) => {
  //   const total = activeBooks.reduce((sum, book) => {
  //     const count = updatedCounts[book.id] || 1;
  //     return sum + count * book.price;
  //   }, 0);
  //   setTotalPrice(total);
  // };

  // Example useEffect to calculate total on mount or book changes
  // useEffect(() => {
  //   calculateTotal(bookCounts);
  // }, [activeBooks]);

  // Function to remove the book
  // const handleDelete = (bookId) => {
  //   setFavoriteBooks(favoriteBooks.filter((book) => book.book_id !== bookId));
  //   setBookCounts((prevCounts) => {
  //     const updatedCounts = { ...prevCounts };
  //     delete updatedCounts[bookId]; // Also remove from bookCounts state
  //     return updatedCounts;
  //   });
  // };
  const handleDelete = async (bookId) => {
    alert("-----", bookId);
    try {
      // Call the backend to delete the book from the cart
      const response = await axiosInstance.delete(
        `/purchase/deleteFromCart/${userId}/${bookId}`
      );

      if (response.status === 200) {
        // If the delete is successful, update the UI
        console.log("Book removed from cart");
        const disabledBooks = JSON.parse(localStorage.getItem('disabledBooks')) || {};
        delete disabledBooks[bookId];
        localStorage.setItem('disabledBooks', JSON.stringify(disabledBooks));
        setFavoriteBooks(
          favoriteBooks.filter((book) => book.book_id !== bookId)
        );
        setBookCounts((prevCounts) => {
          const updatedCounts = { ...prevCounts };
          delete updatedCounts[bookId]; // Remove from bookCounts state as well
          return updatedCounts;
        });

      }
    } catch (error) {
      console.error("Error removing book from cart:", error);
    }
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
    setActiveStep(0); // Reset the stepper on close
  };


  const handlePlaceOrder = async () => {
    // Create order details with the correct book_id
    const orderDetails = {
      userId,
      books: activeBooks.map((book) => ({
        book_id: book.id,  // Ensure book_id is coming from the activeBooks
        count: bookCounts[book.id] || 1,
      })),
      totalPriceWithTax,
      shippingCharge,
      taxAmount,
    };

    console.log("Order Details:", orderDetails);  // Debug log

    try {
      // Send a request to save order details
      const response = await axiosInstance.post(
        "/purchase/orders",
        orderDetails
      );

      if (response.status === 200) {
        // Order saved successfully, now open the popup
        setOpenPopup(true);
      } else {
        console.log("Order could not be placed.");
      }
    } catch (error) {
      console.error("Error placing order:", error);
    }
  };

  const handleApprove = async (data, actions) => {
    try {
      // Capture the order
      const details = await actions.order.capture();
      console.log("Transaction completed by ", details.payer.name.given_name);

      // Call your backend to save the transaction
      const res = await axiosInstance.post('/payment/paypalpurchase', {
        userId,
        amount: totalPriceWithTax,
        transactionDetails: details,
      });

      setSuccess(true);
      console.log("Payment Success:", res.data);
    } catch (err) {
      console.error("Error capturing payment:", err);
      setError("Payment could not be processed");
    }
  };

  const handleError = (err) => {
    console.error("Error with PayPal:", err);
    setError("Payment could not be processed");
  };


  const handlePaymentSelection = (e) => {
    setPaymentMethod(e.target.value);
  };

  const handlePayout = async () => {
    if (!payoutRecipient || !totalPriceWithTax) {
      alert('Please enter recipient and amount');
      return;
    }

    try {
      const res = await axiosInstance.post('/payment/payouts', {
        recipient_type: paymentMethod === 'paypal' ? 'EMAIL' : 'PHONE',
        recipient: payoutRecipient,
        amount: totalPriceWithTax,
      });
      alert(res.data.message);
    } catch (error) {
      console.error('Error processing payout:', error);
      alert('Payout failed');
    }
  };



  // Paginate the books
  // const paginatedBooks = filteredBooks.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Box sx={{ padding: "100px 40px 30px" }}>
      <StyledContainer sx={{ border: "none", borderRadius: "20px" }}>
        <ProfileContainer id="profileContainer" sx={{ borderRadius: "20px" }}>
          <Header style={{ padding: "40px" }}>
            <AvatarWrapper>
              <Avatar
                // alt="aser Avatar"
                src={`https://story-buzz.blr1.digitaloceanspaces.com/uploads/user/profile_pic/${profileImage}`}
                sx={{ width: 150, height: 150 }}
              />
              <Box sx={{ textAlign: "left" }}>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", fontSize: 35 }}
                >
                  {firstName} {lastName}
                </Typography>
                <Typography variant="body2" sx={{ fontSize: 18 }}>
                  {email}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: 18, fontWeight: "bold", color: "green" }}
                >
                  {t("user.totalReads")} : {read}
                </Typography>
              </Box>
            </AvatarWrapper>
            <Box>
              <Button
                variant="contained"
                sx={{
                  marginRight: 4,
                  backgroundColor: "#e6f5fc",
                  color: "#24b9d8",
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor: "#e6f5fc",
                    color: "#000",
                  },
                }}
                onClick={handleOpen}
              >
                {t("user.editprofile")}
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#e6f5fc",
                  color: "#24b9d8",
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor: "#e6f5fc",
                    color: "#000",
                  },
                }}
                onClick={() => navigate("/create/book/form")}
              >
                {t("user.createBook")}
              </Button>
            </Box>
          </Header>
          <TabsContainer
            sx={{
              border: "none",
              borderBottom: "none",
              marginBottom: "20px",
              borderRadius: "20px",
            }}
          >
            <Tabs value={value} onChange={handleChange} centered>
              <Tab
                label={t("user.myBookShelf")}
                sx={{ color: "#24b9d8", fontWeight: "bold" }}
              />

              <Tab
                label={t("user.favourite")}
                sx={{ color: "#24b9d8", fontWeight: "bold" }}
              />

            </Tabs>
            <TabPanel value={value} index={0}>
              <Typography
                variant="body2"
                sx={{
                  fontSize: 18,
                  marginTop: 2,
                  marginBottom: 2,
                  marginLeft: 2,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <MenuBookIcon sx={{ fontSize: 30, marginRight: "10px" }} />
                  {bookCount} {t("user.book")}
                </div>

                {/* <FormControl sx={{ marginBottom: '20px', minWidth: 200,marginLeft:"1000px"}} variant="outlined" margin="normal">
                <InputLabel>{t("user.sortByStatus")}</InputLabel>
                <Select label={t("user.sortByStatus")} value={sortOrder} onChange={handleSortChange}>
                  <MenuItem >{t("user.none")} </MenuItem>
                  <MenuItem value="Draft">{t("user.draft")}</MenuItem>
                  <MenuItem value="Waiting for Approval">{t("user.approval")}</MenuItem>
                  <MenuItem value="Published">{t("user.published")}</MenuItem>
                </Select>
              </FormControl> */}
              </Typography>
              <TableContainer component={Paper} sx={{ borderRadius: "15px" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ fontWeight: "bold" }}>
                        {t("user.s.no")}
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        {t("user.book")}
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        {t("user.status")}
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        {t("user.action")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedBooks
                      .slice()
                      .sort((a, b) => new Date(b.dateAdded) - new Date(a.dateAdded)) // Sort by dateAdded in descending order
                      .map((book, index) => (
                        <TableRow key={index}>
                          <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                          <TableCell>{book.title}</TableCell>
                          <TableCell style={getStatusStyle(book.status)}>
                            {book.approval_status}
                          </TableCell>
                          <TableCell>
                            {(book.approval_status === "draft" || book.approval_status === "rejected") && (
                              <Button onClick={() => handleEdit(book)}>
                                <EditIcon sx={{ color: "#24B9D8" }} />
                              </Button>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>


              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredBooks.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TabPanel>

            <TabPanel value={value} index={1}>
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                mt={10}
              >
                {favoriteBooks.map((favoriteBook) => (
                  <Grid
                    item
                    container
                    justifyContent="center"
                    key={favoriteBook.book_id}
                    sm={6}
                    md={3}
                    lg={3}
                    xl={2}
                  >
                    <BookCard
                      book={favoriteBook}
                      onBookClick={handleBookClick}
                      userId={userId}
                      setBooks={setFavoriteBooks}
                    />
                  </Grid>
                ))}
              </Grid>
              <Box mt={4} display="flex" justifyContent="center">
                <Pagination
                  count={Math.ceil(favoriteBooks.length / booksPerPage)} // Total number of pages
                  page={favoritePage}
                  onChange={handleFavoritePageChange}
                  variant="outlined"
                  color="primary"
                />
              </Box>
            </TabPanel>


          </TabsContainer>
        </ProfileContainer>
      </StyledContainer>
      <FloatingDonateButton />
      <EditProfileModal
        open={isModalOpen}
        handleClose={handleClose}
        profileImage={profileImage}
        setProfileImage={setProfileImage}
        firstName={firstName}
        setFirstName={setFirstName}
        lastName={lastName}
        setLastName={setLastName}
        email={email}
        setEmail={setEmail}
      //  handleSaveProfile={handleSaveProfile} // Pass the function here
      />
    </Box>
  );
};

export default User;
