import React, { useState, useEffect, useContext } from 'react';
import { IconButton } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import axiosInstance from '../utils/AxiosInstance';
import { AuthContext } from '../../hooks/context/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';

const FavouriteButton = ({ bookId, userId, setBooks }) => {
    const [favourited, setFavourited] = useState(false);
    const { isAuthenticated, user, loading} = useContext(AuthContext);
    const location = useLocation();
    const navigate = useNavigate();
    

    useEffect(() => {
        const fetchFavouritedBooks = async () => {
            try {
                const response = await axiosInstance.get(`/users/${userId}/favourites`);
                setFavourited(response.data.favouriteBooks.some(book => book.book_id === bookId));
            } catch (error) {
                if (error.response.status === 401) {
                    window.location.href = '/auth/login';
                  }      
                console.error('Error fetching favourited books:', error);
            }
        };
        fetchFavouritedBooks();
    }, [userId, bookId]);

    const handleFavourite = async () => {
        if (!isAuthenticated || user?.role !== 'user') {         
            const bookPath = location.pathname + location.search;
            navigate('/auth/login', {state:{bookPath}});
            return;
        }

        try {
            const response = favourited 
                ? await axiosInstance.delete(`/books/${bookId}/favourite`, { data: { user_id: userId } })
                : await axiosInstance.post(`/books/${bookId}/favourite`, { user_id: userId });
            
            setFavourited(!favourited);
            
            setBooks(prevBooks =>
                prevBooks.map(book =>
                    book.book_id === bookId
                        ? { ...book, isFavourited: !favourited }
                        : book
                )
            );
        } catch (error) {
            if (error.response.status === 401) {
                window.location.href = '/auth/login';
              }      
            console.error('Error favouriting/unfavouriting book:', error);
        }
    };

    return (

    <IconButton disableRipple onClick={handleFavourite}>
        <StarIcon  sx={{ color: favourited ? 'gold' : '#54666E' }} fontSize='large'/>
    </IconButton>
    );
};

export default FavouriteButton;
