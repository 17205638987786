import React, { useState } from "react";
import { TextField, Button, Grid,Alert, Typography, Box, Dialog,IconButton, DialogTitle, DialogContent, DialogContentText, DialogActions, InputAdornment, Divider, Chip, Modal, CircularProgress } from "@mui/material";
import axiosInstance from "../utils/AxiosInstance";
import { Link, useNavigate } from "react-router-dom";
import theme from "../ui/Theme";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Checkbox from '@mui/material/Checkbox';
import bgImg from "../../assets/final/Login.jpg"
import GoogleAuth from "./GoogleAuth";
import NotMatch from '@mui/icons-material/HighlightOff';
import Navbar from "../layout/Navbar";
import RegTerms from "./RegTerms";
import PasswordValidator from "./PasswordValidator";
import TermsModal from "./TermsModal";
import { useTranslation } from "react-i18next";
import Snackbar from '@mui/material/Snackbar';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { ThemeProvider } from "@emotion/react";
import Captcha from "./Recaptcha";

function Register() {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [alreadyRegMessage, setAlreadyRegMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [validateErrorMessage, setValidateErrorMessage] = useState('')
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [loggedInmessage, setLoggedInMessage] = useState("");
  const [invalidUserMessage ,setInvalidUserMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [termsOpen, setTermsOpen] = useState(false); 
  const [isTermsChecked, setIsTermsChecked] = useState(false); 
  const [passwordFocused, setPasswordFocused] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [showPassword,setShowPassword] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);
  const [notify, setNotify] = useState('');
  const [load,setLoad] = useState(false)
  const [validations, setValidations] = useState({
    hasUppercase: false,
    hasLowercase: false,
    hasNumber: false,
    hasSpecialChar: false,
    isLongEnough: false,
  });
  const {t} = useTranslation('login')

  const navigate = useNavigate();


//Validations
  const validatePassword = (password) => {
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const isLongEnough = password.length >= 8;


    setValidations({
      hasUppercase,
      hasLowercase,
      hasNumber,
      hasSpecialChar,
      isLongEnough,
    });
  };

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

//Terms&conditions
  const handleTermsCheckboxClick = () => {
    if (!isTermsChecked) {
      setTermsOpen(true);
    } else {
      setIsTermsChecked(false);
    }
  };
  
  const handleFocus =()=>{
    setIsFocused(true)
  }
  const handleBlur = ()=>{
    setIsFocused(false)
  }

  const handleTermsAccept = () => {
    setIsTermsChecked(true);
    setTermsOpen(false);
  };

  const handleTermsClose = () => {
    setTermsOpen(false);
  };

  const handleTermsOpen = () => setTermsOpen(true);

//reg dialog
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

//Password
const handlePasswordChange = (e) => {
  const newPassword = e.target.value;
  setPassword(newPassword);
  validatePassword(newPassword);
  setPasswordMismatch("")
  setValidateErrorMessage("");
};

const handleConfirmPassword = (e) => {
  const value = e.target.value
  setConfirmPassword(value) 
  setPasswordMismatch("")
  //setIsFocused(true)
  if (value){
    setIsFocused(true)
  }
  else{
    setIsFocused(false)
  }
}

const handlePasswordFocus = () => {
  setPasswordFocused(true);
};

const handlePasswordBlur = () => {
  if (!password) {
    setPasswordFocused(false);
  }
};

// Submit
const handleSubmit = async (event) => {
  event.preventDefault();

  if (!captchaToken) {
    setNotify("Please complete the CAPTCHA");
    return;
  }
  setNotify("");
  setLoad(true)

  //validator
  if (!validations.hasUppercase || !validations.hasLowercase || !validations.hasNumber || !validations.hasSpecialChar || !validations.isLongEnough) {
    setValidateErrorMessage("Password does not meet criteria.");
    return;
  }
  setValidateErrorMessage("");

  
  if (password !== confirmPassword) {
    setPasswordMismatch(true);
    return;
  }
  setPasswordMismatch(false);


  try {
    const response = await axiosInstance.post("/auth/register", { firstName, lastName, email, password, captchaToken }, { withCredentials: true });
    if (response.status === 200 && response.data.message === "Registration successful") {
      setSuccessMessage("Registration Success");
      // console.log(response.data);
      setDialogOpen(true);
      // navigate("/login");
    }
  } catch (error) {
    if (error.response && error.response.status === 400 && error.response.data.error === "Email already registered. Please login.") {
      setAlreadyRegMessage("User already registered ");
    } else {
      setErrorMessage("Internal server error. Please try again later.");
      // console.log(`Error: ${error.response ? error.response.data.error : error.message}`);
    }
  }finally{
    setLoad(false)   
  }
};

const handleClose = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setOpen(false);
};

  return (
    <Grid>
    <ThemeProvider theme={theme}>
    <Navbar/>
    <Box display="flex" justifyContent="flex-end" alignItems="center" position="relative" minHeight="100vh" pt={10} pb={5} sx={{justifyContent: {xs:"center", md:"flex-end"}, pr:{md:"150px"}, backgroundImage: `url(${bgImg})`}}>
      <Grid container direction="column" justifyContent="center" alignItems="center" boxShadow={1} borderRadius="50px" width="380px"  p={5} sx={{bgcolor:{xs:"#ffffffff", md:"#ffffff39", }}}>
        <Grid item xs={8} sm={8} md={6} lg={3}>
          <Typography variant="h4" align="center" fontWeight="bold" color="#000000">{t("login.register")}</Typography>

          <form onSubmit={handleSubmit}>
            <TextField required autoFocus label={t("register.firstname")} fullWidth margin="normal" value={firstName} onChange={(e) => setFirstName(e.target.value)} sx={{ '& .MuiOutlinedInput-root': {borderRadius: '10px', height:"50px",}, '& .MuiInputLabel-root': { color: 'black',},}}/>
            <TextField required label={t("register.lastname")} fullWidth margin="normal" value={lastName} onChange={(e) => setLastName(e.target.value)} sx={{ '& .MuiOutlinedInput-root': {borderRadius: '10px', height:"50px",}, '& .MuiInputLabel-root': { color: 'black',},}}/>
            <TextField required label={t("login.email")} type="email" fullWidth margin="normal" value={email} onChange={(e) => setEmail(e.target.value)} sx={{ '& .MuiOutlinedInput-root': {borderRadius: '10px', height:"50px",}, '& .MuiInputLabel-root': { color: 'black',},}}/>
            <TextField required label={t("login.password")} type= {showPassword ?'text':'password'} fullWidth margin="normal" value={password} onFocus={handlePasswordFocus} onBlur={handlePasswordBlur} onChange={handlePasswordChange} sx={{ '& .MuiOutlinedInput-root': {borderRadius: '10px', height:"50px",}, '& .MuiInputLabel-root': { color: 'black',},}}
             InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            />

            {passwordFocused && (
            <PasswordValidator validations={validations} validateErr={validateErrorMessage}/>            
            )}
            
            <TextField required label={t("register.conformePassword")} type="password" fullWidth margin="normal" value={confirmPassword}
              onChange={handleConfirmPassword}              
             // onBlur={handleBlur}
              
              sx={{ '& .MuiOutlinedInput-root': {borderRadius: '10px', height:"50px",}, '& .MuiInputLabel-root': { color: 'black',},}}
              InputProps={{ endAdornment: ( 
                               isFocused &&(             
                <InputAdornment position="end">
                   { password && confirmPassword && password === confirmPassword ? (
                    <CheckCircleIcon color="success" />
                    ) : <NotMatch color='error'/>
                    }
                </InputAdornment>  
                               )
              ),}}/>

            <Typography mt={2}><Checkbox sx={{color:"#000000", '&:hover': { bgcolor: '#00000000' }, '&.Mui-checked': { color: "#000000",}, }} required disableRipple inputProps={{ 'aria-label': 'Checkbox demo' }}/>{t("register.areyouover18yearsold")}?</Typography>
            <Typography><Checkbox checked={isTermsChecked} onClick={handleTermsCheckboxClick} sx={{color:"#000000", '&:hover': { bgcolor: '#ffffff00' }, '&.Mui-checked': { color: "#000000",}}} required disableRipple inputProps={{ 'aria-label': 'Checkbox demo' }}/>{t("register.accept")} <Link to="/terms" sx={{ color: '#2100b6', '&:hover': { color: '#008102' } }} color="blue">{t("register.terms")}</Link></Typography>

            {passwordMismatch && (
                <Typography color="error" mt="10px" mb="10px">Passwords does not match</Typography>
              )}

            {alreadyRegMessage && (
              <Typography color="error" mt="10px" mb="10px">{alreadyRegMessage}<Typography component={Link} color="error" to="/auth/login" sx={{textDecoration:"underline"}}>Please Login.</Typography></Typography>
            )}

            {notify && (
              <Typography color="error" mt="10px" mb="10px">{notify}</Typography>
            )}  

            <Box mt="10px">            
              <Captcha onCaptchaChange={handleCaptchaChange}/>
            </Box>

            <Button type="submit" variant="contained" fullWidth color="primary" sx={{mt:"20px", color: '#ffffff', backgroundColor:"#000000", '&:hover': {backgroundColor: '#000000ff', borderColor: '#000000', },}}>
             {load ? <Box><CircularProgress size={15} sx={{color:"#ffffff"}}/></Box>:t("login.register")}
            </Button>
          </form>
        </Grid>

        <Divider color="black" sx={{width:"100%", margin: "20px 0",}}><Chip label={t("login.or")} size="small" /></Divider>

        <Grid item>
          <GoogleAuth setLoggedInMessage={setLoggedInMessage} setInvalidUserMessage={setInvalidUserMessage} setSnackbarOpen={setOpen} label={t("login.signinwithgoogle")}/>
        </Grid>
      </Grid>

      <Dialog open={dialogOpen} keepMounted onClose={handleDialogClose} aria-describedby="alert-dialog-slide-description">
        <DialogTitle>{successMessage}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description"> Please check your mailbox and verify your email to proceed with login.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button component={Link} to="/auth/login">Ok</Button>
        </DialogActions>
      </Dialog>

      <TermsModal
        termsOpen={termsOpen}
        handleTermsClose={handleTermsClose}
        handleTermsAccept={handleTermsAccept}
      />

    </Box>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        sx={{marginLeft:'1270px'}}
      >
      
        {loggedInmessage && (
          <Alert onClose={handleClose} severity="success">
            {loggedInmessage}
          </Alert>
        )}
      </Snackbar>
    </ThemeProvider>
    </Grid>
  );
}

export default Register;
