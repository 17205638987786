import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Typography, Box, Grid, Divider } from '@mui/material';
import aboutimg from '../../assets/aboutimg.jpg'
import '../CSS/CategoriesList.css'
import profile from '../../assets/sponsor.png'
import img1 from '../../assets/img1.png'
import img2 from '../../assets/img2.png'
import img3 from '../../assets/img3.png'
import img4 from '../../assets/img4.png'
import FloatingDonateButton from '../donate/FloatingDonateButton';



const AboutUs = () => {
  const [isVisible, setIsVisible] = useState(true);
  const intervalRef = useRef(null);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setIsVisible(prev => !prev);
    }, 1000); 

    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

 
  const blinkingStyle = {
    opacity: isVisible ? 1 : 0,
    transition: 'opacity 0.5s ease-in-out',
  };


  return (
    <Box 
    sx={{margin:"100px 40px 80px",}}
    >
    <Box
      sx={{
        position: 'relative',
        height: 'auto',
        width: '100%',
        display: 'flex',
        // justifyContent: 'center',
        // alignItems: 'center',
        borderRadius:'20px',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          //  backgroundImage: `url(${aboutimg})`,
          backgroundColor:'#1ab5f8f6',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          opacity: 0.1,
          zIndex: -1,
        }}
      />
      <Grid container direction="column" sx={{margin:"20px 80px 50px"}}>
        <Grid item>
          <Typography variant="h4" sx={{ color: 'black', marginTop: "30px", marginLeft: "30px", fontWeight: "bold" }}>
            About Us :
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h5" sx={{ color: 'black', marginTop: "30px", marginLeft: "30px", fontWeight: "bold" }}>
            Welcome to Story Buzz!!!
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6" sx={{ color: 'black', marginTop: "30px", marginLeft: "30px", fontWeight: "bold" }}>
            Our Vision :
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1' sx={{ width: "auto", marginTop: "5px", marginLeft: "30px",textAlign:'justify' }}>At StoryBuzz, our vision is to ignite a lifelong passion for reading and writing in children, empowering them to explore and cherish their native languages. We envision a world where every child experiences the joy of storytelling, discovers the richness of their cultural heritage, and develops the skills to express themselves creatively and confidently.</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6" sx={{ color: 'black', marginTop: "30px", marginLeft: "30px", fontWeight: "bold" }}>
            Our Mission :
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1' sx={{ width: "auto", marginTop: "5px", marginLeft: "30px" ,textAlign:'justify'}}>Our mission is to inspire and motivate children to embrace their native languages through engaging and innovative programs. By partnering with over 11 schools across the United States, we provide comprehensive, end-to-end solutions that make reading and writing enjoyable and accessible for every child. At StoryBuzz, we believe that by fostering a love for languages, we are building a stronger, more inclusive community where every child's voice can come alive and buzz with excitement.</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6" sx={{ color: 'black', marginTop: "30px", marginLeft: "30px", fontWeight: "bold" }}>
            Who Are We :
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1' sx={{ width: "auto", marginTop: "5px", marginLeft: "30px",textAlign:'justify' }}>StoryBuzz is a nonprofit initiative of Tamilezhuthapadi Publications and Research, dedicated to making a difference in the lives of children. We are a team of passionate educators and language enthusiasts committed to promoting literacy and preserving linguistic heritage.</Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1' sx={{ width: "auto", marginTop: "5px", marginLeft: "30px",textAlign:'justify' }}><b>Story</b>: Stories are at the heart of what we do. They are the building blocks of language, culture, and imagination. Through stories, children learn about the world around them, develop empathy, and gain a deeper understanding of their heritage.</Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1' sx={{ width: "auto", marginTop: "5px", marginLeft: "30px",textAlign:'justify' }}><b>Buzz:</b> The word "buzz" signifies excitement, energy, and liveliness. It represents the enthusiasm we aim to instill in children as they explore and create their own stories. Just like a buzzing bee, we are constantly active, spreading the joy of storytelling to every corner of our community.</Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1' sx={{ width: "auto", marginTop: "5px", marginLeft: "30px",textAlign:'justify' }}>Together, StoryBuzz encapsulates our commitment to making reading and writing an exciting adventure for children. By nurturing a buzzing community of young storytellers, we are not only preserving linguistic heritage but also fostering a love for learning that will last a lifetime.</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6" sx={{ color: 'black', marginTop: "30px", marginLeft: "30px", fontWeight: "bold" }}>
            Our Programs:
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1' sx={{ width: "auto", marginTop: "5px", marginLeft: "30px",textAlign:'justify' }}>Our programs are designed to cater to the diverse needs of children at various stages of their language learning journey. From interactive storytelling sessions to creative writing workshops, we offer a range of activities that are both fun and educational. We also provide resources and training for teachers to help them seamlessly integrate language learning into their curriculum.</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6" sx={{ color: 'black', marginTop: "30px", marginLeft: "30px", fontWeight: "bold" }}>
            Why Choose StoryBuzz:
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1' sx={{ width: "auto", marginTop: "5px", marginLeft: "30px",textAlign:'justify' }}><b>Holistic Approach:</b> We offer end-to-end solutions, from program design to implementation, ensuring a seamless experience for schools and students.<br />

            <b>Expert Team:</b> Our team brings a diverse set of skills and a deep understanding of language education.<br />

            <b>Proven Impact:</b> With over 11 participating schools, our programs have already made a significant difference in the lives of countless children.<br />

            <b>Passion for Languages:</b> We are driven by our love for languages and our desire to see every child succeed.</Typography>
        </Grid>
        <Grid item>
          <Typography  variant='h4' sx={{ display:'flex',justifyContent:'center', paddingTop: "30px", paddingBottom: "30px", color: "red" }}><Box sx={blinkingStyle}>Our Slogan: "Where Stories Come Alive and Buzz!"</Box></Typography>
        </Grid>
      
          
        <Grid item>
         <Box >
          <Box display={'flex'}>
            <Box>
            <Box component={'img'} src={img3} float={'left'} pr={'20px'} mt='10px' width={'300px'} height={'320px'}></Box>
             <Typography display={'inline-block'}  fontWeight={'bold'}  pt={'15px'}>Founder /Chairman - Deepa Akilan </Typography>
            </Box>

          <Typography  sx={{textAlign:'justify'}} >
           As the founder of StoryBuzz, Deepa Akilan brings over a decade of rich experience in educational leadership and publishing. With six years as the principal of Cumming Tamil School and five years as President of Tamilezhuthapadi Publications, she has been deeply committed to promoting the Tamil language and literacy among young learners. Her journey has been driven by a passion for empowering children to embrace their cultural heritage through reading and writing, fostering a lifelong love for learning in their native language.
           <Typography pt="20px">
          In 2020-2021, she managed to publish 150 Tamil books authored by students, all under one roof—an extraordinary achievement.She is known for launching the first-ever official Tamil literary program in a U.S. library, “Kutees Kondattam,” from 2021–2022. Deepa also initiated and ran “Atlanta Visai Parai” in 2022–2023, a unique club focused on teaching traditional Tamil Parai drumming to children. Her pioneering efforts have significantly strengthened Tamil culture and other languages within her community. Additionally, she serves as the only Tamil school principal representing the Tamil language in the Secondary Schools Principals Association, exemplifying her leadership and dedication to preserving and promoting Tamil culture in educational settings.
          </Typography>
          </Typography>
          
          </Box>
          <Box mt={'50px'} display={'flex'}>
         

        <Typography  sx={{textAlign:'justify'}}>Meenakshi Palaniappan is a multifaceted individual with a deep passion for learning and community service. By day, she excels as a software professional, using her expertise to drive innovation and solve real-world challenges.  

Her fascination with languages extends beyond the classroom.As the Executive Editorial Director, Meenakshi Palanippan has been pivotal in upholding the highest standards of quality and service at both the Tamil school and Tamilezhuthapadi Publications for the past five years. Her leadership and commitment to excellence have played a crucial role in ensuring that the educational content and publications not only meet but exceed expectations, fostering a rich learning environment for students and readers alike.. Additionally, she has authored three children's books on various subjects, further contributing to the literary world. 

Meenakshi is also an avid supporter of scouting, believing strongly in the importance of character development and community service. Scouting reflects these values, and she is deeply involved in this movement. In her spare time, you can often find her curled up with a good book, always eager to expand her knowledge and broaden her perspectives. </Typography>
          <Box>
            <Box component={'img'} src={img4} float={'left'} mt='10px' pl={'20px'}  width={'300px'} height={'320px'}></Box>
             <Typography display={'inline-block'}  fontWeight={'bold'} pt={'15px'}>Executive Editorial Director - Meenakshi Palaniappan </Typography>
            </Box>
          </Box>
          <Box mt={'50px'} display={'flex'}>
         

          <Box>
            <Box component={'img'} src={img2} float={'left'} mt='10px' pr={'20px'} width={'300px'} height={'320px'}></Box>
             <Typography display={'inline-block'}  fontWeight={'bold'} pt={'15px'} >Co-Founder / Communications & Marketing Director - Akilan Perumal  </Typography>
            </Box>
        <Typography  sx={{textAlign:'justify'}}>As the Communication and Marketing Director and co-founder of a children's book publications company, Akilan Perumal leads the development and implementation of innovative marketing strategies that elevate the company’s brand and products. He expertly manages all facets of communication, from crafting compelling digital campaigns to orchestrating impactful public relations initiatives, ensuring they resonate with parents, educators, and young readers alike. 

Akilan oversees a dynamic team responsible for creating engaging content, collaborating with authors and illustrators, and enhancing the company’s social media presence. He also drives market research, stays abreast of industry trends, and forges strategic partnerships with schools, libraries, and retailers to broaden the company’s reach and influence. 

Beyond his professional achievements, he is an avid cricket enthusiast, captaining the Atlanta Super Kings cricket team for the past five years. He also has a passion for music and enjoys listening to a variety of genres. 

Additionally, Akilan is a respected leader in the financial banking sector, where he has made significant strides in his career.  </Typography>
          </Box>
          <Box mt={'50px'} display={'flex'}>
         

        <Typography  sx={{textAlign:'justify'}}>Vinayagam Mahalingam has been the Publishing and Printing Director at Tamilezhuthapadi Publications for the past five years, where his extensive expertise and meticulous attention to detail have been pivotal. His leadership ensures that every publication upholds the highest standards of quality, from manuscript to final print. Vinayagam's dedication to preserving and advancing Tamil literature is evident in his thorough approach to the publishing process, ensuring that each book not only engages readers but also enriches the cultural and literary heritage. 

His unwavering commitment to excellence has significantly contributed to the success of Tamilezhuthapadi Publications. Beyond his professional achievements, Vinayagam is a prominent local political leader and is actively involved in philanthropic work, further demonstrating his dedication to community and cultural development. 

    </Typography>
          <Box>
            <Box component={'img'} src={img1} float={'left'} mt='10px' width={'300px'} height={'320px'} pl={'20px'}></Box>
             <Typography display={'inline-block'}  fontWeight={'bold'}pt={'15px'} alignItems={'center'} >Publishing and Printing Director - Vinayagam Mahalingam  </Typography>
            </Box>
          </Box>
         </Box>
            
        </Grid>   
        <Grid item>
          <Typography variant='body1' sx={{ fontWeight:'bold', marginTop: "20px", display:'flex',justifyContent:'center'}}>Join us at StoryBuzz in our mission to inspire the next generation of readers and writers. Together, we can make a lasting impact on the future of our children and our communities, ensuring that every story comes alive and buzzes with excitement.</Typography>
        </Grid> 
        <Grid item>
          <Typography  variant='h4' sx={{ display:'flex',justifyContent:'center', paddingTop: "30px", paddingBottom: "30px", color: "red" }}>Let’s embark on this magical journey together!</Typography>          
        </Grid> 
      </Grid>
    </Box>
    <FloatingDonateButton/>
    
    </Box>
  )
};

export default AboutUs;
