import React, { useEffect, useState, useContext } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Button,
  TextField,
  Grid,
  Box,
  Card,
  CardContent,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  FormLabel,
  Select,
  InputLabel,
  Divider,
  CardMedia,
  CardActions,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import axiosInstance from "../utils/AxiosInstance";
import BookCardTwo from "../common/BookCardTwo";
import { AuthContext } from "../../hooks/context/AuthContext";
import { useNavigate, useLocation, json } from "react-router-dom";
import { Add, Delete, Remove } from "@mui/icons-material";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";


const Checkout = (address) => {
  const [newAddress, setNewAddress] = useState({
    fullname: "",
    mobile: "",
    emailid: "",
    zipcode: "",
    addressLocation: "",
    city: "",
    address: "",
    state: "",
    addNotes:"",
    alternatePhone: "",
  });
  const [addresses, setAddresses] = useState([]);
  // const [editAddresses, setEditAddresses] = useState([formData]);
  // const [formData, setFormData] = useState(currentAddress);

  const [expanded, setExpanded] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(addresses[0] || null);
  const [customsInfo, setCustomsInfo] = useState("Provided");
  const [paymentInfo, setPaymentInfo] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [activeBooks, setActiveBooks] = useState([]);
  const [bookCounts, setBookCounts] = useState({});
  const [buybookCounts, setBuyBookCount] = useState(1);
  const [totalPrice, setTotalPrice] = useState(0);
  const [shippingCharge, setShippingCharge] = useState(0);
  const [taxAmount, setTaxAmount] = useState(0);
  const [totalPriceWithTax, setTotalPriceWithTax] = useState(0);
  const [discount, setDiscount] = useState(0);
  const { user } = useContext(AuthContext);
  const [orderBook, setOrderBook] = useState(null);
  const [usedAddress, setUsedAddress] = useState(addresses[0]);
  const [isEditingAddr, setIsEditingAddr] = useState(null);
  console.log(isEditingAddr, "iddd");
  const [load, setLoad] = useState(false)
  const [finalCartTotal, setFinalCartTotal] = useState(0)
  const [finalBuyNowTotal, setFinalBuyNowTotal] = useState(0)
  const [paymentMethod, setPaymentMethod] = useState("paypal");
  const [payoutRecipient, setPayoutRecipient] = useState("");
  const [openNote, setOpenNote]=useState(false)
  const [isBuyNow, setIsBuyNow] = useState(false);

  const userId = user?.id;
  const addressId = user?.id;
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [bookToDelete, setBookToDelete] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [errors, setErrors] = useState({});
  const [mobileInput, setMobileInput] = useState("");
  const [mobileError, setMobileError] = useState("");

  const states = [
    "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado",
    "Connecticut", "Delaware", "Florida", "Georgia", "Hawaii", "Idaho",
    "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana",
    "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota",
    "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire",
    "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota",
    "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina",
    "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virginia",
    "Washington", "West Virginia", "Wisconsin", "Wyoming"
  ];
  // const { fullname, mobile, emailid, zipcode, location, city, state, address, landmark, alternatePhone, addressType } = req.body;
  const location = useLocation();
  const bookId = location.state?.bookId; // Safely access the bookId
  useEffect(() => {
    if (addresses.length > 0 && !selectedAddress) {
      setSelectedAddress(addresses[0]);
    }
  }, [addresses, selectedAddress]);

  // useEffect(() => {
  //   const useAddress = localStorage.getItem('selectedAddress')
  //   console.log(useAddress, 'adresssfunction')
  //    setUsedAddress(JSON.parse(useAddress))
  // }, [selectedAddress])
  useEffect(() => {
    setAddresses([{ ...newAddress }])
  }, [newAddress])
 const writeNotes =()=>{
  setOpenNote(true)
 }
 const handleCloseNotes =()=>{
  setOpenNote(false)
 }
  // useEffect(()=>{
  // if(openDialog){
  //   document.body.style.overflow='auto'
  // }else{
  //    document.body.style.overflow='hidden'
  // }return()=>{
  //   document.body.style.overflow='auto'
  // }
  // },[openDialog])
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleUseAddress = (step) => {



    // setUsedAddress(JSON.parse(useAddress))
    // setUsedAddress(selectedAddress);
    setExpanded(step);
  };
  const handleBookClick = (book) => {
    navigate(`/book/${book.book_id}`);
  };

  // const handleInputChange = (e) => {
  //   setFormData({ ...formData, [e.target.name]: e.target.value });
  // };

  const handleClose = () => {
    setOpen(false);
  };

  const ShoppingCartItem = ({
    book,
    handleIncrement,
    handleDecrement,
    handleDelete,
    bookCounts,
    // setBookCounts, // Assuming this function is passed as a prop to update counts
  }) => {
    const bookCount = bookCounts[book.id] || 1;
    const [isBuyNow, setIsBuyNow] = useState(false);

    return (
      <Card sx={{ display: "flex", p: 2, m: 5, border: "1px solid #B6B3B2"}}>
        <CardMedia
          component="img"
          sx={{ width: 150, objectFit: "cover" }}
          image={`${process.env.REACT_APP_BOOK_COVER_BASE_URL}${book.cover_image_url}`}
          alt={book.title}
        />
        <Box sx={{ flex: 1, pl: 2, display: "flex", flexDirection: "column" }}>
          <CardContent sx={{ flex: "1 0 auto" }}>
            <Typography variant="h6">{book.title}</Typography>
            <Typography variant="body2" color="text.secondary">
              {book.author_name}
            </Typography>
            {!isBuyNow && (
              <Typography variant="body2" mt={2}>
                Price per book: ${book.price}
              </Typography>
            )}

            {/* Conditional Subtotal */}
            {!isBuyNow && (
              <Typography variant="body2">
                Subtotal: ${(bookCounts[book.id] || 1) * book.price}
              </Typography>
            )}
          </CardContent>

          <CardActions sx={{ justifyContent: "space-between", pt: 0 }}>
            {!isBuyNow ? (
              // Cart Mode: Show increment/decrement controls
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: { xs: "100px", sm: "120px" },
                  border: "2px solid #B6B3B2",
                  borderRadius: "4px",
                  padding: "4px",
                }}
              >
                {bookCount === 1 ? (
                  <IconButton
                    onClick={() => handleDelete(book.id, false)}
                    size="small"
                  >
                    <Delete />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => handleDecrement(book.id, false)}
                    size="small"
                  >
                    <Remove />
                  </IconButton>
                )}

                <Typography variant="body1" mx={1}>
                  {bookCount}
                </Typography>

                <IconButton
                  onClick={() => handleIncrement(book.id, false)}
                  size="small"
                >
                  <Add />
                </IconButton>
              </Box>
            ) : (
              // Buy Now Mode: No quantity controls
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: { xs: "100px", sm: "120px" },
                  border: "2px solid #B6B3B2",
                  borderRadius: "4px",
                  padding: "4px",
                }}
              >
                {bookCount === 1 ? (
                  <IconButton
                    onClick={() => handleDelete(book.id, true)}
                    size="small"
                  >
                    <Delete />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => handleDecrement(book.id, true)}
                    size="small"
                  >
                    <Remove />
                  </IconButton>
                )}

                <Typography variant="body1" mx={1}>
                  {buybookCounts}
                </Typography>

                <IconButton
                  onClick={() => handleIncrement(book.id, true)}
                  size="small"
                >
                  <Add />
                </IconButton>
              </Box>
            )}
          </CardActions>
        </Box>
      </Card>
    );
  };


  const updateCartInBackend = async (bookId, newQuantity) => {
    try {
      const response = await axiosInstance.post(
        "/purchase/updateCartQuantity",
        {
          userId: userId, // Replace with actual userId
          bookId,
          newQuantity,
        }
      );

      if (response.status === 200) {
        const cartData = response.data.books;
        const counts = {};
        let total = 0;
        cartData.forEach((item) => {
          counts[item.id] = item.quantity;
          total += item.price * item.quantity;
        });
        setBookCounts(counts);
        setTotalPrice(response.data.totalPrice);
        setActiveBooks(cartData); // Set the fetched books in state
        setShippingCharge(response.data.shippingCharge);
        setTaxAmount(response.data.taxAmount); // Set the tax amount
        setTotalPriceWithTax(response.data.finalTotal); // Total price after tax
        setDiscount(response.data.discount);
        setFinalCartTotal(response.data.finalTotal);
        
        setIsBuyNow(false);
      }
    } catch (error) {
      console.error("Error updating cart:", error);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setNewAddress({
      fullname: "",
      mobile: "",
      emailid: "",
      zipcode: "",
      addressLocation: "",
      city: "",
      address: "",
      state: "",
      alternatePhone: "",
      addnotes:''
    });
  };

  const handleNewAddressChange = (event) => {
    const { name, value, mobile, emailid, zipcode, location, city, address, state, landmark, addressType, alternatePhone } = event.target;

    // Update newAddress and formData state simultaneously
    setNewAddress((prevAddress) => ({
      ...prevAddress,
      [name]: value,
      [mobile]: value,
      [emailid]: value,
      [zipcode]: value,
    }));

    // setFormData((prevFormData) => ({
    //   ...prevFormData,
    //   [name]: value,
    //   [mobile]: value,
    //   [emailid]: value,
    //   [zipcode]: value,
    // }));

    // Validation logic: Check if the field is empty
    validateField(name, value, mobile, emailid, zipcode);

  };

  const validateField = (name, value, mobile, emailid, zipcode) => {
    let errorMessage = '';

    if (!value.trim()) {
      errorMessage = `${name} is required`;  // Custom error message based on the field name
      errorMessage = `${mobile} is required`;  // Custom error message based on the field name
      errorMessage = `${emailid} is required`;
      errorMessage = `${zipcode} is required`;
    }

    // Update errors state
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage,
    }));
  };


  const validateName = (e) => {
    e.target.value = e.target.value.replace(/\D/g, "")

  }
  const handleIncrement = async (bookId) => {
    setBookCounts((prevCounts) => {
      const newCount = (prevCounts[bookId] || 1) + 1; // Increment count
      if (isBuyNow) {
        console.log("isBuyNow", isBuyNow)
        updateOrderInBackend(bookId, newCount); // Update quantity in orders table
        console.log("------bookId, newCount--------", bookId, newCount)
        setBuyBookCount(newCount);
        setIsBuyNow(true);
      } else {
        console.log("else")
        updateCartInBackend(bookId, newCount); // Update quantity in cart table
      }
      return {
        ...prevCounts,
        [bookId]: newCount, // Update state immediately with new count
      };
    });
  };
  const handleDecrement = async (bookId) => {
    setBookCounts((prevCounts) => {
      const newCount = Math.max((prevCounts[bookId] || 1) - 1, 0); // Prevent going below 0
      if (newCount >= 1) {
        if (isBuyNow) {
          updateOrderInBackend(bookId, newCount); // Update quantity in orders table
          setBuyBookCount(newCount);
          setIsBuyNow(true);
        } else {
          updateCartInBackend(bookId, newCount); // Update quantity in cart table
        }
      }
      return {
        ...prevCounts,
        [bookId]: newCount, // Update state immediately with
      };
    });
  };

  const handleDelete = async (bookId) => {
    try {
      let response;
      if (isBuyNow) {
        console.log("=======userId=====bookId", bookId, userId)
        // Call the backend to delete the book from the order
        // response = await axiosInstance.delete(
        //   "/purchase/deleteFromBuyNowOrder", {userId,bookId}
        // );
        response = await axiosInstance.delete(
          "/purchase/deleteFromBuyNowOrder", {
          userId: userId, // Use appropriate userId here
          bookId: bookId, // Use appropriate bookId here
        }
        );
        window.history.back();
        setIsBuyNow(true);
      } else {
        // Call the backend to delete the book from the cart
        response = await axiosInstance.delete(
          `/purchase/deleteFromCart/${userId}/${bookId}`
        );
      }
      if (response.status === 200) {
        // Update activeBooks state by filtering out the deleted book
        setActiveBooks((prevBooks) =>
          prevBooks.filter((book) => book.id !== bookId)
        );

        // Update bookCounts state by removing the deleted book count
        setBookCounts((prevCounts) => {
          const updatedCounts = { ...prevCounts };
          delete updatedCounts[bookId]; // Remove the count for the deleted book
          return updatedCounts;
        });

        // Remove from disabledBooks in localStorage if applicable
        const disabledBooks =
          JSON.parse(localStorage.getItem("disabledBooks")) || {};
        delete disabledBooks[bookId];
        localStorage.setItem("disabledBooks", JSON.stringify(disabledBooks));

        console.log("Book removed from cart");
      }
    } catch (error) {
      console.error("Error removing book from cart:", error);
    }
  };
  const updateOrderInBackend = async (bookId, newCount) => {
    console.log("----===--------", bookId, newCount)
    try {
      const response_1 = await axiosInstance.post(`/purchase/updateOrder/${userId}`, {
        bookId,
        quantity: newCount,
      });
      console.log("----===--response_1--response_1.data.order.quantity----", response_1, response_1.data.order.quantity)
      const cartData = response_1.data.books;
      const counts = {};
      let total = 0;
      cartData.forEach((item) => {
        counts[item.id] = item.quantity;
        total += item.price * item.quantity;
      });
      setBookCounts(counts);
      setTotalPrice(response_1.data.totalPrice);
      setActiveBooks(cartData); // Set the fetched books in state
      setShippingCharge(response_1.data.shippingCharge);
      setTaxAmount(response_1.data.taxAmount); // Set the tax amount
      setTotalPriceWithTax(response_1.data.finalTotal); // Total price after tax
      setDiscount(response_1.data.discount);
      setFinalBuyNowTotal(response_1.data.finalTotal)
      console.log("Order updated successfully");
      setIsBuyNow(true);
    } catch (error) {
      console.error("Error updating order:", error);
    }
  };

  useEffect(() => {
    const fetchActiveBooks = async () => {
      try {
        if (bookId) {
          // If bookId is present, fetch the specific book for Buy Now
          const response = await axiosInstance.get(
            `/books/getallbook/${bookId}`
          );
          const response_1 = await axiosInstance.get(
            `/purchase/getBuyCartBooks/${bookId}/${userId}`
          );
          console.log("------response_1-------", response_1, response_1.data.finalTotal);
          const cartData = response_1.data.books;
          const counts = {};
          let total = 0;
          cartData.forEach((item) => {
            counts[item.id] = item.quantity;
            total += item.price * item.quantity;
          });

          setBookCounts(counts);
          setTotalPrice(response_1.data.totalPrice);
          setShippingCharge(response_1.data.shippingCharge);
          setTaxAmount(response_1.data.taxAmount); // Set the tax amount
          setTotalPriceWithTax(response_1.data.finalTotal); // Total price after tax
          setDiscount(response_1.data.discount);
          // Set the final total specifically for Cart
          setFinalBuyNowTotal(response_1.data.finalTotal)
          setOrderBook(response.data);
          setIsBuyNow(true);
        } else {
          const response = await axiosInstance.get(
            `/purchase/getActiveCartBooks/${userId}`
          );
          // Set initial book counts and calculate the total price
          const cartData = response.data.books;
          const counts = {};
          let total = 0;
          cartData.forEach((item) => {
            counts[item.id] = item.quantity;
            total += item.price * item.quantity;
          });

          setBookCounts(counts);
          setTotalPrice(response.data.totalPrice);
          setActiveBooks(cartData); // Set the fetched books in state
          setShippingCharge(response.data.shippingCharge);
          setTaxAmount(response.data.taxAmount); // Set the tax amount
          setTotalPriceWithTax(response.data.finalTotal); // Total price after tax
          setDiscount(response.data.discount);
          // Set the final total specifically for Cart
          // setFinalCartTotal(response.data.finalTotal);
          setIsBuyNow(false);
        }
      } catch (error) {
        console.error("Error fetching active books:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchActiveBooks();
  }, [bookId, userId]);

  // // Function to fetch the quantity of a specific book for the current user
  // const fetchOrderQuantity = async (bookId, userId) => {
  //   try {
  //     console.log("0");
  //     const response = await axiosInstance.get(
  //       `/purchase/getOrderQuantity/${userId}/${bookId}`
  //     );
  //     if (response.status === 200) {
  //       // setBuyBookCount(response.data.quantity);
  //     } else {
  //       console.log("Order not found");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching order quantity:", error);
  //   }
  // };

  // // Call this function inside useEffect when component loads
  // useEffect(() => {
  //   // if (bookId && userId) {
  //   fetchOrderQuantity(bookId, userId);
  //   // }
  // }, [bookId, userId]);

  const handleClickOpen = (bookId) => {
    setBookToDelete(bookId); // Set the book ID to be deleted
    setOpen(true); // Open the dialog
  };

  const handleEditDialog = async () => {
    setOpenDialog(true);
    // try{
    //   const response = await axiosInstance.get(
    //     `/address/${addressId}addressId/`,
    //     newAddress
    //   )
    // }
  };
  // Save new address
  const handleAddAddress = async (e) => {
    e.preventDefault()
    setLoad(true)
    try {
      const response = await axiosInstance.post(
        `/users/${userId}/address`,
        newAddress
      );
      console.log(newAddress,'hhhhhhhhhhhhhhhhhhhhhhhhh')
      if (response.status === 201) {
        setAddresses([...addresses, response.data.address]);
        setSelectedAddress(response.data.address);
        handleCloseDialog(false);
      }
    } catch (error) {
      console.error("Error adding address:", error);
    } finally {
      setLoad(false)
      setOpenDialog(false);
    }
  };



  const handleSubmit = async () => {
    try {
      const response = await axiosInstance.put(
        `/api/update-address/${address.id}`,
        newAddress
      );
      console.log("Address updated successfully:", response.data);
      setOpen(false);
    } catch (error) {
      console.error("Error updating address:", error);
    }
  };

  // Fetch user addresses when component mounts
  const fetchAddresses = async () => {
    const response = await axiosInstance.get(`/users/${userId}/address`)
    setAddresses(response.data);
  };

  useEffect(() => {
    fetchAddresses();
  }, [userId]);

  const handleUpdateAddress = async (addId) => {
    console.log("edit", addId);

    try {
      const response = await axiosInstance.put(
        `/users/address/${addId}`,
        newAddress
      );

      console.log(response);
      setNewAddress({
        fullname: address.name,
        mobile: address.mobile_number,
        emailid: address.email_id,
        zipcode: address.zipcode,
        addressLocation: address.address_location,
        city: address.city,
        address: address.address,
        state: address.state,
        alternatePhone: address.alternate_phone,
        addnotes:address.addnotes

        // Add other fields like street, city, etc.
      });
      setAddresses([...addresses, response.data.address]);
      fetchAddresses();
    } catch {
    } finally {
      setOpenDialog(false);
    }
  };

  const handleEditAddress = async (addId) => {
    setOpenDialog(true);
    try {
      const response = await axiosInstance.get(`/users/getaddress/${addId}`);
      setIsEditingAddr(addId);
      const address = response.data[0];
      setNewAddress({
        fullname: address.name,
        mobile: address.mobile_number,
        emailid: address.email_id,
        zipcode: address.zipcode,
        addressLocation: address.addressLocation,
        city: address.city,
        address: address.address,
        state: address.state,
        alternatePhone: address.alternate_phone,
        addnotes:address.add_notes

        // Add other fields like street, city, etc.
      });
      console.log("=====cherck========", response.data, newAddress.name);
      // setOpenDialog(false)
      // setNewAddress(addresses)
      return response.data;
    } catch (error) {
      console.error("Error fetching address:", error);
    } finally {
    }
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleAddressChange = (event) => {
    const selectedId = event.target.value;
    const selected = addresses.find(
      (addr) => addr.id === parseInt(event.target.value)
    );
    if (selected) {
      setSelectedAddress(selected);
    }
    localStorage.setItem('selectedAddress', JSON.stringify(selected))
  };

  const handleApprove = async (data, actions) => {
    try {
      console.log("-----totalPriceWithTax------",totalPriceWithTax)
      console.log("-----details------",details)
      // Capture the order
      const details = await actions.order.capture();
      console.log("Transaction completed by ", details.payer.name.given_name);

      // Call your backend to save the transaction
      const res = await axiosInstance.post("/payment/paypalpurchase", {
        userId,
        amount: totalPriceWithTax,
        transactionDetails: details,
      });

      setSuccess(true);
      console.log("Payment Success:", res.data);
    } catch (err) {
      console.error("Error capturing payment:", err);
      setError("Payment could not be processed");
    }
  };
  // PayPal Integration for "Buy Now"
  const renderBuyNowPayPalButtons = () => (
    <PayPalButtons
      style={{ layout: "vertical" }}
      createOrder={(data, actions) => {
        return actions.order.create({
          purchase_units: [
            {
              amount: {
                value: finalBuyNowTotal, // Ensure correct total value
              },
            },
          ],
        });
      }}
      onApprove={handleApprove}
      onError={(err) => {
        console.error("PayPal Error:", err);
        setError(`Payment could not be processed: ${err.message || "Unknown error"}`);
      }}
    />
  );

  // PayPal Integration for "Add to Cart"
  const renderCartPayPalButtons = () => (
    <PayPalButtons
      style={{ layout: "vertical" }}
      createOrder={(data, actions) => {
        return actions.order.create({
          purchase_units: [
            {
              amount: {
                value: totalPriceWithTax, // Ensure correct total value
              },
            },
          ],
        });
      }}
      onApprove={handleApprove}
      onError={(err) => {
        console.error("PayPal Error:", err);
        setError(`Payment could not be processed: ${err.message || "Unknown error"}`);
      }}
    />
  );

  const handleError = (err) => {
    console.error("Error with PayPal:", err);
    setError("Payment could not be processed");
  };

  const handlePaymentSelection = (e) => {
    setPaymentMethod(e.target.value);
  };

  const handlePayout = async () => {
    if (!payoutRecipient || !totalPriceWithTax) {
      alert("Please enter recipient and amount");
      return;
    }

    try {
      const res = await axiosInstance.post("/payment/payouts", {
        recipient_type: paymentMethod === "paypal" ? "EMAIL" : "PHONE",
        recipient: payoutRecipient,
        amount: totalPriceWithTax,
      });
      alert(res.data.message);
    } catch (error) {
      console.error("Error processing payout:", error);
      alert("Payout failed");
    }
  };

  const handleNextStep = (step) => {
    setExpanded(step);
  };

  const handleChangeClick = (step) => {
    setExpanded(step);
  };

  const handleCloseClick = () => {
    setExpanded(false);
  };

  const goBack = () => {
    window.history.back();
  };

  return (
    <Box
      sx={{
        margin: "100px 40px 80px",
        borderRadius: "20px",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <IconButton
            onClick={goBack}
            sx={{
              position: "absolute",
              left: { xs: 30, md: 10 },
              top: { xs: 70, md: 80 },
              color: "black",
              bgcolor: "#fff",
              "&:hover": { bgcolor: "darkgreen", color: "#fff" },
              ml: 2,
            }}
            aria-label="Go Back"
          >
            <ArrowBackIcon sx={{ fontSize: 30 }} />
          </IconButton>
          <Box
            sx={{
              bgcolor: "#ffffff",
              maxWidth: 600,
              margin: "auto",
              border: '1px solid #B6B3B2', // Outline color
              borderRadius: 2,
              padding: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "30px",
                fontWeight: "bold",
                pb: "20px",
                // fontFamily:'Coiny'
              }}
            >
              Checkout
            </Typography>

            {/* Shipping Address */}

            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
              sx={{ border: "none", boxShadow: "none" }}
            >
              <AccordionSummary>
                <Grid item container justifyContent="space-between">
                  <Grid item>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{ fontWeight: "bold" }}
                    >
                      1. Shipping Address
                    </Typography>
                  </Grid>

                  <Grid item>
                    {expanded !== "panel1" && usedAddress && (
                      <Box
                        sx={{
                          width: "200px",
                          display: "flex",
                          alignItems: "center",
                          height: "auto",
                        }}
                      >
                        <Typography variant="body2" sx={{ width: "150px" }}>
                          <Typography>
                             {","} {","}
                          </Typography>
                          {usedAddress.fullname}
                          {usedAddress.mobile_number}
                          {usedAddress.address}
                          {", "}
                          {usedAddress.address_location}
                          {", "}
                          {usedAddress.city}
                          {", "}
                          {usedAddress.state}{","}
                          {usedAddress.zipcode}{"."}
                        </Typography>
                      </Box>
                    )}
                  </Grid>

                  <Grid item>
                    {expanded === "panel1" ? (
                      <Button
                        variant="text"
                        sx={{ color: "#FF4E33", marginLeft: "auto" }}
                        onClick={handleCloseClick}
                      >
                        Close
                      </Button>
                    ) : (
                      <Button
                        variant="text"
                        sx={{
                          color: "#FF4E33",
                          marginLeft: "auto",
                          height: "48px",
                        }}
                        onClick={handleChange("panel1")}
                      >
                        Change
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <FormControl component="fieldset">
                  <RadioGroup
                    value={selectedAddress ? selectedAddress.id : ""}
                    onChange={handleAddressChange}
                    aria-label="shipping address"
                    name="shipping-address"
                  >
                    {addresses.map((address) => (
                      <Card
                        key={address.id}
                        sx={{
                          marginBottom: 2,
                          border: "none",
                          boxShadow: "none",
                        }}
                      >
                        <CardContent>
                          <FormControlLabel
                            value={address.id}
                            control={
                              <Radio
                                sx={{
                                  color: "#FF4E33",
                                  "&.Mui-checked": { color: "#FF4E33" },
                                }}
                              />
                            }
                            label={
                              <Box>
                                <Typography variant="subtitle1">
                                  {address.name}
                                </Typography>
                                <Typography variant="body2">
                                  
                                {address.address}, {address.addressLocation}
                                </Typography>
                                <Typography>
                                  {" "}
                                  {address.city},
                                  {address.state},
                                  {address.zipcode},
                                 
                                </Typography>
                                <Typography>{address.email_id}</Typography>
                                <Typography> {address.mobile_number}, {address.add_notes}</Typography>
                                <Typography
                                  variant="body"
                                  fontSize={14}
                                  color="#FF4E33"
                                  sx={{
                                    "&:hover": { textDecoration: "underline" },
                                  }}
                                  //  onClick={handleEditDialog}
                                  //  onClick={handleEditAddress}
                                  onClick={() => handleEditAddress(address.id)}
                                >
                                  Edit address
                                </Typography>
                              </Box>
                            }
                          />
                        </CardContent>
                      </Card>
                    ))}
                  </RadioGroup>

                </FormControl>
                <Grid item xs={12} >
                  <Box
                    display="flex"
                    justifyContent="center"
                    onClick={handleOpenDialog}
                  >
                    <AddIcon />
                    <Typography
                      sx={{
                        "&:hover": { textDecoration: "underline" },
                        marginBottom: 2,
                        cursor: "pointer",
                        textAlign: "center",
                      }}
                    >
                      Add a new address
                    </Typography>

                  </Box>
                  <Box display={'flex'} justifyContent={'flex-end'}>
                    <Button
                      variant="contained"
                      sx={{
                        height: "30px",
                        width: "180px",
                        backgroundColor: "#FF4E33",
                        color: "white",
                        "&:hover": { backgroundColor: "#FF8C00" },
                        borderRadius: "20px",
                      }}
                      onClick={() => handleUseAddress("panel2")}
                    >
                      Use this address
                    </Button>
                  </Box>

                </Grid>

              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel2"}
              // onChange={handleChange("panel2")}
              sx={{ border: "none", boxShadow: "none" }}
            >
              <AccordionSummary>
                <Grid item container justifyContent="space-between">
                  <Grid item>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{ fontWeight: "bold" }}
                    >
                      2. Order Summary
                    </Typography>
                  </Grid>

                  <Grid item>
                    {!expanded && paymentInfo && (
                      <Box
                        sx={{
                          marginLeft: "auto",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="body2" sx={{ marginRight: 2 }}>
                          {paymentInfo}
                        </Typography>
                        <Button
                          variant="text"
                          color="primary"
                          onClick={() => handleChangeClick(2)}
                        >
                          Change
                        </Button>
                      </Box>
                    )}
                  </Grid>

                  <Grid item>
                    {expanded === "panel2" ? (
                      <Button
                        variant="text"
                        color="primary"
                        sx={{ color: "#FF4E33", marginLeft: "auto" }}
                        onClick={handleCloseClick}
                      >
                        Close
                      </Button>
                    ) : (
                      <Button
                        variant="text"
                        color="primary"
                        sx={{ color: "#FF4E33", marginLeft: "auto" }}
                        // onClick={handleChange("panel2")}
                        onClick={() => handleUseAddress("panel2")}
                      >
                        Change
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </AccordionSummary>

              <Grid container>
                {isBuyNow ? (
                  // Show the specific book for "Buy Now"
                  <Grid item xs={12} key={orderBook.book_id}>
                    <ShoppingCartItem
                      book={orderBook}
                      handleIncrement={handleIncrement}
                      handleDecrement={handleDecrement}
                      handleDelete={() => handleDelete(orderBook.id)}
                      bookCounts={bookCounts}
                    />
                  </Grid>
                ) : (
                  // Show all cart items
                  activeBooks.map((book, index) => (
                    <Grid item xs={12} key={book.book_id}>
                      <ShoppingCartItem
                        book={book}
                        handleIncrement={handleIncrement}
                        handleDecrement={handleDecrement}
                        handleDelete={() => handleDelete(book.id)}
                        bookCounts={bookCounts}
                      />
                      {index < activeBooks.length - 1 && <Divider />}{" "}
                      {/* Horizontal line between books */}
                    </Grid>
                  ))
                )}

                <Grid item xs={12} display="flex" justifyContent="flex-end">
                  <Button
                    variant="contained"
                    sx={{
                      height: "30px",
                      width: "140px",
                      backgroundColor: "#FF4E33",
                      color: "white",
                      "&:hover": { backgroundColor: "#FF8C00" },
                      borderRadius: "20px",
                    }}
                    onClick={() => handleNextStep("panel3")}
                  >
                    Continue
                  </Button>
                </Grid>
              </Grid>
            </Accordion>

            {/* Review Items */}
            <Accordion
              expanded={expanded === "panel3"}
              // onChange={handleChange("panel3")}
              sx={{ border: "none", boxShadow: "none" }}
            >
              <AccordionSummary>
                <Grid item container justifyContent="space-between">
                  <Grid item>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{ fontWeight: "bold" }}
                    >
                      3. Payment
                    </Typography>
                  </Grid>

                  <Grid item>
                    {expanded === "panel3" ? (
                      <Button
                        variant="text"
                        color="primary"
                        sx={{ color: "#FF4E33", marginLeft: "auto" }}
                        onClick={handleCloseClick}
                      >
                        Close
                      </Button>
                    ) : (
                      <Button
                        variant="text"
                        color="primary"
                        sx={{ color: "#FF4E33", marginLeft: "auto" }}
                        onClick={handleChange("panel3")}
                      >
                        Change
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                {/* <Typography variant="body1">
                  Here are the items and shipping details.
                </Typography> */}
                {/* <Button variant="contained" color="primary" fullWidth sx={{ marginTop: 2 }}>
                        Confirm & Place Order
                    </Button> */}
                {/* "client-id": "AcgvnHRPQl-UgJ5_PJxpTYtIlaWbUQijaRJp9TzvHewYwt3cW2i-t3N0OD5PrdSXwR4_Yc6cKqbyhCu_",  */}
                <PayPalScriptProvider
              options={{
                "client-id": "AcgvnHRPQl-UgJ5_PJxpTYtIlaWbUQijaRJp9TzvHewYwt3cW2i-t3N0OD5PrdSXwR4_Yc6cKqbyhCu_",
                currency: "USD",
              }}
      >
        {success ? (
          <h3>Payment Successful!</h3>
        ) : (
          <div>
            {error && <p style={{ color: "red" }}>{error}</p>}

            {
  isBuyNow ? (
    <div>
      <h3>Buy Now Total: ${finalBuyNowTotal}</h3>
      {finalBuyNowTotal > 0 ? renderBuyNowPayPalButtons() : <p style={{ color: "red" }}>Invalid total amount. Please try again.</p>}
    </div>
  ) : (
    <div>
      <h3>Cart Total: ${totalPriceWithTax}</h3>
      {totalPriceWithTax > 0 ? renderCartPayPalButtons() : <p style={{ color: "red" }}>Invalid total amount. Please try again.</p>}
    </div>
  )
}

          </div>
        )}
      </PayPalScriptProvider>
                {/* 
                <div>
                  <h3>Total: ${totalPriceWithTax}</h3>
                  <h4>Select Payment Method</h4>
                  <div>
                    <label>
                      <input
                        type="radio"
                        value="paypal"
                        checked={paymentMethod === "paypal"}
                        onChange={handlePaymentSelection}
                      />
                      PayPal
                    </label>
                    <label>
                      <input
                        type="radio"
                        value="bank"
                        checked={paymentMethod === "bank"}
                        onChange={handlePaymentSelection}
                      />
                      Direct Bank Transfer
                    </label>
                  </div>

                  <div>
                    <h4>
                      Enter Recipient{" "}
                      {paymentMethod === "paypal" ? "Email" : "Phone"}
                    </h4>
                    <input
                      type="text"
                      value={payoutRecipient}
                      onChange={(e) => setPayoutRecipient(e.target.value)}
                      placeholder={
                        paymentMethod === "paypal"
                          ? "PayPal Email"
                          : "Phone Number"
                      }
                    />
                  </div>

                  <button onClick={handlePayout}>Send Payout</button>
                </div> */}
              </AccordionDetails>
            </Accordion>
            {/* Payment Method */}
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box sx={{ position: "relative", position: "sticky", top: 80 }}>
            <Card sx={{ p: 2, border: '1px solid #B6B3B2' }}>
              <CardContent>
                <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold",textAlign:'center' }}>
                  {/* {isBuyNow ? "Order Summary" : "Cart Price Details"} */}
                  Order Summary
                </Typography>
                <hr style={{ border: "1px solid #B6B3B2", width: "100%" }} />
                <Box mt={2}>
                  <Box display={"flex"}>
                    <Typography variant="body1" mb={2} textAlign={"left"}>
                      Total Items:
                    </Typography>
                    <Typography position={"absolute"} right={30}>
                      {activeBooks.length}
                    </Typography>
                  </Box>
                  <Box display={"flex"}>
                    <Typography variant="body1" mb={2}>
                      Total Price:
                    </Typography>
                    <Typography position={"absolute"} right={30}>
                      ${totalPrice}.00
                    </Typography>
                  </Box>
                  <Box display={"flex"}>
                    <Typography variant="body1" mb={2}>
                      Discount:
                    </Typography>
                    <Typography position={"absolute"} right={30}>
                      -${discount}
                    </Typography>
                  </Box>
                  <Box display={"flex"}>
                    <Typography variant="body1" mb={2}>
                      Shipping Charges:
                    </Typography>
                    <Typography position={"absolute"} right={30}>
                      ${shippingCharge}
                    </Typography>
                  </Box>
                  <Box display={"flex"}>
                    <Typography variant="body1" mb={2}>
                      Tax (7%):
                    </Typography>
                    <Typography position={"absolute"} right={30}>
                      ${taxAmount}
                    </Typography>
                  </Box>

                  <hr style={{ border: "1px solid #B6B3B2", width: "100%" }} />
                  <Box display={"flex"} mt={2}>
                    <Typography variant="body1" mb={2} sx={{ fontWeight: "bold" }}>
                      Final Price:
                    </Typography>
                    <Typography position={"absolute"} right={30} sx={{ fontWeight: "bold" }}>
                      ${totalPriceWithTax}
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Grid>

      </Grid>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        {/* <DialogTitle>Add Address</DialogTitle> */}
        <DialogTitle  fontSize={'30px'} fontWeight={'bold'}>Add address</DialogTitle>
        <form onSubmit={(e)=>handleAddAddress(e)} >
          <DialogContent >
            <Grid container spacing={2} >
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  label="Full name"
                  variant="outlined"
                  name="fullname"
                  value={newAddress.fullname}
                  onChange={handleNewAddressChange}
                  error={!!errors.fullname}
                  helperText={errors.fullname}
                  required
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",
                      height: "50px",
                    },
                    "& .MuiInputLabel-root": { color: "black" },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={!!errors.mobile}
                  helperText={errors.mobile}
                  fullWidth

                  label="Mobile number"
                  variant="outlined"
                  name="mobile"
                  // value={newAddress.mobile}
                  inputProps={{ minLength: 10, maxLength: 10 }} // Max length for input
                  onChange={(e) => { setMobileInput(e.target.value.replace(/\D/g, "")); setMobileError(""); }}
                  value={mobileInput+newAddress.mobile}
                  type="tel"
                  maxLength="10"
                  autoComplete="true"
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+1</InputAdornment>
                    ),
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '10px',
                      height: "50px",
                    },
                    '& .MuiInputLabel-root': {
                      color: 'black',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  // error={!!errors.alternatePhone}
                  // helperText={errors.alternatePhone}
                  fullWidth
                  label="Alternate Phone (Optional)"
                  variant="outlined"
                  name="alternatePhone"
                  value={newAddress.alternatePhone}
                  onChange={handleNewAddressChange}
                  onInput={validateName}
                  // required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+1</InputAdornment>
                    ),
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",
                      height: "50px",
                    },
                    "& .MuiInputLabel-root": { color: "black" },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>

                <TextField
                  // error={!!errors.emailid}
                  // helperText={errors.emailid}
                  // required
                  fullWidth
                  label="Email ID"
                  variant="outlined"
                  name="emailid"
                  value={newAddress.emailid}
                  onChange={handleNewAddressChange}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",
                      height: "50px",
                    },
                    "& .MuiInputLabel-root": { color: "black" },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={!!errors.address}
                  helperText={errors.address}
                  fullWidth
                  label="Street address or P.O.Box"
                  variant="outlined"
                  name="address"
                  multiline
                  rows={1}
                  value={newAddress.address}
                  onChange={handleNewAddressChange}
                  required
                  sx={{
                    "& .MuiOutlinedInput-root": { borderRadius: "10px" },
                    "& .MuiInputLabel-root": { color: "black" },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  // error={!!errors.address}
                  // helperText={errors.address}
                  fullWidth
                  label="Apt, suite, unit, building, floor, etc."
                  variant="outlined"
                  name="addressLocation"
                  multiline
                  rows={1}
                   value={newAddress.addressLocation}
                    onChange={handleNewAddressChange}
                  required
                  sx={{
                    "& .MuiOutlinedInput-root": { borderRadius: "10px" },
                    "& .MuiInputLabel-root": { color: "black" },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  error={!!errors.city}
                  helperText={errors.city}
                  fullWidth
                  label="City"
                  variant="outlined"
                  name="city"
                  value={newAddress.city}
                  onChange={handleNewAddressChange}
                  size="small"
                  required
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",
                      height: "50px",
                    },
                    "& .MuiInputLabel-root": { color: "black" },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  required
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",
                      height: "50px",
                    },
                    "& .MuiInputLabel-root": { color: "black" },
                  }}
                >
                  <InputLabel>State</InputLabel>
                  <Select
                    label="State"
                    value={newAddress.state}
                    name="state"
                    onChange={handleNewAddressChange}
                  >
                    {states.map((state, index) => (
                      <MenuItem key={index} value={state}>{state}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  error={!!errors.zipcode}
                  helperText={errors.zipcode}
                  fullWidth
                  label="ZIP Code"
                  variant="outlined"
                  name="zipcode"
                  onInput={(e) => validateName(e)}
                  inputProps={{ maxLength: 10 }}
                  value={newAddress.zipcode}
                  onChange={handleNewAddressChange}

                  required
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",
                      height: "50px",
                    },
                    "& .MuiInputLabel-root": { color: "black" },
                  }}
                />
              </Grid>
              {/* <Grid item xs={12} sm={6}>
              <TextField
                error={!!errors.location}
                helperText={errors.location}
                fullWidth
                label="Locality"
                variant="outlined"
                name="location"
                value={newAddress.location}
                onChange={handleNewAddressChange}
                size="small"
                required
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "10px",
                    height: "50px",
                  },
                  "& .MuiInputLabel-root": { color: "black" },
                }}
              />
            </Grid> */}


              {/* <Grid item xs={12} sm={6}>
                <TextField
                  error={!!errors.landmark}
                  helperText={errors.landmark}
                  fullWidth
                  label="Landmark (Optional)"
                  variant="outlined"
                  name="landmark"
                  value={newAddress.landmark}
                  onChange={handleNewAddressChange}
                  required
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",
                      height: "50px",
                    },
                    "& .MuiInputLabel-root": { color: "black" },
                  }}
                />
              </Grid> */}

              {/* Address Type - Radio buttons */}
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  {/* <FormLabel
                    component="legend"
                    sx={{ color: "#1976d2" }}
                    value={newAddress.addressType}
                  >
                    Address Type
                  </FormLabel>
                  <RadioGroup row>
                    <FormControlLabel
                      value="home"
                      control={<Radio />}
                      label="Home"
                    />
                    <FormControlLabel
                      value="work"
                      control={<Radio />}
                      label="Work"
                    />
                  </RadioGroup> */}
                  <Typography sx={{cursor:'pointer'}} >Add notes </Typography>
                 <TextField
                 name="addNotes"
                  value={newAddress.addNotes}
                  onChange={handleNewAddressChange}
                 sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",
                      height: "50px",
                    },
                    "& .MuiInputLabel-root": { color: "black" },
                  }}/>
                  <Dialog open={openNote} border={'none'}>

                  <DialogActions>
                    <Button onClick={handleCloseNotes} color="secondary">Cancel</Button>
                    <Button>Submit</Button>
                  </DialogActions>
                  </Dialog>
                </FormControl>
              </Grid>
            </Grid>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="secondary">
                Cancel
              </Button>
              {isEditingAddr ? (
                <Button
                  onClick={() => handleUpdateAddress(isEditingAddr)}
                  color="primary"
                  variant="contained"
                  sx={{
                    height: "30px",
                    width: "auto",
                    backgroundColor: "#FF4E33",
                    color: "white",
                    "&:hover": { backgroundColor: "#FF8C00" },
                  }}
                >
                  Update
                </Button>
              ) : (
                <Button
                  // onClick={handleAddAddress}
                  type="submit"
                  color="primary"
                  variant="contained"
                  sx={{
                    height: "30px",
                    width: "150px",
                    backgroundColor: "#FF4E33",
                    color: "white",
                    "&:hover": { backgroundColor: "#FF8C00" },
                  }}
                >
                  {load ? <Box><CircularProgress size={15} sx={{ color: "#ffffff" }} /></Box> : "Add Address"}
                </Button>
              )}
            </DialogActions>
          </DialogContent>

        </form>
      </Dialog>
    </Box>
  );
};

export default Checkout;
