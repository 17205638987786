import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Box, ThemeProvider, Grid } from '@mui/material';
import axiosInstance from "../utils/AxiosInstance";
import theme from "../ui/Theme";
import bgImg from "../../assets/final/Login.jpg";
import { useTranslation } from "react-i18next";
import Navbar from '../layout/Navbar';

const PasswordResetForm = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const {t}= useTranslation("login")

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post('/auth/reset-password-request', { email });
      if (response.status === 200 && response.data.message === "Password reset email sent successfully") {
      setMessage(response.data.message);
    }
   }catch (error) {
    if (error.response && error.response.status === 400 && error.response.data.error === "Email not found") {
      setMessage('Email not found please register.');
    } else (
      console.log("Internal server error", error)
    )
  }};

  return (
  <ThemeProvider theme={theme}>
    <Navbar/>
    <Box display="flex" justifyContent="flex-end" alignItems="center" position="relative" minHeight="100vh" pt={10} pb={5} sx={{justifyContent: {xs:"center", md:"flex-end"}, pr:{md:"150px"}, backgroundImage: `url(${bgImg})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center"}}>
    <Grid container direction="column" justifyContent="center" alignItems="center" boxShadow={1} borderRadius="50px" width="380px" p={5} sx={{bgcolor:{xs:"#ffffffff", md:"#ffffff39", }}}>
        <Grid item xs={8} sm={8} md={6} lg={3}>
            <Typography variant="h4">{t("login.forgotPass")}?</Typography>

        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label={t("login.email")}sendresetemail
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="normal"
            required
            sx={{ '& .MuiOutlinedInput-root': {borderRadius: '10px', height:"50px",}, '& .MuiInputLabel-root': { color: 'black',},}}
          />
          <Button type="submit" fullWidth variant="contained" color="primary" sx={{mt:"20px", color: '#ffffff', backgroundColor:"#000000", '&:hover': {backgroundColor: '#000000ff', borderColor: '#000000', },}}>{t("login.sendresetemail")}</Button>
        </form>
        {message && <Typography mt={2}>{message}</Typography>}

        </Grid>
      </Grid>
    </Box> 
    </ThemeProvider>

    // <Grid container direction="columm" justifyContent="center" alignItems="center" width="auto">
    //     <Grid item xs={8} >

    //   </Grid>
    // </Grid>
  );
};

export default PasswordResetForm;
