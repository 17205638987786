import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  Dialog,
  DialogContent,
  useMediaQuery,
  useTheme,
  Link,
  CircularProgress
} from '@mui/material';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faComment, faBookmark, faShare, faBookOpenReader } from '@fortawesome/free-solid-svg-icons';
import Comments from '../common/Comments';
import Share from '../common/Share';
import BookCard from '../common/BookCard';
import axiosInstance from '../utils/AxiosInstance';
import LikeButton from '../common/LikeButton';
import FavouriteButton from '../common/FavouriteButton';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../hooks/context/AuthContext';
import BookView from "../bookViewer/BookView";
import FloatingDonateButton from '../donate/FloatingDonateButton';
import { LanguageContext } from "../../hooks/context/LanguageContext";
import Loader from "../bookCreator/Loader"
import ShareIcon from '@mui/icons-material/Share';
import Navbar from '../layout/Navbar'
const BookDescription = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isXlScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const [bookmarked, setBookmarked] = useState(false);
  const [openCommentsDialog, setOpenCommentsDialog] = useState(false);
  const [openShareDialog, setOpenShareDialog] = useState(false);
  const [books, setBooks] = useState([]);
  const { isAuthenticated, user, loading } = useContext(AuthContext);
  const [readDialogOpen, setReadDialogOpen] = useState(false);
  const [value, setValue] = useState(0)
  const { language } = useContext(LanguageContext);
  const location = useLocation();
  const { t } = useTranslation("nav");
  const [isDisabled, setIsDisabled] = useState(false);
  const navigate = useNavigate();
  // const change =()=>{
  //   setValue('0')
  //   localStorage.setItem("TabValue",0)
  //   const bh= localStorage.setItem('prevPath', location.pathname + location.search);
  //   console.log(bh,"sdv");
  // }
  const { bookId } = useParams();

  const [book, setBook] = useState(null);

  const [addedBooks, setAddedBooks] = useState([]);  // Tracks the books added to the cart
  const [cartCount, setCartCount] = useState(0);     // Tracks the cart item count


  // const handleAddToCart = (bookId) => {
  //   // console.log('Book ID:', bookId);

  //   if (!addedBooks.includes(bookId)) {
  //     setAddedBooks((prev) => {
  //       const newAddedBooks = [...prev, bookId];
  //       console.log('Updated addedBooks:', newAddedBooks); // Log the updated list here
  //       return newAddedBooks;
  //     });
  //     setCartCount((prevCount) => prevCount + 1); // Update cartCount based on previous value
  //   }
  // };


  // Function to handle the cart icon click
  // const handleCartClick = () => {
  //   if (cartCount > 0) {
  //     navigate("/user-dashboard", { state: { selectedTab: 2 } });
  //   } else {
  //     console.log("Cart is empty!");
  //   }
  // };

  const userId = user?.id;

  const handleBuyClick = async (bookId) => {
    // const quantity = 1; // Set the quantity for the order, or retrieve it from state if needed
    if (!isAuthenticated || user?.role !== 'user') {

      const bookPath = location.pathname + location.search;
      navigate('/auth/login', { state: { bookPath } });
      return;
    }
  
    try {
      const response = await axiosInstance.post('/purchase/BuyaddToCart', {
        userId,
        bookId,
        // quantity,
      });
    console.log("-----------response----------",response)
  
      if (response.status === 200) {
        console.log("Order created successfully:", response.data.cartId);
        navigate("/checkout", {
          state: { bookId: bookId , fromBuyButton: true } // Pass bookId in state
        });
      }
    } catch (error) {
      console.error("Error creating order:", error);
    }
  };
  



  // const handleAddToCart = async (userId, bookId) => {
  //   try {
  //     const response = await axiosInstance.post('/purchase/addToCart', { userId, bookId });
  //     if (response.status === 200) {
  //       setAddedBooks([...addedBooks, bookId]);  // Add the newly added book to the state
  //       console.log('Book added to cart:', response.data);
  //     }
  //   } catch (error) {
  //     console.error('Error adding book to cart:', error);
  //   }
  // };

  // Initialize state as an object that holds the disabled state for each book
  const [disabledBooks, setDisabledBooks] = useState({});

  useEffect(() => {
    // Retrieve the disabled states from localStorage on page load
    const storedDisabledBooks = JSON.parse(localStorage.getItem('disabledBooks')) || {};
    setDisabledBooks(storedDisabledBooks);
  }, []);


  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'disabledBooks') {
        const disabledBooksFromStorage = JSON.parse(event.newValue) || {};
        setIsDisabled(disabledBooksFromStorage[bookId] || false);
      }
    };

    // Add event listener for storage changes
    window.addEventListener('storage', handleStorageChange);

    // Cleanup on unmount
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [bookId]);


  // Function to handle adding books to the cart
  const handleAddToCart = async (userId, bookId) => {
    if (!isAuthenticated || user?.role !== 'user') {

      const bookPath = location.pathname + location.search;
      navigate('/auth/login', { state: { bookPath } });
      return;
    }
    try {
      const response = await axiosInstance.post('/purchase/addToCart', { userId, bookId });
      if (response.status === 200) {
        console.log('Book added to cart:', response.data);

        // Update the disabledBooks state to mark the current book as disabled
        const updatedDisabledBooks = { ...disabledBooks, [bookId]: true };
        setDisabledBooks(updatedDisabledBooks);

        // Save the updated disabledBooks state to localStorage
        localStorage.setItem('disabledBooks', JSON.stringify(updatedDisabledBooks));
        setIsDisabled(true);
      }
    } catch (error) {
      console.error('Error adding book to cart:', error);
    }
  };

  // Check if a specific book should be disabled
  const isButtonDisabled = (bookId) => {
    return disabledBooks[bookId] || false; // If the bookId is not found, return false (enabled)
  };


  

  useEffect(() => {
    // Fetch cart data for the employee/user
    const fetchCartData = async () => {
      try {
        console.log("=====userId=====", userId)
        const response = await axiosInstance.get(`/purchase/getCartBooks/${userId}`);
        console.log("Response data: ", response.data);

        if (response.status === 200) {
          const cartBooks = response.data.bookIds || [];  // Access bookIds from response
          setAddedBooks(cartBooks);  // Set bookIds in state
          console.log("Cart Books: ", cartBooks);
        }
      } catch (error) {
        console.error('Error fetching cart data:', error);
      }
    };

    fetchCartData();
  }, [userId]);  // Fetch cart data whenever userId changes


  const fetchBookData = async () => {

    try {
      const response = await axiosInstance.get(`/books/book/${bookId}`, {

      });
      setBook(response.data);
    } catch (error) {
      console.error('Error fetching book data:', error);
    }
  };

  useEffect(() => {

    fetchBookData();
  }, [bookId, language]);

  useEffect(() => {
    fetchRelatedBooks();
  }, [book]);

  const fetchRelatedBooks = async () => {
    try {
      const response = await axiosInstance.get(`/books/related`, {
        params: { book_id: book.book_id }, headers: {
          'Accept-Language': language
        }
      });
      // console.log("dddd",response.data);

      setBooks(response.data);
    } catch (error) {
      console.error('Error fetching related books:', error);
    }
  };

  const handleBookClick = (book) => {
    navigate(`/book/${book.book_id}`);
  };

  const handleClick = () => {
    // navigate(`/view/${book.pdf_url}`,{state:{book}});
    // navigate(`/view/${book.book_id}`,{state:{book, userId}});    
    setReadDialogOpen(true); // Open the dialog on button click
  };

  const handleCloseReaderDialog = () => {
    setReadDialogOpen(false); // Close the dialog
    fetchBookData();
  };

  const handleShareIconClick = () => {
    if (!isAuthenticated || user?.role !== 'user') {

      const bookPath = location.pathname + location.search;
      navigate('/auth/login', { state: { bookPath } });
      return;
    }

    setOpenShareDialog(true);
  };

  // If loading, disable the button until cart data is fetched
  // const isButtonDisabled = loading || addedBooks.includes(bookId);


  const handleShareDialogClose = () => {
    setOpenShareDialog(false);
  };

  const handleCommentsIconClick = () => {
    setOpenCommentsDialog(true);
  };

  const handleCommentsDialogClose = () => {
    setOpenCommentsDialog(false);
  };

  const formatCount = (count) => {
    if (count >= 1000) {
      return `${Math.floor(count / 1000)}k`;
    }
    return count.toString();
  };

  if (!book) {
    // Show a loader while the book data is being fetched
    return <Loader />;
  }

  return (
    <>
      <Box
        sx={{
          width: 'auto',
          height: 'auto',
          borderRadius: '20px',
          paddingBottom: isSmallScreen ? "50px" : isMediumScreen ? "100px" : "150px",
          position: 'relative',
          paddingRight: isSmallScreen ? "20px" : "50px",
          margin: isSmallScreen ? "60px 20px 40px" : isMediumScreen ? "80px 30px 60px" : "100px 40px 80px",
          backgroundColor: "#b7deeff6",
        }}
      >
        <IconButton
          disableRipple
          onClick={handleShareIconClick}
          sx={{
            position: 'absolute',
            top: isSmallScreen ? '10px' : isMediumScreen ? '20px' : '40px',
            right: isSmallScreen ? '10px' : isMediumScreen ? '20px' : '50px',
          }}
        >
          {/* <FontAwesomeIcon icon={faShare} fontSize={isSmallScreen ? "30px" : "50px"} /> */}
          <ShareIcon icon={faShare} sx={{ fontSize: isSmallScreen ? "30px" : "50px" }} />

        </IconButton>
        <IconButton
          disableRipple
          sx={{
            position: 'absolute',
            top: isSmallScreen ? '10px' : isMediumScreen ? '20px' : '40px',
            right: isSmallScreen ? '10px' : isMediumScreen ? '20px' : '50px',
          }}
        >
        </IconButton>

        {/* Share Dialog */}
        <Dialog open={openShareDialog} onClose={handleShareDialogClose}>
          <DialogContent>
            <Share />
          </DialogContent>
        </Dialog>

        {/* Comments Dialog */}
        <Dialog
          open={openCommentsDialog}
          onClose={handleCommentsDialogClose}
          fullWidth
          maxWidth="md"
        >
          <DialogContent>
            <Typography variant="h6" gutterBottom>Comments</Typography>
            <Comments bookId={book.book_id} userId={userId} setBooks={book} />
          </DialogContent>
        </Dialog>

        <Grid container direction={isSmallScreen ? "column" : "row"}>
          <Grid item xs={12} md={3} lg={2}>
            <Box
              component="img"
              src={`${process.env.REACT_APP_BOOK_COVER_BASE_URL}${book.cover_image_url}`}
              sx={{
                width: isSmallScreen ? '120px' : isMediumScreen ? '180px' : '200px',
                height: isSmallScreen ? '180px' : isMediumScreen ? '250px' : '280px',
                margin: isSmallScreen ? '20px auto' : isMediumScreen ? '30px' : '40px',
                borderRadius: '15px',
              }}
            />
          </Grid>
          <Grid item xs={12} md={9} lg={10}>
            <Grid container direction="column" sx={{ height: "auto" }}>
              <Grid item sx={{ marginLeft: isSmallScreen ? '20px' : '70px', marginTop: '30px' }}>
                <Typography variant="h6" fontFamily='MyTamilFontHeading' fontSize={isSmallScreen ? "24px" : isMediumScreen ? "32px" : "40px"}>
                  {book.title}
                </Typography>
                <Typography fontSize={isSmallScreen ? "16px" : "18px"}>{t("title.authorName")} : {book.author_name}</Typography>
                <Typography fontSize={isSmallScreen ? "16px" : "18px"}>{t("title.level")} : {book.level_name}</Typography>
                <Typography fontSize={isSmallScreen ? "16px" : "18px"}>{t("title.category")} : {book.category_name}</Typography>
                <Typography fontSize={isSmallScreen ? "16px" : "18px"}>{t("title.subCategory")} :  {book.subcategory_name}</Typography>
              </Grid>
              <Grid item sx={{ marginLeft: isSmallScreen ? '20px' : '70px', marginTop: '10px' }}>
                <Typography sx={{ maxWidth: isSmallScreen ? "100%" : "900px", maxHeight: "300px" }}>
                  {t("title.discription")} : {book.description}
                </Typography>
              </Grid>
              <Grid item sx={{ marginLeft: isSmallScreen ? '20px' : '70px', marginTop: '20px' }}>
                <Grid container direction="row" spacing={2} alignItems="center">
                  <Grid item>
                    <Button
                      variant="contained"
                      sx={{
                        color: '#000',
                        fontWeight: 'bold',
                        paddingLeft: "20px",
                        background: '#04ad4d',
                        boxShadow: 'none',
                        '&:hover': {
                          background: '#268943',
                          color: "black",
                          boxShadow: 'none',
                        },
                        fontSize: isSmallScreen ? "12px" : "16px",
                      }}
                      onClick={handleClick}
                    >
                      {t("bookdis.read")}
                    </Button>

                  </Grid>
{/* 
                  <Grid item>
                    <Button
                      variant="contained"
                      sx={{
                        color: '#000',
                        fontWeight: 'bold',
                        paddingLeft: "20px",
                        background: '#ffff00',
                        boxShadow: 'none',
                        '&:hover': {
                          background: '#FFF99E',
                          color: "black",
                          boxShadow: 'none',
                        },
                        fontSize: isSmallScreen ? "12px" : "16px",
                      }}
                      onClick={() => handleBuyClick(book.book_id)} 
                    >
                      {t("bookdis.buy")}
                    </Button>

                  </Grid> */}

                  {/* <Navbar addedBooks={addedBooks} /> */}


                  {/* <Grid item>
                    <Button
                      variant="contained"
                      sx={{
                        color: '#000',
                        fontWeight: 'bold',
                        paddingLeft: "20px",
                        background: '#F0F8FF',
                        boxShadow: 'none',
                        '&:hover': {
                          background: '#F9F2FF',
                          color: "black",
                          boxShadow: 'none',
                        },
                        fontSize: isSmallScreen ? "12px" : "16px",
                      }}
                      disabled={isButtonDisabled(bookId)}
                      onClick={() => handleAddToCart(userId, bookId)}
                    >
                      {t("bookdis.cart")}
                    </Button>
                  </Grid> */}


                  {book.youtube_link && (
                    <Grid item>
                      <Button
                        sx={{
                          color: 'white',
                          fontWeight: 'bold',
                          background: '#c90000',
                          boxShadow: 'none',
                          '&:hover': {
                            background: '#e73e3e',
                            color: "black",
                            boxShadow: 'none',
                          },
                          fontSize: isSmallScreen ? "12px" : "16px",
                        }}
                        component={Link} href={book.youtube_link} target='_label'
                      >
                        {t("bookdis.youTube")}
                      </Button>
                    </Grid>
                  )}
                  <Grid item>
                    <LikeButton
                      onClick={handleShareIconClick}
                      value='0'
                      disableRipple
                      sx={{ fontSize: isSmallScreen ? "20px" : "24px" }}
                      bookId={book.book_id}
                      userId={userId}
                      initialLikesCount={formatCount(book.likes_count)}
                      setBooks={book}
                    />
                  </Grid>
                  <Grid item>
                    <IconButton disableRipple onClick={handleCommentsIconClick}>
                      <FontAwesomeIcon icon={faComment} fontSize={isSmallScreen ? "20px" : "30px"} />
                      <Typography sx={{ fontSize: isSmallScreen ? "16px" : "24px", paddingLeft: "4px" }}> {formatCount(book.comments_count)} </Typography>
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton disableRipple sx={{ cursor: "default" }}>
                      <FontAwesomeIcon icon={faBookOpenReader} fontSize={isSmallScreen ? "20px" : "30px"} />
                      <Typography sx={{ fontSize: isSmallScreen ? "16px" : "24px", paddingLeft: "4px" }}> {formatCount(book.views_count)}</Typography>
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <FavouriteButton bookId={book.book_id} userId={userId} setBooks={book} />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction="row" sx={{ marginLeft: isSmallScreen ? '20px' : '60px', marginTop: '80px' }}>
            <Grid item>
              <Typography variant="h1" fontFamily='MyTamilFontHeading' fontSize={isSmallScreen ? "20px" : isMediumScreen ? "25px" : "30px"}>
                {t("bookdis.bookcard")}
              </Typography>
            </Grid>
            <Grid container direction="row" alignItems="center" justifyContent="flex-start" mt={10}>
              {books.map((book) => (
                <Grid item container justifyContent="center" key={book.book_id} xs={12} sm={6} md={4} lg={3} xl={2}>
                  <BookCard
                    book={book}
                    onBookClick={handleBookClick}
                    setBooks={setBooks}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>

        <BookView
          readDialogOpen={readDialogOpen}
          onCloseReadDialog={handleCloseReaderDialog}
          book={book}
          userId={userId} // Pass required props
        />
      </Box>
      <FloatingDonateButton />
    </>
  );
};

export default BookDescription;
