import React, { useState, useEffect } from 'react';
import { Grid, TextField, Button, InputAdornment } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const SharePage = () => {
    const [currentURL, setCurrentURL] = useState('');
    const [dimText, setDimText] = useState(false);

    useEffect(() => {
        setCurrentURL(window.location.href);
    }, []);

    const handleCopyClick = () => {
        navigator.clipboard.writeText(currentURL).then(() => {
            // console.log('currentURL:',currentURL)
            setDimText(true);
            setTimeout(() => setDimText(false), 2000); // Reset dimText after 2 seconds
        }).catch(err => console.error('Failed to copy: ', err));
    };

    console.log("Current url",currentURL);

    const handleShareClick = async () => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: 'Check this out!',
                    text: 'Here is a link I wanted to share with you.',
                    url: currentURL,
                });
            } catch (error) {
                console.error('Error sharing:', error);
            }
        } else {
            alert('Sharing is not supported on this device.');
        }
    };

    return (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <TextField
                    fullWidth
                    value={currentURL}
                    InputProps={{
                        readOnly: true,
                        style: { opacity: dimText ? 0.5 : 1 },
                        endAdornment: (
                            <InputAdornment position="end">
                                <Button 
                                    onClick={handleCopyClick} 
                                    variant="contained" 
                                    color="primary"
                                >
                                    <ContentCopyIcon />
                                </Button>
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
            <Grid item>
                <Grid container direction="row" justifyContent="space-evenly">
                    <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={handleShareClick}
                    >
                        Share this link
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default SharePage;
