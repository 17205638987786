import React from 'react'
import Carousel from '../home/Carousel'
import Counter from '../home/Counter'
import CategoriesList from '../home/Levels'
import RecentlyAdded from '../home/RecentlyAdded'
import Popular from '../home/Trending'
import Events from '../home/Events'
import Information from '../home/Description'
import FloatingDonateButton from '../donate/FloatingDonateButton'; 


function Home() {
  return (
    <>
      <Carousel/>
      <Counter/>  
      <RecentlyAdded/>
      <Popular/>
      <Information/>
      <CategoriesList/>
      <Events/>
      <FloatingDonateButton/>
      
    </>
  )
}

export default Home;