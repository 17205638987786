import React, { useContext, useState, useEffect } from "react";
import { TextField, Button, Grid, Typography, Box, ThemeProvider, Alert, IconButton, Divider, Link, Chip, styled, CircularProgress, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import axiosInstance from "../utils/AxiosInstance";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import theme from "../ui/Theme";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import bgImg from "../../assets/final/Login.jpg"
import GoogleAuth from "./GoogleAuth";
import axios from "axios";
import { AuthContext } from "../../hooks/context/AuthContext";
import Navbar from "../layout/Navbar";
import { useTranslation } from "react-i18next";
import Snackbar from '@mui/material/Snackbar';


// console.log("url", process.env.REACT_APP_API_BASE_URL);

function Login() {
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loggedInmessage, setLoggedInMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [verifyMessage, setVerifyMessage] = useState("");
  const [showResendDialog, setShowResendDialog] = useState(false);
  const { checkAuth } = useContext(AuthContext);
  const { t } = useTranslation("login")
  const [open, setOpen] = useState(false);
  const [load, setLoad] = useState(false)
  console.log(load)

  const navigate = useNavigate();


  // console.log("formState", path);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoad(true)

    try {
      const response = await axiosInstance.post("/auth/login", { email, password }, { withCredentials: true });


      // console.log(response.data)
      if (response.status === 200) {

        setLoggedInMessage("Login successful");
        setOpen(true);
        setTimeout(async () => {
          await checkAuth();
          const { redirectUrl } = response.data || '/';
          const { cbPath, form, image,bookPath } = location.state || {};
        if (cbPath) {
            navigate(cbPath, { state: { form, image } });
        } else if (bookPath) {
             navigate(bookPath);
          } else {
            navigate(redirectUrl);
        }
    } , 1000);
      }


    } catch (error) {
      if (error.response && error.response.status === 400) {
        const errorMessage = error.response.data.error;
        if (errorMessage === "Incorrect email.") {
          setErrorMessage("Email not found. Please register.");
          setEmail("");
          setPassword("");
        } else if (errorMessage === "Incorrect password.") {
          setErrorMessage("Password does not match.");
          setPassword("");
        } else if (errorMessage === "The email address is already in use by another account.") {
          setErrorMessage("The email address is already in use by another account.");
          setEmail("");
          setPassword("");
        }
        else {
          setErrorMessage("Invalid credentials");
          setEmail("");
          setPassword("");
        }
      } else if (error.response && error.response.status === 403) {
        setShowResendDialog(true);
      } else {
        setErrorMessage("Internal server error. Please try again later.");
        // console.log(`Error: ${error.response ? error.response.data.error : error.message}`);
      }
    }
    finally {
      setLoad(false)
      //navigate('/'); 
    }
  }


  const handleResendVerificationEmail = async () => {
    try {
      const response = await axiosInstance.post("/auth/resend-verification", { email });
      setVerifyMessage(response.data.message);
      setOpen(true);
      setShowResendDialog(false);
    } catch (error) {
      setErrorMessage("Failed to resend verification email. Please try again later.");
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    setVerifyMessage("");
    setLoggedInMessage("");

  };

  return (
    <ThemeProvider theme={theme}>
      <Navbar />

      <Typography sx={{
        position: 'absolute',
        top: 80,
        color: "#191970",
        fontSize: '20px',
        fontWeight: 600,
        fontFamily: "Fredoka",
        width: '100%',
        textAlign: 'center'
      }}>“Hope you enjoyed reading our books. We love to hear from you”.<Typography sx={{
        fontSize: '20px',
        fontWeight: 600,
        fontFamily: "Fredoka",
      
      }}>Please login to give your feedback..!</Typography> </Typography>
      <Box display="flex" alignItems="center" height="100vh" sx={{ justifyContent: { xs: "center", md: "flex-end" }, pr: { md: "150px" }, backgroundImage: `url(${bgImg})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center", margin: "0px" }}>
        <Grid container direction="column" justifyContent="center" alignItems="center" boxShadow={1} borderRadius="50px" width="380px" p={5} sx={{ bgcolor: { xs: "#ffffffff", md: "#ffffff39", } }}>
          <Grid item xs={8} sm={8} md={6} lg={3}>
            <Typography variant="h4" align="center" fontWeight="bold" color="#000000">{t("login.login")}</Typography>
            <Typography align="center" ml="5px" >New to StoryBuzz ? <Link component={RouterLink} to="/auth/register"
              sx={{
                textDecoration: "none",
                color: 'blue',
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}>{t("login.register")}</Link></Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                required
                label={t("login.email")}
                fullWidth
                type="email"
                margin="normal"
                autoFocus
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setErrorMessage("")
                }}
                sx={{ '& .MuiOutlinedInput-root': { borderRadius: '10px', height: "50px", }, '& .MuiInputLabel-root': { color: 'black', }, }}
              />
              <TextField
                required
                label={t("login.password")}
                type={showPassword ? 'text' : 'password'}
                fullWidth

                margin="normal"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value)
                  setErrorMessage("")
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{ '& .MuiOutlinedInput-root': { borderRadius: '10px', height: "50px", }, '& .MuiInputLabel-root': { color: 'black', }, }}
              />

              {errorMessage && (<Typography color="error">{errorMessage}</Typography>)}
              <Typography color="text.primary"><Link component={RouterLink} to="/auth/reset-password/form" sx={{
                textDecoration: "none",
                color: 'blue',
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline'
                }
              }} >{t("login.forgotPass")} ?</Link></Typography>


              <Button type="submit" fullWidth variant="contained" sx={{ mt: "20px", color: '#ffffff', backgroundColor: "#000000", '&:hover': { backgroundColor: '#000000ff', borderColor: '#000000', }, }}>
                {/* {t("login.login")} */} {load ? <Box><CircularProgress size={15} sx={{ color: "#ffffff" }} /></Box> : t("login.login")}
              </Button>


              {/* <Button type="submit" variant="contained" sx={{mt:"10px", backgroundColor: 'black', color: 'white', width:"90px", '&:hover': {backgroundColor: '#000000ff',},}}>
              Sign in
            </Button> */}

            </form>
          </Grid>

          <Divider color="black" sx={{ width: "100%", margin: "20px 0", }}><Chip label={t("login.or")} size="small" /></Divider>

          <Grid item>
            <GoogleAuth setLoggedInMessage={setLoggedInMessage} setInvalidUserMessage={setErrorMessage} setSnackbarOpen={setOpen} label={t("login.signinwithgoogle")} />
          </Grid>
        </Grid>


        <Dialog open={showResendDialog} onClose={() => setShowResendDialog(false)}>
          <DialogTitle>Email Verification Required</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ color: "#ff0000" }}>
              Your email is not verified yet. Please check your inbox for the verification email or{" "}
              <Link href="#" onClick={handleResendVerificationEmail}>
                click here
              </Link> to resend the verification email.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowResendDialog(false)} color="primary">Close</Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          open={open}
          autoHideDuration={5000}
          onClose={handleClose}
          sx={{ marginLeft: '1270px' }}
        >
          {(verifyMessage || loggedInmessage) && (
            <Alert onClose={handleClose} severity="success">
              {verifyMessage || loggedInmessage}
            </Alert>
          )}
        </Snackbar>
      </Box>
    </ThemeProvider>
  );
}

export default Login;
