import React, { useState, useRef, useTransition, useContext } from 'react';
import { Box, Button, TextField, Dialog, DialogContent, IconButton, Typography, CircularProgress } from '@mui/material';
import { Close as CloseIcon, Edit as EditIcon, CloudUpload as CloudUploadIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { SketchPicker } from 'react-color';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import backgroundImage from '../../assets/Back2.png';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../utils/AxiosInstance';
import { LinearGradient } from '@react-pdf/renderer';
import { AuthContext } from '../../hooks/context/AuthContext';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import FloatingDonateButton from '../donate/FloatingDonateButton';
import Loaders from './Loader';



const BookCreationForm = () => {
    const location = useLocation();
    const [form, setForm] = useState(location.state?.form || { authorName: '', bookTitle: '', description: '' });
    const [image, setImage] = useState(location.state?.image || null);
    const [pdf, setPdf] = useState(location.state?.pdf || null);
    const fileInputRef = useRef(null);
    const pdfInputRef = useRef(null);
    const [errors, setErrors] = useState({ authorName: '', bookTitle: '', description: '', image: '' });
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isBookCreated, setIsBookCreated] = useState(false);
    const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState(false);
    const { isAuthenticated, user, loading } = useContext(AuthContext);
    const [gradient, setGradient] = useState('#fff'); // Default gradient color
    const [isPickerVisible, setIsPickerVisible] = useState(false);
    const [loader, setLoader] = useState(false)
    const { t } = useTranslation("common")
    const [bookId, setBookId] = useState(null);

    console.log(bookId);

    const navigate = useNavigate();

    // const { state } = location;

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        // console.log(file);

        if (file) {
            setImage(file);
            setErrors((prevErrors) => ({
                ...prevErrors,
                image: ''
            }));
        }
    };

    const handlePdfUpload = (event) => {
        const file = event.target.files[0];
        if (file && file.type === 'application/pdf') {
            setPdf(file);
            setErrors((prevErrors) => ({
                ...prevErrors,
                pdf: '' // Clear the error when a file is selected
            }));
            setIsPopupOpen(false); // Close the popup after a valid PDF is uploaded
        } else {
            console.log('Please upload a PDF file.');
        }
    };


    const handlePdfRemove = () => {
        setPdf(null);
        if (pdfInputRef.current) {
            pdfInputRef.current.value = null;
        }
    };

    //     const [errors, setErrors] = useState({
    //         authorName: '',
    //         bookTitle: '',
    //         description: '',
    //         image: ''
    //     });


    const handleSubmit = async (e) => {
        e.preventDefault();

        const cbPath = location.pathname + location.search
        if (!isAuthenticated || user?.role !== 'user') {

            navigate('/auth/login', { state: { cbPath, form, image } });
            return;
        }

        const newErrors = {
            authorName: validateAuthorName(form.authorName),
            bookTitle: validateBookTitle(form.bookTitle),
            description: validateDescription(form.description),
            image: image ? '' : 'Image is required',
        };

        setErrors(newErrors);
        const hasErrors = Object.values(newErrors).some(error => error);

        if (!hasErrors) {
            if (!pdf) {
                setIsPopupOpen(true);
            } else {
                try {
                    setLoader(true)
                    const formDataImage = new FormData();
                    formDataImage.append('thumbnail', image);
                    formDataImage.append('bookTitle', form.bookTitle);

                    const imageResponse = await axiosInstance.post('/upload/thumbnail', formDataImage);

                    const formDataPdf = new FormData();
                    formDataPdf.append('book', pdf);
                    formDataPdf.append('bookTitle', form.bookTitle);

                    const pdfResponse = await axiosInstance.post('/upload/book', formDataPdf);

                    const imageUrl = imageResponse.data.fileName;
                    const pdfUrl = pdfResponse.data.fileName;

                    const bookData = {
                        title: form.bookTitle,
                        authorName: form.authorName,
                        description: form.description,
                        cover_image_url: imageUrl,
                        pdf_url: pdfUrl,
                        created_by: user.id
                    };

                    const bookResponse = await axiosInstance.post('/create/book', bookData);
                    console.log('Book created successfully', bookResponse.data);

                    if (bookResponse.data) {
                        setIsBookCreated(true);
                        setIsSuccessPopupOpen(true); // Show success message
                        setImage(null);
                        fileInputRef.current.value = '';
                        setPdf(null);
                        setForm({ authorName: '', bookTitle: '', description: '' });

                    }

                } catch (error) {
                    if (error.response.status === 401) {
                        window.location.href = '/auth/login';
                      }      
                    else if (error.response && error.response.data) {
                        alert(error.response.data.message);
                    } else {
                        console.error('Error creating the book:', error);
                    }
                } finally {
                    setLoader(false)
                }
            }
        }
    };



    const Popup = ({ open, onClose, onUpload, }) => (

        <Dialog open={open} onClose={onClose}>

            <DialogContent sx={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 6, backgroundColor: 'white' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: "30px", justifyContent: 'center' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Box onClick={() => handlEditSubmit(onClose)}>
                            {loader ? <CircularProgress /> : <EditIcon sx={{ color: 'orange', cursor: 'pointer', fontSize: 40 }} />}
                        </Box>
                        <Typography variant="body2" align="center">{t("counter.createyourownbook")}</Typography>
                    </Box>

                    <span style={{ border: "1px solid black", height: "50px" }}></span>

                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Box onClick={onUpload}>
                            <CloudUploadIcon sx={{ color: 'orange', fontSize: 40, cursor: 'pointer' }} />
                        </Box>
                        <Typography variant="body2" align="center">{t("counter.UploadYourBook")}</Typography>
                    </Box>
                </Box>


                <IconButton
                    sx={{ position: 'absolute', top: 0, right: 0, color: 'black' }}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            </DialogContent>
        </Dialog>
    );

    const SuccessPopup = ({ open, onClose }) => (
        <Dialog open={open} onClose={onClose}>
            <DialogContent sx={{ textAlign: 'center', padding: 4 }}>
                <Typography variant="h6">{t("counter.bookcreatesubmit")}</Typography>
                <Button onClick={onClose} variant="contained" color="primary" sx={{ marginTop: 2 }}>
                    {t("counter.close")}
                </Button>
            </DialogContent>
        </Dialog>
    );

    const validateAuthorName = (name) => {
        const regex = /^[A-Za-z\s]*$/;
        if (!name) {
            return 'Author Name is required';
        }
        // if (!regex.test(name)) {
        //     return 'Author Name should only contain alphabets';
        // }
        if (name.length > 25) {
            return 'Only 20 characters';
        }
        return '';
    };

    const validateBookTitle = (title) => {
        const regex = /^[A-Za-z0-9\s]*$/;
        if (!title) {
            return 'Book Title is required';
        }
        // if (!regex.test(title)) {
        //     return 'Book Title should only contain alphanumeric characters';
        // }
        if (title.length > 30) {
            return 'Only 50 characters';
        }
        return '';
    };

    const validateDescription = (description) => {
        if (!description) {
            return 'Description is required';
        }
        if (description.length > 200) {
            return 'Only 200 characters';
        }
        return '';
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: value
        });

        let error = '';
        if (name === 'authorName') {
            error = validateAuthorName(value);
        } else if (name === 'bookTitle') {
            error = validateBookTitle(value);
        } else if (name === 'description') {
            error = validateDescription(value);
        }

        setErrors({
            ...errors,
            [name]: error
        });
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
    };

    const formatText = (text, length) => {
        if (!text) return '';
        const regex = new RegExp(`.{1,${length}}`, 'g');
        return text.match(regex).join('\n');
    };

    const handleEditClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
            // fileInputRef.current.addEventListener('change', handleImageSubmit);
        }
    };




    const handlEditSubmit = async (onClose) => {

        const newErrors = {
            authorName: validateAuthorName(form.authorName),
            bookTitle: validateBookTitle(form.bookTitle),
            description: validateDescription(form.description),
            image: image ? '' : 'Image is required',
        };

        setErrors(newErrors);
        const hasErrors = Object.values(newErrors).some(error => error);

        if (!hasErrors && image) {
            try {
                setLoader(true);

                const formDataImage = new FormData();
                formDataImage.append('thumbnail', image);
                formDataImage.append('bookTitle', form.bookTitle);


                const imageResponse = await axiosInstance.post('/upload/thumbnail', formDataImage);
                const imageUrl = imageResponse.data.fileName;


                const bookData = {
                    title: form.bookTitle,
                    authorName: form.authorName,
                    description: form.description,
                    cover_image_url: imageUrl,
                    created_by: user.id
                };


                const bookResponse = await axiosInstance.post('/create/book', bookData);
                console.log('Book created successfully', bookResponse.data);

                if (bookResponse.data) {
                    console.log("jjj", bookResponse.data.book)
                    setIsBookCreated(true);
                    navigate('/create/book', { state: { book: bookResponse.data.book } });

                    // setIsSuccessPopupOpen(true); 
                    setImage(null);
                    fileInputRef.current.value = '';
                    setForm({ authorName: '', bookTitle: '', description: '' });
                    onClose();
                    // setBookId(bookResponse.data.book.id);
                    // console.log("mmm",bookResponse.data.book.id);

                    return bookResponse.data;
                }

            } catch (error) {
                if (error.response.status === 401) {
                    window.location.href = '/auth/login';
                  }      
                else if (error.response && error.response.data) {
                    alert(error.response.data.message);
                } else {
                    console.error('Error creating the book:', error);
                }
            } finally {
                setLoader(false);
            }
        }
    };

    const handlePdfUploadClick = () => {
        if (pdfInputRef.current) {
            pdfInputRef.current.click();
        }
    };

    return (

        <Box
            sx={{
                backgroundImage: `url(${backgroundImage})`, // Set background image for the wrapper
                backgroundSize: 'contain', // Ensure the full image is displayed without cropping
                backgroundRepeat: 'no-repeat', // Avoid repeating the image
                backgroundPosition: 'center', // Center the image in the container
                minHeight: '100vh', // Ensure it covers the viewport height
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 15,
                borderRadius: '20px',
            }}
        >
            <Box
                component="form"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    maxWidth: '600px',
                    marginTop: '100px',
                    padding: "80px 80px",
                    borderRadius: 5,
                    // boxShadow: 1,
                    backgroundColor: 'transparency',
                    transform: 'translateY(-10%)',
                }}
                onSubmit={handleSubmit}
            >
                <Typography
                    sx={{
                        position: 'absolute',
                        top: '-40px',
                        fontSize: '65px',
                        fontWeight: '400',
                        lineHeight: '100px',
                        fontFamily: 'Great Vibes, cursive',
                        background: 'linear-gradient(rgba(202, 60, 59, 1), rgba(10, 0, 123, 1), rgba(45, 210, 42, 1))',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                        backgroundClip: 'text',
                        textFillColor: 'transparent',
                        animation: 'glow 2s ease-in-out infinite',
                        '@keyframes glow': {
                            '0%': { textShadow: '0 0 10px rgba(202, 60, 59, 0.5), 0 0 20px rgba(10, 0, 123, 0.5), 0 0 30px rgba(45, 210, 42, 0.5)' },
                            '50%': { textShadow: '0 0 20px rgba(202, 60, 59, 1), 0 0 30px rgba(10, 0, 123, 1), 0 0 40px rgba(45, 210, 42, 1)' },
                            '100%': { textShadow: '0 0 10px rgba(202, 60, 59, 0.5), 0 0 20px rgba(10, 0, 123, 0.5), 0 0 30px rgba(45, 210, 42, 0.5)' },
                        },
                    }}
                >
                    Create Your Book
                </Typography>

                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        mb: 3
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            flexGrow: 1,
                            position: 'fixed'

                        }}
                    >

                        <TextField
                            label={t("counter.bookTitle")}
                            name="bookTitle"
                            value={form.bookTitle}
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            error={!!errors.bookTitle}
                            helperText={errors.bookTitle}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold',
                                },
                            }}
                        />

                        <TextField
                            label={t("counter.authorName")}
                            name="authorName"
                            value={form.authorName}
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            error={!!errors.authorName}
                            helperText={errors.authorName}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold',
                                },
                            }}
                        />

                        <TextField
                            label={t("counter.description")}
                            name="description"
                            value={form.description}
                            onChange={(e) => {
                                const { value } = e.target;
                                if (value.length <= 200) {
                                    handleChange(e); 
                                }
                            }}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            multiline
                            rows={4}
                            error={!!errors.description}
                            helperText={errors.description || `${form.description.length}/200 characters`}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold',
                                },
                            }}
                        />

                    </Box>
                    <Box sx={{
                        width: '450px',
                        height: '300px',
                        pt: '20px',
                        display: "flex",
                        justifyContent: 'flex-end',

                    }}>
                        <Box
                            sx={{
                                position: 'relative',
                                // ml: 2,
                                width: 200,
                                height: 280,
                                border: '1px solid #000',
                                borderRadius: 5,
                                //  marginLeft: '100px',
                                // display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                // padding: 2,
                                textAlign: 'center',
                                overflowY: 'auto',
                                whiteSpace: 'pre-wrap',
                                wordWrap: 'break-word',
                                boxShadow: '0px 4px 20px 0px #00000040',
                                backgroundColor: gradient, // Apply gradient as background color
                                scrollbarWidth: 'none',
                                overflow: "hidden",
                                '&:hover .editIcon': {
                                    opacity: 1,
                                }
                            }}
                        >
                            <input
                                type="file"
                                id="imageUpload"
                                name="thumbnail"
                                accept="image/*"
                                style={{ display: 'none' }}
                                onChange={handleImageUpload}
                                ref={fileInputRef}
                            />
                            {image ? (
                                <img
                                    src={URL.createObjectURL(image)}
                                    alt="Uploaded Cover"
                                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                />
                            ) : (
                                <label htmlFor="imageUpload" style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    fontSize: '24px',
                                    color: 'black',
                                    cursor: 'pointer',
                                    zIndex: 1,
                                }}>
                                    <Typography sx={{ fontSize: '12px' }}>{t("counter.coverimage")}</Typography>
                                    +

                                </label>
                            )}

                            {image && (
                                <Box
                                    component="div"
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        backgroundImage: `url(${image})`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        // clipPath: 'path("M -20 220 C 37 120 162 228 200 168 L 1194 -334 L -1086 -500 Z")',
                                        zIndex: 0,
                                    }}

                                >
                                    <EditIcon
                                        className="editIcon"
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            fontSize: '24px',
                                            color: 'black',
                                            cursor: 'pointer',
                                            zIndex: 2,
                                            opacity: 0,
                                            transition: 'opacity 0.3s',

                                        }}
                                        onClick={handleEditClick}
                                    />
                                </Box>



                            )}

                            {/* <div style={{
                            fontWeight: 'bold', fontSize: '12px', marginTop: '180px'
                        }}>
                            {formatText(form.bookTitle, 25)}
                        </div>
                        <div style={{ fontWeight: 'bold', fontSize: '12px', marginLeft: '100px', marginTop: '10px' }}>
                            {formatText(form.authorName, 15)}
                        </div> */}
                        </Box>
                    </Box>
                    {errors.image && (
                        <Box sx={{ color: 'red', position: 'absolute', bottom: "180px", right: '120px' }}>
                            {errors.image}
                        </Box>
                    )}
                </Box>





                {/* <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
                    <Box sx={{ textAlign: 'center', cursor: 'pointer', marginLeft: '400px', marginTop: '-30px' }} onClick={() => setIsPickerVisible(!isPickerVisible)}>
                        <h3>Choose Gradient</h3>
                    </Box>
                    {isPickerVisible && (
                        <SketchPicker
                            color={gradient}
                            onChangeComplete={(color) => {
                                setGradient(color.hex);
                            }}
                            disableAlpha
                        />
                    )}
                    {isPickerVisible && (
                        <Box sx={{ mt: 1, fontWeight: 'bold', fontSize: '16px', color: 'black' }}>
                            Selected Color: {gradient}
                        </Box>
                    )}
                </Box> */}

                {/* Display PDF below the submit button after upload */}
                {pdf ? (
                    <Box
                        sx={{
                            mt: 3,
                            p: 2,
                            border: '1px solid #ccc',
                            borderRadius: 10,
                            width: '100%',
                            textAlign: 'center',
                            backgroundColor: '#f9f9f9',
                        }}
                    >
                        <Typography variant="body2">
                            <IconButton onClick={handlePdfRemove}>
                                <DeleteIcon sx={{ color: 'red' }} />
                            </IconButton>
                            Book Uploaded: {pdf.name}
                        </Typography>

                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            sx={{
                                width: '150px',
                                height: "40px",
                                backgroundColor: '#000000'
                            }}
                            disabled={
                                errors.authorName || errors.bookTitle || errors.description || errors.image || errors.pdf
                            }
                        >
                            {/* {t("counter.sub")} */}
                            {loader ? <CircularProgress size={15} sx={{ color: "#ffffff" }} /> : t("counter.sub")}
                        </Button>
                        {/* {loader ? (
                    <span className="indicator-progress" style={{ display: "block" }}>
                      Please wait...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  ) : (
                    'Submit'
                  )} */}

                    </Box>

                ) : (

                    /* Hidden file input for PDF upload */
                    /* <input
                        type="file"
                        id="pdfUpload"
                        accept="application/pdf"
                        style={{ display: 'none' }}
                        onChange={handlePdfUpload}
                        ref={pdfInputRef}
                    /> */


                    <>
                        <Typography
                            variant="body1"
                            color="error"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: 2, // Adjust the margin as needed
                            }}
                        >
                            <ErrorOutlineIcon sx={{ marginRight: 1 }} />
                            Enter all the required details to create your book
                        </Typography>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            sx={{
                                marginTop: 2,
                                width: '200px',
                                height: "40px",
                                backgroundColor: 'black',
                                borderRadius: '5px',

                            }}
                            disabled={
                                errors.authorName || errors.bookTitle || errors.description || errors.image
                            }
                        >
                            {t("counter.submit")}
                        </Button>
                        {/* {loader ? 
                            <Loaders/>:null
                        } */}
                    </>
                )}

                <input
                    type="file"
                    accept="application/pdf"
                    name="book"
                    ref={pdfInputRef}
                    style={{ display: 'none' }}
                    onChange={handlePdfUpload}
                />

                {/* {pdf && (
                    <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 2 }}>
                        <IconButton onClick={handlePdfRemove}>
                            <DeleteIcon sx={{ color: 'red' }} />
                        </IconButton>
                        <span>{pdf.name}</span>
                    </Box>
                )} */}
            </Box>
            <FloatingDonateButton />


            <Popup open={isPopupOpen} onClose={handleClosePopup} onUpload={handlePdfUploadClick} />
            <SuccessPopup open={isSuccessPopupOpen} onClose={() => setIsSuccessPopupOpen(false)} />
        </Box>
    );
};

export default BookCreationForm;
