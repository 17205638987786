import React, { useEffect, useState } from 'react';
import { Drawer, List, ListItem, ListItemText, ListItemIcon, Typography, Box, CssBaseline, AppBar, Toolbar, IconButton, Grid, ListItemButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import EditIcon from '@mui/icons-material/Edit';
import BookIcon from '@mui/icons-material/Book';
import CategoryIcon from '@mui/icons-material/Category';
import Dashboard from '../adminPanel/Dashboard';
import BooksApproval from '../adminPanel/BooksApproval';
import BookTable from '../adminPanel/Books';
import BooksPending from '../adminPanel/BooksPending';
import CommentsControl from '../adminPanel/CommentsControl';
import StoryBuzz from '../../assets/logonew.png'
import EditorApproval from './EditorApproval';
import BookStatus from './BookStatus';
import SearchSection from '../adminPanel/SearchSection/SearchSection';
import ProfileSection from '../adminPanel/ProfileSection';
import { useLocation } from 'react-router-dom';



const drawerWidth = 240;

const TabPanel = ({ children, value, index }) => (
  <div role="tabpanel" hidden={value !== index}>
    {value === index && (
      <Box p={3}>
        <Typography>{children}</Typography>
      </Box>
    )}
  </div>
);

const EditorPanel = (openDialog) => {
  const [value, setValue] = useState(0);
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleChange = (index) => {
    localStorage.setItem('selectedTab', index);
    setValue(index);
};
const location = useLocation();
const prevTab = location.state?.prevTab || 0;
useEffect(() => {
  console.log("Previous Tab Index:", prevTab);
  setValue(prevTab);  // Assuming you have a state `setValue` to control the selected tab
}, [prevTab]);

useEffect(() => {
  const savedTab = localStorage.getItem('selectedTab');
  if (savedTab !== null) {
    
    setValue(Number(savedTab));  // Ensure it's converted to a number
    
  } else {
    setValue(0);  // Default to tab index 0 if nothing is found in localStorage
  }
}, []);

  const drawer = (
    <Box>
      <List>
        <ListItem disableRipple sx={{ borderBottom: '1px solid #ccc' }}>
          <ListItemIcon onClick={() => handleChange(0)}>
            <img src={StoryBuzz} alt="Icon" style={{ width: 40, height: 40, cursor: "pointer" }} />
          </ListItemIcon>
          <ListItemText primary="Storybuzz" />
        </ListItem>
        <ListItemButton onClick={() => handleChange(0)}
          sx={{
            backgroundColor: value === 0 ? '#1976d2' : 'transparent',
            color: value === 0 ? '#fff' : 'inherit',
            "&:hover": {
              backgroundColor: value === 0 ? '#3a84cf' : 'transparent'
            }
          }} >
          <ListItemText primary="Dashboard" />
        </ListItemButton>
        <ListItemButton onClick={() => handleChange(1)}
          sx={{
            backgroundColor: value === 1 ? '#1976d2' : 'transparent',
            color: value === 1 ? '#fff' : 'inherit',
            "&:hover": {
              backgroundColor: value === 1 ? '#3a84cf' : 'transparent'
            }
          }}
        >
          <ListItemText primary="Books" />
        </ListItemButton>
        <ListItemButton onClick={() => handleChange(2)}
          sx={{
            backgroundColor: value === 2 ? '#1976d2' : 'transparent',
            color: value === 2 ? '#fff' : 'inherit',
            "&:hover": {
              backgroundColor: value === 2 ? '#3a84cf' : 'transparent'
            }
          }}
        >
          <ListItemText primary="Book Approval" />
        </ListItemButton>
      </List>
    </Box>
  );
  

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: 1201, width: { sm: `calc(100% - ${drawerWidth}px)` }, ml: { sm: `${drawerWidth}px` } }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Grid container direction="row" sx={{ justifyContent: 'space-between' }}>
            <Grid item>
              <Typography variant="h6" noWrap>
                Editor Panel
              </Typography>
            </Grid>
            <Grid item>
              <Grid container direction="row">
                {/* <Grid item>
                  <SearchSection />
                </Grid> */}
                <Grid item>
                  <Box sx={{ justifyContent: "flex-end" }}>
                    <ProfileSection />
                  </Box>
                </Grid>

              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="mailbox folders">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
        <TabPanel value={value} index={0}>
          <Dashboard />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <BookStatus />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <EditorApproval />
        </TabPanel>
      </Box>
    </Box>
  );
};

export default EditorPanel;
