import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  IconButton,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  CircularProgress,
  Paper,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Stepper,
  Step,
  StepLabel,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  DialogContentText,
} from "@mui/material";
import { Add, Remove, Delete } from "@mui/icons-material";
import axiosInstance from "../utils/AxiosInstance";
import { AuthContext } from "../../hooks/context/AuthContext";
import { useNavigate } from "react-router-dom";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import BookCardTwo from "../common/BookCardTwo";
import Checkout from "./Checkout";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
const ShoppingCartItem = ({
  book,
  handleIncrement,
  handleDecrement,
  handleDelete,
  bookCounts,
}) => {
  const bookCount = bookCounts[book.id] || 1;
  console.log("=============book==========",book,bookCounts)
  return (
    <Card sx={{ display: "flex", mb: 2, p: 2, ml: 5, border: "1px solid #B6B3B2" }}>
      <CardMedia
        component="img"
        sx={{ width: 150, objectFit: "cover" }}
        image={`${process.env.REACT_APP_BOOK_COVER_BASE_URL}${book.cover_image_url}`}
        alt={book.title}
      />
      <Box sx={{ flex: 1, pl: 2, display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Typography variant="h6">{book.title}</Typography>
          <Typography variant="body2" color="text.secondary">
            {book.author_name}
          </Typography>
          <Typography variant="body2"  mt={2}>
          Price per book: ${book.price}
          </Typography>
          <Typography variant="body2">
            Subtotal: ${(bookCounts[book.id] || 1) * book.price}
          </Typography>
        </CardContent>

        <CardActions sx={{ justifyContent: "space-between", pt: 0 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: { xs: "100px", sm: "120px" },
              border: "1px solid #ccc",
              borderRadius: "4px",
              padding: "4px",
            }}
          >
            {bookCount === 1 ? (
              <IconButton onClick={() => handleDelete(book.id)} size="small">
                <Delete />
              </IconButton>
            ) : (
              <IconButton onClick={() => handleDecrement(book.id)} size="small">
                <Remove />
              </IconButton>
            )}

            <Typography variant="body1" mx={1}>
              {bookCount}
            </Typography>

            <IconButton onClick={() => handleIncrement(book.id)} size="small">
              <Add />
            </IconButton>
          </Box>

          {/* {bookCount > 1 && (
            <IconButton disabled>
              <Delete color="disabled" />
            </IconButton>
          )} */}
        </CardActions>
      </Box>
    </Card>
  );
};

const ShoppingCart = () => {
  const [activeBooks, setActiveBooks] = useState([]);
  const [bookCounts, setBookCounts] = useState({});
  const [totalPrice, setTotalPrice] = useState(0);
  const [shippingCharge, setShippingCharge] = useState(0);
  const [taxAmount, setTaxAmount] = useState(0);
  const [totalPriceWithTax, setTotalPriceWithTax] = useState(0);
  const [discount, setDiscount] = useState(0);



  const [loading, setLoading] = useState(true);
  const { isAuthenticated, user } = useContext(AuthContext);
  const [openPopup, setOpenPopup] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();
  const userId = user?.id;
  const steps = ["Enter Your Address", "Order Summary", "Payment Option"];
  const [address, setAddress] = useState({
    name: "",
    mobile: "",
    pincode: "",
    locality: "",
    streetAddress: "",
    city: "",
    state: "",
    landmark: "",
    alternatePhone: "",
  });
  

  const [value, setValue] = useState(0);
  const handleNext = () => setActiveStep((prevStep) => prevStep + 1);
  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);
  const [open, setOpen] = useState(false);
  const [bookToDelete, setBookToDelete] = useState(null);

  useEffect(() => {
    console.log("Updated activeBooks:", activeBooks);
  }, [activeBooks]);

  useEffect(() => {
    const fetchActiveBooks = async () => {
      try {
        const response = await axiosInstance.get(
          `/purchase/getActiveCartBooks/${userId}`
        );
         // Set initial book counts and calculate the total price
         const cartData = response.data.books;
      const counts = {};
      let total = 0;
      cartData.forEach((item) => {
        counts[item.id] = item.quantity;
        total += item.price * item.quantity;
      });

      setBookCounts(counts);
      setTotalPrice(response.data.totalPrice);
        setActiveBooks(cartData); // Set the fetched books in state
         setShippingCharge(response.data.shippingCharge);
         setTaxAmount(response.data.taxAmount);    // Set the tax amount
         setTotalPriceWithTax(response.data.finalTotal);  // Total price after tax
         setDiscount(response.data.discount);
         
      } catch (error) {
        console.error("Error fetching active books:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchActiveBooks();
  }, [userId]);
  console.log("----------------activeBooks---", activeBooks);

  const updateCartInBackend = async (bookId, newQuantity) => {
    try {
      const response = await axiosInstance.post(
        "/purchase/updateCartQuantity",
        {
          userId: userId, // Replace with actual userId
          bookId,
          newQuantity,
        }
      );

      if (response.status === 200) {
        const cartData = response.data.books;
        const counts = {};
        let total = 0;
        cartData.forEach((item) => {
          counts[item.id] = item.quantity;
          total += item.price * item.quantity;
        });
        setBookCounts(counts);
        setTotalPrice(response.data.totalPrice);
        setActiveBooks(cartData); // Set the fetched books in state
        setShippingCharge(response.data.shippingCharge);
        setTaxAmount(response.data.taxAmount); // Set the tax amount
        setTotalPriceWithTax(response.data.finalTotal); // Total price after tax
        setDiscount(response.data.discount);
      }
    } catch (error) {
      console.error("Error updating cart:", error);
    }
  };

  const handleIncrement = async (bookId) => {
    setBookCounts((prevCounts) => {
      const newCount = (prevCounts[bookId] || 1) + 1; // Increment count
      updateCartInBackend(bookId, newCount); // Send updated count to backend
      return {
        ...prevCounts,
        [bookId]: newCount, // Update state immediately with new count
      };
    });
  };

  const handleDecrement = async (bookId) => {
    setBookCounts((prevCounts) => {
      const newCount = Math.max((prevCounts[bookId] || 1) - 1, 0); // Prevent going below 0
      if (newCount >= 1) {
        updateCartInBackend(bookId, newCount); // Update backend only if count >= 1
      }
      return {
        ...prevCounts,
        [bookId]: newCount, // Update state immediately with new count
      };
    });
  };

  const handleClickOpen = (bookId) => {
    setBookToDelete(bookId); // Set the book ID to be deleted
    setOpen(true); // Open the dialog
  };

  const handleClose = () => {
    setOpen(false);
    setBookToDelete(null); // Reset the book ID
  };

  const handleConfirmDelete = () => {
    if (bookToDelete !== null) {
      handleDelete(bookToDelete); // Call the original delete function
      handleClose(); // Close the dialog
    }
  };

  const handleDelete = async (bookId) => {
    try {
      // Call the backend to delete the book from the cart
      const response = await axiosInstance.delete(
        `/purchase/deleteFromCart/${userId}/${bookId}`
      );

      if (response.status === 200) {
        // Update activeBooks state by filtering out the deleted book
        setActiveBooks((prevBooks) =>
          prevBooks.filter((book) => book.id !== bookId)
        );

        // Update bookCounts state by removing the deleted book count
        setBookCounts((prevCounts) => {
          const updatedCounts = { ...prevCounts };
          delete updatedCounts[bookId]; // Remove the count for the deleted book
          return updatedCounts;
        });

        // Remove from disabledBooks in localStorage if applicable
        const disabledBooks =
          JSON.parse(localStorage.getItem("disabledBooks")) || {};
        delete disabledBooks[bookId];
        localStorage.setItem("disabledBooks", JSON.stringify(disabledBooks));

        console.log("Book removed from cart");
      }
    } catch (error) {
      console.error("Error removing book from cart:", error);
    }
  };

  const handleBookClick = (book) => {
    navigate(`/book/${book.book_id}`);    
    
  };

  const handlePlaceOrder = async () => {
    // Create order details with the correct book_id
    const orderDetails = {
      userId,
      books: activeBooks.map((book) => ({
        book_id: book.id, // Ensure book_id is coming from the activeBooks
        count: bookCounts[book.id] || 1,
      })),
      totalPrice,
    };

    console.log("Order Details:", orderDetails); // Debug log

    try {
      // Send a request to save order details
      const response = await axiosInstance.post(
        "/purchase/orders",
        orderDetails
      );

      if (response.status === 200) {
        // Order saved successfully, now open the popup
        // setOpenPopup(true);
        setTimeout(() => {
          navigate("/checkout");
        }, 1000);
      } else {
        console.log("Order could not be placed.");
      }
    } catch (error) {
      console.error("Error placing order:", error);
    }
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
    setActiveStep(0); // Reset the stepper on close
  };

  const handleCancel = () => {
    navigate("/");
    setValue("0");
    localStorage.setItem("TabValue", 0);
  };

  if (loading) {
    return <CircularProgress />;
  }
  const goBack = () => {
    window.history.back();
  };

  return (
    <Box mt={4} p={2}>
      <Grid item>
      <IconButton
            onClick={goBack}
            sx={{
              position: "absolute",
              left: { xs: 30, md: 10 },
              top: { xs: 70, md: 80 },
              color: "black",
              bgcolor: "#fff",
              "&:hover": { bgcolor: "darkgreen", color: "#fff" },
              ml: 2,
            }}
            aria-label="Go Back"
          >
            <ArrowBackIcon sx={{ fontSize: 30 }} />
          </IconButton>
      </Grid>
      
          <Grid>
          <Typography
        fontSize='30px'
        gutterBottom
        sx={{ fontWeight: "bold", mt: 10, mb: 2, ml: 5 }}
      >
        Shopping Cart
      </Typography>
          </Grid>
     

      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <Box>
            {activeBooks.length > 0 ? (
              activeBooks.map((book) => (
                <Box key={book.id} sx={{ mb: 2 }}>
                  {" "}
                  <ShoppingCartItem
                    book={book}
                    handleIncrement={handleIncrement}
                    handleDecrement={handleDecrement}
                    handleDelete={() => handleClickOpen(book.id)}
                    bookCounts={bookCounts}
                  />
                  {/* <BookCardTwo
                    book={book} // Pass the book object
                    onBookClick={handleBookClick}
                    userId={userId}
                    setBooks={setActiveBooks}
                  /> */}
                </Box>
              ))
            ) : (
              <Typography>No books in the cart.</Typography>
            )}
            {/* <Checkout
              activeBooks={activeBooks}
              steps={["Address", "Order Summary", "Payment"]}
              activeStep={activeStep}
              handleNext={handleNext}
              handleBack={handleBack}
            /> */}
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Confirm Deletion"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to delete this book from your cart?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  No
                </Button>
                <Button
                  onClick={handleConfirmDelete}
                  color="secondary"
                  autoFocus
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Grid>

        <Grid item xs={12} md={3} ml={5}>
          <Box sx={{ position: "relative" }}>
            <Card sx={{ p: 2, position: "sticky", top: 20, border: "1px solid #B6B3B2" }}>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold",textAlign:'center' }}>
                Order Summary
              </Typography>
              <hr style={{ border: "1px solid #B6B3B2", width: "100%" }} /> 
              <Box display="flex" justifyContent="space-between" mb={2} mt={2}>
                <Typography>Price</Typography>
                <Typography>${totalPrice}</Typography>
              </Box>

              <Box display="flex" justifyContent="space-between" mb={2}>
                <Typography>Discount</Typography>
                <Typography color="error">
                - ${discount}
                </Typography>
              </Box>

              <Box display="flex" justifyContent="space-between" mb={2}>
                <Typography>Shipping Charges</Typography>
                <Typography color="success">${shippingCharge.toFixed(2)}</Typography>
              </Box>

              <Box display="flex" justifyContent="space-between" mb={2}>
                <Typography>Tax (7%):</Typography>
                <Typography color="success">${taxAmount.toFixed(2)}</Typography>
              </Box>
              <hr style={{ border: "1px solid #B6B3B2", width: "100%" }} /> 
              <Box display="flex" justifyContent="space-between"  mt={2}>
                <Typography variant="h6" fontWeight="bold">
                Total Price (with Tax):
                </Typography>
                <Typography variant="h6" fontWeight="bold">
                 ${totalPriceWithTax}
                </Typography>
              </Box>

              <Button
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: "#FF4E33",
                  color: "white",
                  "&:hover": { backgroundColor: "#FF8C00" },
                  mt: 2,
                }}
                onClick={handlePlaceOrder}
              >
                Place Order
              </Button>
            </Card>
            <Dialog
              open={openPopup}
              onClose={handleClosePopup}
              size="md"
              fullWidth
            >
              <DialogTitle>{steps[activeStep]}</DialogTitle>
              <DialogContent>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>

                {activeStep === 0 && (
                  <Box mt={3}>
                    {/* Add Address Fields */}
                    <Grid container spacing={2}>
                      {/* <Grid item xs={12}>
                          <Button
                            variant="outlined"
                            startIcon={<LocationOnIcon />} // Use any suitable icon for location
                            fullWidth
                            sx={{ justifyContent: 'flex-start' }}
                          >
                            Use my current location
                          </Button>
                        </Grid> */}

                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Name"
                          variant="outlined"
                          name="name"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Mobile number"
                          variant="outlined"
                          name="mobile"
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Email ID"
                          variant="outlined"
                          name="mobile"
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Pincode"
                          variant="outlined"
                          name="pincode"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Locality"
                          variant="outlined"
                          name="locality"
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="City/District/Town"
                          variant="outlined"
                          name="city"
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Address (Area and Street)"
                          variant="outlined"
                          name="address"
                          multiline
                          rows={4}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel>State</InputLabel>
                          <Select label="State">
                            <MenuItem value="state1">State 1</MenuItem>
                            <MenuItem value="state2">State 2</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Landmark (Optional)"
                          variant="outlined"
                          name="landmark"
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Alternate Phone (Optional)"
                          variant="outlined"
                          name="alternatePhone"
                        />
                      </Grid>

                      {/* Address Type - Radio buttons */}
                      <Grid item xs={12}>
                        <FormControl component="fieldset">
                          <FormLabel
                            component="legend"
                            sx={{ color: "#1976d2" }}
                          >
                            Address Type
                          </FormLabel>
                          <RadioGroup row value={address.addressType}>
                            <FormControlLabel
                              value="home"
                              control={<Radio />}
                              label="Home"
                            />
                            <FormControlLabel
                              value="work"
                              control={<Radio />}
                              label="Work"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>

                      {/* Buttons */}
                      <Grid item xs={12} sm={6}>
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth
                          sx={{ backgroundColor: "#ff6600" }}
                        >
                          Save and Deliver Here
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Button
                          variant="outlined"
                          color="primary"
                          fullWidth
                          onClick={handleCancel}
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                )}

                {activeStep === 1 && (
                  <Box mt={3}>
                    <Grid container mt={10}>
                      {activeBooks.map((active) => (
                        <Grid
                          item
                          key={active.book_id}
                          sm={6}
                          md={6}
                          lg={6}
                          xl={6}
                        >
                          <BookCardTwo
                            book={active}
                            onBookClick={handleBookClick}
                            userId={userId}
                            setBooks={setActiveBooks}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                )}

                {activeStep === 2 && (
                  <Box mt={3}>
                    <Typography>Payment Options Content Here...</Typography>
                  </Box>
                )}
              </DialogContent>

              <DialogActions>
                <Button onClick={handleBack} disabled={activeStep === 0}>
                  Back
                </Button>
                {activeStep === steps.length - 1 ? (
                  <Button variant="contained" color="success">
                    Confirm and Pay
                  </Button>
                ) : (
                  <Button
                    onClick={handleNext}
                    variant="contained"
                    color="primary"
                  >
                    Next
                  </Button>
                )}
              </DialogActions>
            </Dialog>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ShoppingCart;
